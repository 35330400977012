import React from "react";

import MaterialTable from "material-table";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import moment from "moment";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FormHelperText from "@material-ui/core/FormHelperText";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory, Link as RLink } from "react-router-dom";
import logoCDO from "assets/img/LOGOCDO.png";
import GridItem from "components/Grid/GridItem.js";
import clsx from "clsx";
import { green } from "@material-ui/core/colors";
import localization from "components/localization/localization";
import PeopleIcon from "@material-ui/icons/People";
import VisibilityIcon from "@material-ui/icons/Visibility";
import GetAppIcon from "@material-ui/icons/GetApp";
import Table from "components/Table/Table.js";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditGroup from "./editGroup";
import AddUsers from "containers/editGroup/addGroupAndUser/addUsers";
import AddGroup from "containers/editGroup/addGroupAndUser/addGroup";
import AddCompany from "containers/editGroup/addCompany/addCompany";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Paper from '@material-ui/core/Paper';
import TextField from "@material-ui/core/TextField";
import DeleteIcon from '@material-ui/icons/Delete';
import ApartmentIcon from '@material-ui/icons/Apartment';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Copyright() {
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <img src={logoCDO} alt="Con-Certeza" width="30" height="35" />
      <GridItem>
        <Typography variant="body2" color="textSecondary" align="center">
          {"Powered by "}
          <Link color="inherit" href="https://certificaciondigital.online/">
            CDO
          </Link>
        </Typography>
      </GridItem>
    </Grid>
  );
}

const useStylestable = makeStyles(styles2);
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#00ACC1",
    color: "white"
  },
  paperred: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "red",
    color: "white"
  },
  papergreen: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "green",
    color: "white"
  },
  button: {
    margin: theme.spacing(1),
  },
  containerWizard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  buttonWizard: {
    marginBottom: theme.spacing(2),
  },
  input: {
    display: "none",
  },
  botondanger: {
    backgroundColor: "red",
    color: "white"
  },
  botongreen: {
    backgroundColor: "green",
    color: "white"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  gridList: {
    width: 1000,
    height: 450,
  },
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ContainerEditGroup(props) {
  const classes2 = useStylestable();
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  const [activeRender, setActiveRender] = React.useState(0);

  const [dialogUsers, setDialogUsers] = React.useState(false);
  const [dialogUsers2, setDialogUsers2] = React.useState(false);

  const [dialogEdit, setDialogEdit] = React.useState(false);
  const [user, setuser] = React.useState(false);
  const [dataGroups, setdataGroups] = React.useState([]);
  const [arrayData, setArrayData] = React.useState([]);

  const [dataUsers2, setdataUsers2] = React.useState([]);
  const [idGroup, setIdGroup] = React.useState("");
  const [openAddGr, setOpenAddGr] = React.useState(false);
  const [openAddCompany, setOpenAddCompany] = React.useState(false);
  const [stateGroups2, setstateGroups2] = React.useState([]);
  const [nameCompany, setNameCompany] = React.useState("");
  const [nameGroup, setNameGroup] = React.useState([]);

  const [dialogAdquisiciones, setDialogAdquisiciones] = React.useState(false);
  const [dialogConfirm, setDialogConfirm] = React.useState(false);
  const [dialogCompany, setDialogCompany] = React.useState(false);
  const [customAsset, setCustomAsset] = React.useState("");
  const [totalAdquisiciones, setTotalAdquisiciones] = React.useState("");
  const [adquisicionDetalles, setAdquisicionDetalles] = React.useState("");
  const [adquisicionEdit, setAdquisicionEdit] = React.useState("");
  const [fecha, setFecha] = React.useState("");
  const [lote, setLote] = React.useState(1);
  const [comentarios, setComentarios] = React.useState("");
  const [nombreCompany, setNombreCompany] = React.useState("");
  const [detalleGrupo, setDetalleGrupo] = React.useState("");

  function handleDialogoCompany(rowData) {
    setDetalleGrupo(rowData);
    rowData.custom_assets ? setNombreCompany(rowData.custom_assets.company) : setNombreCompany("");
    setDialogCompany(true);
  }

  function handleAddCompany() {
    let token = sessionStorage.getItem("Token");
    var config = {
      method: 'put',
      url: `${process.env.REACT_APP_URL}api/roles/${detalleGrupo.id}?custom[company]=${nombreCompany}`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      }
    };

    //if(nombreCompany){
    axios(config)
      .then(function (res) {
        console.log(res.data);
        setDialogCompany(false);
        setNombreCompany("");
        getTotalAdquisiciones();
        getDataGroup();
        setActiveRender(0);
      })
      .catch(function (error) {
        alert("se produjo un error al agregar compañía");
        console.error(error);
      });
    //}
  }

  React.useEffect(() => {
    getTotalAdquisiciones();
    getDataGroup();
  }, []);

  const getDataGroup = () => {
    let token = sessionStorage.getItem("Token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: " application/json",
      },
    };
    const URL = `${process.env.REACT_APP_URL}api/roles/customers`;
    axios
      .get(URL, config)
      .then((res) => {
        const objectData = res.data;
        console.log("data1", objectData);

        var arrayitemData = [];
        for (let item in objectData) {
          console.log("ITEMDATA", objectData[item]);
          arrayitemData.push(objectData[item]);
        }

        arrayitemData.map(function (item) {
          item.created_at = moment(item.created_at).format("DD-MM-YYYY");

          return item;
        });
        setArrayData(arrayitemData);

        console.log("DataGroups", objectData[`Correduría 82`]);
        const NewArrayKeys = Object.keys(objectData);
        console.log("dataKeys", NewArrayKeys);

        setstateGroups2(objectData);
        const itemda = itemCompany(arrayitemData);

        // var newObject = Array.of.apply( NewArrayKeys)
        // console.log("RESULTADOS", result);
      })
      .catch((error) => {
        alert("se produjo un error al consultar los grupos");
        console.error(error);
      });
  };

  const handleOpenDialogUser2 = () => {
    setDialogUsers2(false);
  };

  const handleOpenGroups = (data) => {
    console.log("dataUser121", data);
    setNameCompany(data.name);

    data.groups.map(function (item) {
      item.created_at = moment(item.created_at).format("DD-MM-YYYY");

      return item;
    });
    setdataGroups(data.groups);
    setActiveRender(1);
  };

  const itemCompany = (data) => {
    console.log("dataUSersitme", data);
    // const values = data.map((item) => [
    //  item
    // ]);

    // return values;
  };

  function handleEliminarAdquisicion() {
    console.log("eliminar", adquisicionEdit);
    let token = sessionStorage.getItem("Token");
    var config = {
      method: 'delete',
      url: `${process.env.REACT_APP_URL}api/adquisiciones/${adquisicionEdit._id}`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    };
    axios(config)
      .then(function (response) {
        console.log("Adquisicion Eliminada:", response.data);
        getAdquisiciones(adquisicionEdit.custom_assets_id);
        getTotalAdquisiciones();
        setDialogConfirm(false);
        //alerta("El catálogo ha sido eliminado","warning")
      })
      .catch(function (error) {
        console.log(error);
        alert("Error al eliminar adquisicion", "error")
      });
  }

  function handleGuardarAdquisicion(ca) {
    var data = new FormData();
    data.append('custom_assets_id', ca);
    data.append('lote', lote);
    data.append('fecha', fecha);
    data.append('comentarios', comentarios);

    let token = sessionStorage.getItem("Token");
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_URL}api/adquisiciones`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        getAdquisiciones(ca);
        getTotalAdquisiciones();
        //alerta("Nuevo catálogo creado","info")
        setFecha("");
        setComentarios("");
        setLote(1);
      })
      .catch(function (error) {
        console.log(error);
        alert("Error al crear catálogo", "error")
      });

  }

  function buscarDataCompany(nCompany, n, ta) {
    if (ta.length > 0) {
      for (var i = 0; i < ta.length; i++) {
        if (ta[i].customassets.company === nCompany) {
          switch (n) {
            case 1:
              return (ta[i].totalotes);
              break;
            case 2:
              return (ta[i].nrodocumentos);
              break;
            case 3:
              return (ta[i].totalotes - ta[i].nrodocumentos);
              break;
          }
        }
      }
    }
    return (null);
  }

  function getTotalAdquisiciones() {
    //buscar adquisiciones
    let token = sessionStorage.getItem("Token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: " application/json",
      },
    };
    const URL = `${process.env.REACT_APP_URL}api/adquisiciones/showall`;
    axios
      .get(URL, config)
      .then((res) => {
        setTotalAdquisiciones(res.data);
        console.log("TOTAL Adquisiciones: ", res.data);
        //setDialogAdquisiciones(true);
      })
      .catch((error) => {
        alert("se produjo un error al consultar adquisiciones");
        console.error(error);
      });
    //fin buscar
  }

  function getAdquisiciones(ca) {
    //buscar adquisiciones
    let token = sessionStorage.getItem("Token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: " application/json",
      },
    };
    const URL = `${process.env.REACT_APP_URL}api/adquisiciones/show/${ca}`;
    axios
      .get(URL, config)
      .then((res) => {
        setAdquisicionDetalles(res.data);
        console.log("Detalles: ", res.data);
        setDialogAdquisiciones(true);
      })
      .catch((error) => {
        alert("se produjo un error al consultar adquisiciones");
        console.error(error);
      });
    //fin buscar
  }

  const handleRegistrarAdquisicion = (data) => {
    console.log(data);
    if (data.group_count > 0) {
      if (data.groups[0].custom_assets) {
        getAdquisiciones(data.groups[0].custom_assets._id)
      }
    }
  };

  const handleEditGroup = (data) => {
    setIdGroup(data.id);
    setDialogEdit(true);
  };

  const handleopenunit = (data) => {
    setActiveRender(2);
    console.log("DATAUSERGRUOP", data);
    setNameGroup(data.id);
    setdataUsers2(data.users);
  };
  const itemUsers2 = (data) => {
    console.log("dataUSersitme");
    const values = Object.values(data).map((item) => [
      item.name,
      item.id,
      item.email,
    ]);

    return values;
  };

  const HandleSigUp = (data) => {
    console.log("DATAGRUPOPOO", data);
    setDialogUsers(true);
  };

  const addCompany = () => {
    setOpenAddCompany(true);
  };

  const OpenAddGroup = () => { };

  let disabledRegistrar = true;
  if (fecha != "" && lote > 0) {
    disabledRegistrar = false;
  }

  const companyWizardUrl = `/${localStorage.getItem("uri")}/customer-wizard`;

  return (
    <Container>
      <div class={classes.containerWizard}>
        <RLink to={companyWizardUrl + "/new"}>
          <Button
            color="primary"
            variant="contained"
            className={classes.buttonWizard}
            onClick={() => setActiveRender(0)}
          >
            Nuevo cliente
          </Button>
        </RLink>
      </div>
      {activeRender === 0 && totalAdquisiciones ? (
        <Grid>
          <MaterialTable
            localization={localization}
            title={`Compañías (${arrayData.length})`}
            columns={[
              { title: "Nombre", field: "name" },

              { title: "Fecha de creación", field: "created_at" },
              { title: "N° Grupos", field: "group_count" },
              {
                title: "Adquisiciones",
                field: "uid",
                render: (row) => buscarDataCompany(row.name, 1, totalAdquisiciones),
              },
              {
                title: "Consumo",
                field: "uid",
                render: (row) => buscarDataCompany(row.name, 2, totalAdquisiciones),
              },
              {
                title: "Saldo",
                field: "uid",
                render: (row) => (
                  <span>
                    {buscarDataCompany(row.name, 3, totalAdquisiciones) < 0 ? (
                      <font color="red" size="3">
                        <b>{buscarDataCompany(row.name, 3, totalAdquisiciones)}</b>
                      </font>
                    ) : (
                      <font color="green" size="3">
                        <b>{buscarDataCompany(row.name, 3, totalAdquisiciones)}</b>
                      </font>
                    )}
                  </span>
                ),
              },
            ]}
            data={arrayData}
            actions={[
              {
                icon: "add",
                tooltip: "Agregar Compañia",
                isFreeAction: true,
                onClick: (event) => addCompany(),
                hidden: true,
              },

              (rowData) => ({
                icon: () => (
                  <PeopleIcon
                    className={
                      classes2.tableActionButtonIcon + " " + classes2.edit
                    }
                  />
                ),
                tooltip: "Ver Grupos",
                hidden: true,
                onClick: (event, rowData) => {
                  handleOpenGroups(rowData);
                },
              }),

              (rowData) => ({
                icon: () => (
                  <RLink to={companyWizardUrl + "/" + rowData.id}>
                    <EditIcon
                      className={
                        classes2.tableActionButtonIcon + " " + classes2.edit
                      }
                    />
                  </RLink>
                ),
                tooltip: "Ver o editar compañía",
                // onClick: (event, rowData) => handleEditGroup(rowData),
              }),

              (rowData) => ({
                icon: () => (
                  <ShoppingCartIcon
                    className={
                      classes2.tableActionButtonIcon + " " + classes2.edit
                    }
                  />
                ),
                tooltip: "Registrar adquisición de paquete",
                // onClick: (event, rowData) => handleEditGroup(rowData),
                onClick: (event, rowData) => handleRegistrarAdquisicion(rowData),
              }),

            ]}
            options={{
              pageSize: 20, // make initial page size
              pageSizeOptions: [20, 50, 100],
              actionsColumnIndex: -1,
              exportButton: true,
            }}
          />
        </Grid>
      ) : activeRender === 1 ? (
        <Grid>
          <Button
            size="large"
            className={classes.button}
            startIcon={<ArrowBackIcon />}
            onClick={() => setActiveRender(0)}
          >
            Regresar
          </Button>
          <MaterialTable
            localization={localization}
            title={`Grupos (${dataGroups.length})`}
            columns={[
              { title: "Nombre ", field: "name" },
              { title: "ID", field: "id" },
              { title: "Fecha de creación", field: "created_at" },
              { title: "Descripcion", field: "description" },
              { title: "N° de usuarios", field: "users.length" },
            ]}
            data={dataGroups}
            actions={[
              {
                icon: "add",
                tooltip: "Agregar grupo",
                isFreeAction: true,
                onClick: (event) => setOpenAddGr(true),
              },
              (rowData) => ({
                icon: () => (
                  <LockOpenIcon
                    className={
                      classes2.tableActionButtonIcon + " " + classes2.edit
                    }
                  />
                ),
                tooltip: " Ver permisos",
                // onClick: (event, rowData) =>
                //   handleOpenFirmantesRechazados(rowData),
              }),
              (rowData) => ({
                icon: () => (
                  <PeopleIcon
                    className={
                      classes2.tableActionButtonIcon + " " + classes2.edit
                    }
                  />
                ),
                tooltip: "Ver usuarios",
                onClick: (event, rowData) => handleopenunit(rowData),
              }),
              (rowData) => ({
                icon: () => (
                  <EditIcon
                    className={
                      classes2.tableActionButtonIcon + " " + classes2.edit
                    }
                  />
                ),
                tooltip: "Editar usuario",
                onClick: (event, rowData) => handleEditGroup(rowData),
              }),
              (rowData) => ({
                icon: () => (
                  <ApartmentIcon
                    className={
                      classes2.tableActionButtonIcon + " " + classes2.edit
                    }
                  />
                ),
                tooltip: "Editar Compañía",
                onClick: (event, rowData) => handleDialogoCompany(rowData),
              }),
            ]}
            options={{
              pageSize: 5, // make initial page size
              pageSizeOptions: [5, 10, 20, 500],
              actionsColumnIndex: -1,
              exportButton: true,
            }}
          />
        </Grid>
      ) : activeRender === 2 ? (
        <Grid>
          <Button
            size="large"
            className={classes.button}
            startIcon={<ArrowBackIcon />}
            onClick={() => setActiveRender(1)}
          >
            Regresar
          </Button>
          <MaterialTable
            localization={localization}
            title={`Usuarios (${dataUsers2.length})`}
            columns={[
              { title: "Nombre ", field: "name" },
              { title: "ID", field: "id" },
              { title: "Correo electronico", field: "email" },
            ]}
            data={dataUsers2}
            actions={[
              {
                icon: "add",
                tooltip: "Agregar usuario",
                isFreeAction: true,
                onClick: (event, rowData) => HandleSigUp(rowData),
              },
              (rowData) => ({
                icon: () => (
                  <EditIcon
                    className={
                      classes2.tableActionButtonIcon + " " + classes2.edit
                    }
                  />
                ),
                tooltip: "Editar usuario",
                onClick: (event, rowData) => handleEditGroup(rowData),
              }),
            ]}
            options={{
              pageSize: 5, // make initial page size
              pageSizeOptions: [5, 10, 20, 500],
              actionsColumnIndex: -1,
              exportButton: true,
            }}
          />
        </Grid>
      ) : (
        ""
      )}

      {/* Dialogo para ver cambiar logo y urii de los grupos */}
      <Container>
        <Dialog
          // fullWidth={true}
          // maxWidth="md"
          open={dialogEdit}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Editar grupo"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid>
                <EditGroup setDialogEdit={setDialogEdit} idGroup={idGroup} />
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogEdit(false)} color="succes">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
      {/* Dialogo para registrar un usuario */}
      <Container>
        <Dialog
          // fullWidth={true}
          maxWidth="md"
          open={dialogUsers}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Registrar usuario"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid>
                <AddUsers
                  nameCompany={nameCompany}
                  nameGroup={nameGroup}
                  getDataGroup={getDataGroup}
                  setDialogUsers={setDialogUsers}
                />
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogUsers(false)} color="succes">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
      {/* Dialogo para registrar un grupo */}
      <Container>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={openAddGr}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Registrar grupo"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid>
                <AddGroup
                  setOpenAddGr={setOpenAddGr}
                  nameCompany={nameCompany}
                />
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenAddGr(false)} color="succes">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
      {/* Dialogo para registrar una compañia */}
      <Container>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={openAddCompany}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Registrar grupo"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid>
                <AddCompany getDataGroup={getDataGroup} />
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenAddCompany(false)} color="succes">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </Container>

      {/* Dialogo para registrar Adquisicion de paquete */}
      <Container>
        <Dialog
          fullWidth={true}
          maxWidth="lg"
          open={dialogAdquisiciones}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {adquisicionDetalles ? "Paquetes de documentos: " + adquisicionDetalles.customassets.company : null}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {adquisicionDetalles ? (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Paper className={classes.paper}>
                        <b>Total Adquisiciones:</b> <h1>{adquisicionDetalles.totalotes}</h1>
                      </Paper>
                    </Grid>
                    <Grid item xs={4}>
                      <Paper className={classes.paper}>
                        <b>Total Consumo:</b> <h1>{adquisicionDetalles.nrodocumentos}</h1>
                      </Paper>
                    </Grid>
                    <Grid item xs={4}>
                      {adquisicionDetalles.totalotes - adquisicionDetalles.nrodocumentos < 0 ? (
                        <Paper className={classes.paperred}>
                          <b>Saldo Disponible:</b> <h1>{adquisicionDetalles.totalotes - adquisicionDetalles.nrodocumentos}</h1>
                        </Paper>
                      ) : (
                        <Paper className={classes.papergreen}>
                          <b>Saldo Disponible:</b> <h1>{adquisicionDetalles.totalotes - adquisicionDetalles.nrodocumentos}</h1>
                        </Paper>
                      )}

                    </Grid>
                  </Grid>
                  <hr />
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Paper>
                        <b>Registrar adquisición</b>
                        <hr />
                      </Paper>
                    </Grid>
                    <Grid item xs={3}>
                      <Paper>
                        <b>Fecha:</b><br />
                        <TextField
                          id="dateDesde"
                          type="date"
                          //value={desde}
                          onChange={(e) => {
                            //console.log("fecha", e.target.value); // Works
                            setFecha(e.target.value);
                            var date = new Date(e.target.value);
                            if (date.getFullYear() > 2300) {
                              alert("La fecha es inválida");
                            }

                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Paper>
                    </Grid>

                    <Grid item xs={3}>
                      <Paper>
                        <b>Cantidad:</b><br />
                        <TextField
                          //variant="outlined"
                          id="standard-number"
                          type="number"
                          value={lote}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            min: 1,
                            max: 90,
                            style: { fontSize: 15, color: "green" },
                          }}
                          style={{ width: "80%" }}
                          onChange={(e) => {
                            //console.log("lote", e.target.value); // Works
                            setLote(e.target.value);
                            if (e.target.value <= 0) {
                              alert(
                                "Debe ser mayor que cero"
                              );
                              setLote(1);
                            }
                          }}
                        />
                      </Paper>
                    </Grid>

                    <Grid item xs={4}>
                      <Paper>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="coimentarios"
                          label="Comentarios"
                          variant="outlined"
                          fullWidth
                          inputProps={{
                            maxlength: 128
                          }}
                          FormHelperTextProps={{
                            className: classes.helperText
                          }}
                          value={comentarios}
                          onChange={(e) => {
                            setComentarios(e.target.value)
                          }}
                        />
                      </Paper>
                    </Grid>

                    <Grid item xs={2}>

                      <Button
                        disabled={disabledRegistrar}
                        onClick={() => {
                          handleGuardarAdquisicion(adquisicionDetalles.customassets._id);
                        }}
                        className={classes.botongreen}
                        style={{ marginTop: 15 }}
                      >
                        Registrar
                      </Button>

                    </Grid>

                  </Grid>
                  <br /><br />
                  <hr />
                  <Grid container>
                    <Grid item xs={12}>
                      <MaterialTable
                        localization={localization}
                        title={`Adquisiciones (${adquisicionDetalles.adquisiciones.length})`}
                        columns={[
                          {
                            title: "Fecha",
                            field: "fecha",
                            render: (row) => (
                              <span>
                                {moment(row.fecha).format("DD-MM-YYYY")}
                              </span>
                            ),
                          },
                          { title: "Cantidad", field: "lote" },
                          { title: "Comentarios", field: "comentarios" },
                        ]}
                        data={adquisicionDetalles.adquisiciones}
                        actions={[
                          {
                            icon: () => (
                              <DeleteIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                              />
                            ),
                            tooltip: "Eliminar adquisición",
                            onClick: (event, rowData) => {
                              //console.log(rowData)
                              setAdquisicionEdit(rowData);
                              setDialogConfirm(true);
                            },
                          },
                        ]}

                        options={{
                          pageSize: 5, // make initial page size
                          pageSizeOptions: [5, 10, 20, 500],
                          actionsColumnIndex: -1,
                          exportButton: false,
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : null}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setDialogAdquisiciones(false)
            }}
              className={classes.botondanger}>
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </Container>

      {/* Dialogo para confirmar eliminación de adquisicion */}
      <Container>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={dialogConfirm}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Eliminar aquisición"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid>
                <font color="black"><b>¿Esta seguro de eliminar esta aquisición?</b></font><br />
                <b>Fecha:</b> {moment(adquisicionEdit.fecha).format("DD-MM-YYYY")}<br />
                <b>Cantidad:</b> {adquisicionEdit.lote}<br />
                <b>Comentarios:</b> {adquisicionEdit.comentarios}
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogConfirm(false)} color="secondary" variant="contained">
              Cancelar
            </Button>
            <Button onClick={() => handleEliminarAdquisicion()} className={classes.botondanger}>
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
      </Container>

      {/* Dialogo para agregar / editar company */}
      <Container>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={dialogCompany}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Editar Compañía"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid>
                <TextField
                  autoFocus
                  margin="dense"
                  id="coimentarios"
                  label="Compañía"
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    maxlength: 128
                  }}
                  FormHelperTextProps={{
                    className: classes.helperText
                  }}
                  value={nombreCompany}
                  onChange={(e) => {
                    setNombreCompany(e.target.value)
                  }}
                />
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setNombreCompany("");
                setDialogCompany(false);
              }}
              color="secondary" variant="contained">
              Cancelar
            </Button>
            <Button onClick={() => handleAddCompany()} className={classes.botongreen}>
              Modificar
            </Button>
          </DialogActions>
        </Dialog>
      </Container>


    </Container>
  );
}
