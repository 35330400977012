/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Previewpdf from "../PreviewPDF/PreviewPDF2";
//import logo from "../Logo.png";
import logo from "assets/img/ConCertezaAzul.png";
import PaymentForm from "../Firma/Firma";
import SubirId from "./SubirId";
import bandeja from "assets/img/bandeja.png";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import DialogActions from "@material-ui/core/DialogActions";
import confirmacion from "assets/img/confirmation.svg";
import cancelar from "assets/img/cancelar.png";
import firmado from "assets/img/Firmaado.svg";
import errorDocumento from 'assets/img/errordocumento.png';
import axios from "axios";
import { isMobile } from "react-device-detect";
import { BrowserRouter as Router, useParams } from "react-router-dom";
import { Grid, Snackbar } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { whiteColor } from "assets/jss/material-dashboard-react";
import { green } from "@material-ui/core/colors";
import Review from "../ReviewPDF/ReviewPDF";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import { saveLog, saveLogCapture } from "containers/Logs/funcionesLog.js";
import QRCode from "qrcode.react";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import AddAPhoto from "@material-ui/icons/AddAPhoto";
import Check from "@material-ui/icons/Check";
import InfoIcon from "@material-ui/icons/Info";
import iconoQR from "assets/img/iconoQRCDO.jpeg";
import DetallesProceso from "containers/Firma/Detalles";
import Tooltip from "@material-ui/core/Tooltip";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import GetAppIcon from "@material-ui/icons/GetApp";

import StepConnector from "@material-ui/core/StepConnector";

import GroupAddIcon from "@material-ui/icons/GroupAdd";
import PhotoSizeSelectLargeIcon from "@material-ui/icons/PhotoSizeSelectLarge";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import GestureIcon from "@material-ui/icons/Gesture";

import PublishIcon from "@material-ui/icons/Publish";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useSelector, useDispatch } from "react-redux";
import { MySnackbar } from "components/Snackbar/MySnackbar";
import { CopyToClipboard } from "react-copy-to-clipboard";
import LinkIcon from "@material-ui/icons/Link";

import CdoApi from "services/Cdo";
import PVProcessor from "containers/PruebaVida/PVProcessor";
import PVSpeechProcessor from "containers/PruebaVida/PVSpeechProcessor";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Powered"}
      <Link color="inherit" href="https://certificaciondigital.online/">
        {" "}
        by CDO
      </Link>{" "}
    </Typography>
  );
}

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      " linear-gradient(79deg, rgba(76,175,80,1) 0%, rgba(102,187,106,1) 63%, rgba(92,184,96,1) 100%)",
  },
});

function CodigoQR() {
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          float: "left",
          marginTop: "0px",
          marginLeft: "10px",
        }}
      >
        <div style={{ float: "left" }}>
          <font size="1">Firma desde otro dispositivo</font>
          <br />
          <QRCode
            id="qr-gen"
            value={window.location.href}
            size={225}
            level={"H"}
            includeMargin={true}
          />
        </div>
      </div>
      <br />
      <br />
      <br />
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    padding: theme.spacing(1),
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 700,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",

    justifyContent: "flex-end",
  },
  buttons2: {
    display: "flex",

    justifyContent: "center",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  button2: {
    backgroundColor: green[500],
    color: whiteColor,
    alignItems: "center",
    top: "50%",
    left: "20%",
    marginTop: 12,
    marginLeft: 12,
  },
  buttonProgress: {
    color: green[500],
    alignItems: "center",
    top: "50%",
    left: "50%",
    marginTop: 12,
    marginLeft: 12,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    textAlign: "center",
  },
}));

export default function FirmaURL() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  let { idfirmante, idDoc, isLogged = false } = useParams();

  const [activeStep, setActiveStep] = useState(0);
  const [openComemt, setOpenComent] = useState(false);
  const [comment, setComment] = useState(false);
  const [idFirma, setidFirma] = useState("");
  const [frente, setFrente] = useState("");
  const [reverso, setReverso] = useState("");
  const [ocrFront, setOcrFront] = useState("");
  const [ocrBack, setOcrBack] = useState("");
  const [typeId, setTypeId] = useState(1);
  const [validacionId, setValidacionId] = useState(false);
  const [progrees, setProgrees] = useState(false);
  const [speechBlob, setSpeechBlob] = useState("");
  const [speechPhrase, setSpeechPhrase] = useState("");

  const [valuepin, setValuePin] = useState("");
  const [success, setSuccess] = useState(false);
  const [idDocumento, setidDocumento] = useState("");
  const [nombreFirmante, setNombreFirmante] = useState("");
  const [dialogoQR, setDialogoQR] = useState(false);
  const [coordenadas, setCoordenadas] = useState("");
  const [dialogoInfo, setDialogoInfo] = useState(false);
  const [documento, setDocumento] = useState("");
  const [speechRequired, setSpeechRequired] = useState(false);
  const [numeroRan, setNumeroRan] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [snackData, setSnackData] = useState({
    openSnack: false,
    snackError: "",
    snackMessage: "",
    snackSeverity: "",
  });
  const { openSnack, snackError, snackMessage, snackSeverity } = snackData;

  const { user, signToken } = useSelector((state) => state.auth);
  const isIncompatibleBrowser =
    PVSpeechProcessor.isMediaRecorderAvailable() === false &&
    PVSpeechProcessor.isCaptureAvailable() === false;

  const getlink = () => {
    alert("Se copio en el portapapeles");
  };

  const [generatedToken, setGeneratedToken] = useState(false);

  useEffect(() => {

    const f = async () => {
      document.body.style.overflow = "auto";

      // Si no hay token temporal, crear uno
      await dispatch({
        type: "SET_SIGN_TOKEN",
        payload: null,
      });
      let token = signToken;
      try {
        token = await CdoApi.CdoAuth.checkin(idDoc, idfirmante);
      } catch (e) {
        // Si falla en generarse un token, entonces asignar uno invalido a proposito.
        token = 'FAIL_TO_GENERATE';
      }
      window.sessionStorage.setItem('signToken', token);
      await dispatch({
        type: "SET_SIGN_TOKEN",
        payload: token,
      });

      //datos del firmante
      axios
        .get(`${process.env.REACT_APP_URL}api/firmantes/${idfirmante}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setGeneratedToken(true);
          console.log("FIRMANTE:", res.data);
          setSpeechRequired(res.data.speech_required);
          setCoordenadas(res.data.signature_coords);
          setNombreFirmante(res.data.name + " " + res.data.last_name);
          if (res.data.firmas.length >= 1) {
            setActiveStep(8);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      //fin datos firmante

      axios
        .get(`${process.env.REACT_APP_URL}api/documentos/${idDoc}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("resInfoDocu", res.data.status.name);
          console.log(res.data);
          setDocumento(res.data);
          if (res.data.status.name === "firmado") {
            setActiveStep(5);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };

    f();
  }, []);

  var steps = ["Revisa documento", "Carga ID", "Firma"];
  if (speechRequired == true) {
    steps = ["Revisa documento", "Prueba de vida", "Carga ID", "Firma"];
  }

  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;
    let icons = {};

    if (speechRequired == true) {
      icons = {
        1: <FindInPageIcon />,
        2: <CameraAltIcon />,
        3: <AddAPhoto />,
        4: <GestureIcon />,
      };
    } else {
      icons = {
        1: <FindInPageIcon />,
        2: <AddAPhoto />,
        3: <GestureIcon />,
      };
    }

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {completed ? <Check /> : icons[String(props.icon)]}
      </div>
    );
  }

  const handleCloseSnack = () => {
    setSnackData({
      openSnack: false,
      snackError: "",
      snackMessage: "",
      snackSeverity: "",
    });
  };

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  const handleInput = (event) => {
    setValuePin(event.target.value);
  };

  const handleNext = async (result) => {
    if (result && result.videoBlobUrl) {
      console.log("SAVED_SPEECH_BLOB", result.videoBlobUrl);
      setSpeechBlob(result.videoBlobUrl);
      setSpeechPhrase(result.clave);
    }
    setActiveStep(activeStep + 1);
  };
  const closeComment = () => {
    setOpenComent(false);
  };

  const handleConfirmar = async () => {
    setSuccess(true);
    const token = sessionStorage.signToken;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: " application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const urlInit = `${process.env.REACT_APP_URL}api/firmas/${idFirma}/confirm?pin=${valuepin}`;
    console.log("ULRINIT CON ID", urlInit);
    axios
      .put(urlInit, {}, config)
      .then(async (response) => {
        //Guardar log confirmar
        //buscar datos firmante
        const token = sessionStorage.signToken;
        axios
          .get(`${process.env.REACT_APP_URL}api/firmantes/${idfirmante}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            saveLog(
              token,
              res.data.name + " " + res.data.last_name + " confirmó " + idDoc
            );
            saveLogCapture(
              token,
              res.data.name + " " + res.data.last_name + " confirmó " + idDoc
            );
          })
          .catch((error) => {
            console.error(error);
          });
        //fin log
        console.log("RES DE CONFIRM");

        await setidDocumento(idDoc);
        setTimeout(setActiveStep(6), 1500);
        setSuccess(false);
      })
      .catch((error) => {
        // Error 😨
        setSuccess(false);

        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data.message);
          alert(error.response.data.message);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  const imageB64 = async (firma) => {
    const url = `${process.env.REACT_APP_URL}api/firmas`;
    const token = sessionStorage.signToken;
    let base64 = firma instanceof Blob ? firma : dataURLtoBlob(firma);
    let blobFront = frente instanceof Blob ? frente : dataURLtoBlob(frente);
    let blobBack = "";
    if (reverso)
      blobBack = reverso instanceof Blob ? reverso : dataURLtoBlob(reverso);
    //validacion  de la data cuando es requerido la prueba de vida, video y texto

    const data = new FormData();

    // const frenteExtension = frente.split(":")[1].split(";")[0];
    // const reversoExtension = reverso.split(":")[1].split(";")[0];
    // const firmaExtension = firma.split(":")[1].split(";")[0];

    console.log("Frente Blob", frente, "Reverso Blob: ", reverso);

    data.append("graphic_signature", base64);
    data.append("firmantes_id", idfirmante);
    data.append("photo_front", blobFront);
    data.append("photo_back", blobBack);

    if (speechRequired == true) {
      const videoBlob = await fetch(speechBlob).then((e) => e.blob());
      data.append("speech_filename", videoBlob);
      data.append("speech_text", speechPhrase);
    }

    if (ocrFront !== "") {
      data.append("photo_front_ocr", ocrFront);
    }

    if (ocrBack !== "") {
      data.append("photo_back_ocr", ocrBack);
    }

    data.append("firma_photoid_types_id", typeId);

    setProgrees(true);
    await axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
        crossDomain: true,
      })
      .then((response) => {
        console.log("SUCCESS FIRST REQUEST");
        //Guardar log Registró Grafo firma
        //buscar datos firmante
        const token = sessionStorage.signToken;
        axios
          .get(`${process.env.REACT_APP_URL}api/firmantes/${idfirmante}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            saveLog(
              token,
              res.data.name +
              " " +
              res.data.last_name +
              " registró grafo " +
              idDoc
            );
            saveLogCapture(
              token,
              res.data.name +
              " " +
              res.data.last_name +
              " registró grafo " +
              idDoc
            );
          })
          .catch((error) => {
            if (error.response) {
              console.log(error);
              setSnackData({
                openSnack: true,
                snackError: error,
              });
              // console.log(error.response.status);
              // console.log(error.response.headers);
            }
            setProgrees(false);

            return;
          });
        //fin log
        console.log("successds", response.data);
        let datares = response.data[1];
        console.log("success", datares._id);
        setidFirma(datares._id);
        handleNext();
        setProgrees(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error);
          setSnackData({
            openSnack: true,
            snackError: error,
          });
          console.log(error.response.status);
          console.log(error.response.headers);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        }
        setProgrees(false);
        setOpenAlert(true);
        // console.log("Error servidor", error.response);
        // alert(err.message);
      });
  };

  const openDialogcomment = () => {
    setOpenComent(true);
  };
  const handleInputComment = (event) => {
    setComment(event.target.value);
    console.log("comentario", comment);
  };

  function handleAlerta() {
    console.log("Alerta");
    const token = sessionStorage.signToken;
    const data = new FormData();
    data.append("uid", documento.uid);
    data.append("asunto", "Rechazo de firma: " + documento.filename);
    data.append(
      "contenido",
      "El documento <u>" +
      documento.filename +
      "</u> (id:" +
      documento._id +
      ") ha sido rechazado por <u>" +
      nombreFirmante +
      "</u>. Motivo: <u>" +
      comment +
      "</u>."
    );
    data.append("severidad", 1);
    data.append("estatus", 1);
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_URL}api/alertas`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    return new Promise(function (resolve, reject) {
      axios(config).then(
        (response) => {
          var result = response.data;
          console.log("Processing Request");
          console.log("Alerta rechazo:", response.data);
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  const handleRechazar = () => {
    const token = sessionStorage.signToken;
    axios
      .put(
        `${process.env.REACT_APP_URL}api/firmantes/${idfirmante}/refuse?comment=${comment}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setSnackData({
          openSnack: true,
          snackMessage: "Ha rechazado firmar el presente documento",
          snackSeverity: "success",
        });
        // alert("acaba de rechazar el firmado del documento");
        handleAlerta();
        setActiveStep(14);
        closeComment();
      });
  };
  const getStepContent = (step) => {
    if (speechRequired == true) {
      switch (step) {
        case 0:
          return <Previewpdf documenType={documento} />;
        case 1:
          return <PVProcessor handleNext={handleNext} />;
        case 2:
          return (
            <SubirId
              isLogged={isLogged}
              idfirmante={idfirmante}
              setFrente={setFrente}
              setReverso={setReverso}
              setTypeId={setTypeId}
              handleNext={handleNext}
              setValidacionId={setValidacionId}
              setOcrFront={setOcrFront}
              setOcrBack={setOcrBack}
            />
          );
        case 3:
          return (
            <PaymentForm
              isLogged={isLogged}
              handleNext={handleNext}
              imageB64={imageB64}
              progrees={progrees}
            />
          );
        default:
          throw new Error("Unknown step");
      }
    } else {
      switch (step) {
        case 0:
          console.log(documento, "PREVIEW DATA INFO");
          return <Previewpdf documenType={documento} />;
        case 1:
          return (
            <SubirId
              isLogged={isLogged}
              idfirmante={idfirmante}
              setFrente={setFrente}
              setReverso={setReverso}
              setTypeId={setTypeId}
              handleNext={handleNext}
              setValidacionId={setValidacionId}
              setOcrFront={setOcrFront}
              setOcrBack={setOcrBack}
            />
          );
        case 2:
          return (
            <PaymentForm
              isLogged={isLogged}
              handleNext={handleNext}
              imageB64={imageB64}
              progrees={progrees}
            />
          );
        default:
          throw new Error("Unknown step");
      }
    }
  };

  let disableform = true;
  if (comment.length >= 5) {
    disableform = false;
  }
  let disablePin = true;

  if (valuepin.length > 3) {
    disablePin = false;
  }

  let disablebutton = false;
  /*
  if (activeStep === steps.length - 2 && (frente === "" || reverso==="") ) {
    disablebutton = true;
  }
*/
  if (activeStep === steps.length - 2) {
    console.log("tipo en FIrma:", typeId);
    if (typeId === 1 || typeId === "1") {
      if (frente === "" || reverso === "" || validacionId === false) {
        disablebutton = true;
      }
    } else {
      if (typeId === "2") {
        if (frente === "" || validacionId === false) {
          disablebutton = true;
        }
      }
    }
  }

  const handleDescargarPDF = async () => {
    const token = sessionStorage.signToken;

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/documentos/${documento._id}/preview/download`,
      //responseType: "blob", // important
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config).then((response) => {
      const linkSource = `data:application/pdf;base64,${response.data}`;
      const downloadLink = document.createElement("a");
      const fileName = documento.filename;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });
  };

  if (!generatedToken)
    return (<div>
      <Backdrop className={classes.backdrop} open={true}>
        <Grid>
          <CircularProgress size={44} className={classes.buttonProgress} />
        </Grid>
      </Backdrop>
    </div>);

  if (signToken === 'FAIL_TO_GENERATE') {
    return (<React.Fragment>
      <CssBaseline />
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <a href="https://certificaciondigital.online/" target="_blank">
            <img src={logo} alt="logo" width="220" height="60" />
          </a>
          {user.my_assets && (
            <>
              <h5 className={classes.title}></h5>
              <Button
                autoFocus
                variant="contained"
                color="primary"
                onClick={() => {
                  history.goBack();
                }}
              >
                Regresar
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <React.Fragment>
            <Typography variant="h5" gutterBottom align={"center"}>
              No se ha encontrado el documento.
            </Typography>
            <Typography variant="subtitle1" align={"center"}>
              El documento que desea consultar no se ha encontrado. Es posible que el enlace haya expirado.
              <br />
              Con-Certeza agradece su participacion.
            </Typography>
            <div align="center">
              <img src={errorDocumento} alt="logo" width="350" height="240" />
            </div>
          </React.Fragment>
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>)
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <a href="https://certificaciondigital.online/" target="_blank">
            <img src={logo} alt="logo" width="220" height="60" />
          </a>
          {user.my_assets && (
            <>
              <h5 className={classes.title}></h5>
              <Button
                autoFocus
                variant="contained"
                color="primary"
                onClick={() => {
                  history.goBack();
                }}
              >
                Regresar
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Stepper
            activeStep={activeStep}
            className={classes.stepper}
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          {/*BOTONES*/}

          {activeStep === 0 ? (
            <div className={classes.buttons2}>
              {activeStep === 0 && (
                <React.Fragment>
                  {isMobile ? (
                    ""
                  ) : (
                    <Tooltip title="Da clic para descargar el documento en PDF">
                      <Button
                        onClick={() => {
                          handleDescargarPDF();
                        }}
                        //color="secondary"
                        className={classes.button}
                        startIcon={<GetAppIcon />}
                        style={{ backgroundColor: "#00acc1", color: "white" }}
                      >
                        {isMobile ? "" : "Descargar"}
                      </Button>
                    </Tooltip>
                  )}

                  <Tooltip title="Da clic para obtener un código QR y firma desde otro dispositivo.">
                    <Button
                      onClick={() => {
                        setDialogoQR(true);
                      }}
                      //color="secondary"
                      className={classes.button}
                      startIcon={
                        <img
                          src={iconoQR}
                          alt="Con-Certeza"
                          width="30"
                          height="30"
                        />
                      }
                      style={{ backgroundColor: "#00acc1", color: "white" }}
                    >
                      {isMobile ? "" : "Escanea"}
                    </Button>
                  </Tooltip>

                  <Button
                    onClick={() => {
                      setDialogoInfo(true);
                    }}
                    //color="secondary"
                    className={classes.button}
                    style={{ backgroundColor: "#00acc1", color: "white" }}
                  >
                    {isMobile ? (
                      <font size="1">info</font>
                    ) : (
                      "Información adicional"
                    )}
                  </Button>

                  <Button
                    onClick={openDialogcomment}
                    //color="secondary"
                    className={classes.button}
                    style={{ backgroundColor: "red", color: "white" }}
                  >
                    {isMobile ? <font size="1">rechazar</font> : "rechazar"}
                  </Button>
                </React.Fragment>
              )}
              {speechRequired == true ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                >
                  Siguiente
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                  disabled={disablebutton}
                >
                  {activeStep === steps.length - 1
                    ? "Mandar a firma"
                    : activeStep === steps.length - 2 && frente
                      ? "Firmar"
                      : activeStep === steps.length - 3
                        ? "Cargar ID"
                        : activeStep === steps.length - 4
                          ? "Siguiente"
                          : null}
                </Button>
              )}
            </div>
          ) : null}
          <br />
          {activeStep === 0 && (
            <center>
              <Typography align="center" variant="h7" gutterBottom>
                TIPO DE PROCESO DE FIRMA:{" "}
                <strong>
                  {documento && documento.type.name.toUpperCase()}
                </strong>
              </Typography>
            </center>
          )}
          {/*FIN BOTONES*/}
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  ¡Muchas gracias por utilizar nuestra plataforma!
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{ textTransform: "none" }}
                >
                  Se registró con éxito tu firma. Para culminar el proceso
                  deberás registrar el código de confirmación que te será
                  enviado.
                </Typography>
                <div align="center">
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="pin"
                      name="pin"
                      label="Código"
                      type="number"
                      fullWidth
                      autoComplete="given-name"
                      onChange={handleInput}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 4);
                      }}
                      error={valuepin.length > 3 ? false : true}
                    />
                  </Grid>
                </div>
                <div align="center">
                  <img src={bandeja} alt="logo" width="260" height="220" />
                </div>
                <div align="right">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleConfirmar}
                    disabled={disablePin}
                    className={classes.button}
                  >
                    confirmar
                  </Button>
                </div>
              </React.Fragment>
            ) : activeStep === 6 ? (
              <React.Fragment>
                <Review idDocumento={idDocumento} setFirmado={handleNext} />
              </React.Fragment>
            ) : activeStep === 14 ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  La firma del documento ha sido rechazada
                </Typography>
                <Typography variant="subtitle1">
                  Al haberlo rechazado, no se generará ningún entregable final.
                  No se requiere ninguna acción adicional de su parte.
                  <br />
                  Firma Digital Con-Certeza agradece tu participación.
                  <br />
                </Typography>
                <div align="center">
                  <img src={cancelar} alt="logo" width="350" height="240" />
                </div>
              </React.Fragment>
            ) : activeStep === 5 ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom align={"center"}>
                  El documento ya ha sido firmado
                </Typography>
                <Typography variant="subtitle1" align={"center"}>
                  El documento que desea consultar ya ha sido firmado o no esta
                  disponible.
                  <br />
                  Con-Certeza agradece su participacion.
                </Typography>
                <div align="center">
                  <img src={firmado} alt="logo" width="350" height="240" />
                </div>
              </React.Fragment>
            ) : activeStep === 8 ? (
              <React.Fragment>
                <Typography
                  variant="subtitle1"
                  align={"center"}
                  style={{ textTransform: "none" }}
                >
                  Ya ha plasmado su firma en el documento, ahora deberá
                  registrar el código de confirmación el cual será proporcionado
                  vía correo electrónico o mensaje de texto (SMS).
                  <br />
                  Con-Certeza agradece su participación.
                  <br />
                  <a
                    href="https://certificaciondigital.online/"
                    target="_blank"
                  >
                    https://certificaciondigital.online/
                  </a>{" "}
                </Typography>
                <div align="center">
                  <img src={confirmacion} alt="logo" width="350" height="240" />
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <center>{getStepContent(activeStep)}</center>
                {activeStep === steps.length - 1 ? (
                  ""
                ) : (
                  <div className={classes.buttons}>
                    {activeStep === 0 && (
                      <React.Fragment>
                        {isMobile ? (
                          <Button
                            onClick={() => {
                              handleDescargarPDF();
                            }}
                            //color="secondary"
                            className={classes.button}
                            startIcon={<GetAppIcon />}
                            style={{
                              backgroundColor: "#00acc1",
                              color: "white",
                            }}
                          >
                            {isMobile ? "" : "Descargar"}
                          </Button>
                        ) : (
                          ""
                        )}
                        <Button
                          onClick={() => {
                            setDialogoInfo(true);
                          }}
                          //color="secondary"
                          className={classes.button}
                          style={{ backgroundColor: "#00acc1", color: "white" }}
                        >
                          {isMobile ? (
                            <font size="1">info</font>
                          ) : (
                            "Información adicional"
                          )}
                        </Button>
                        <Button
                          onClick={openDialogcomment}
                          color="secondary"
                          className={classes.button}
                          style={{ backgroundColor: "red", color: "white" }}
                        >
                          {isMobile ? (
                            <font size="1">rechazar</font>
                          ) : (
                            "rechazar"
                          )}
                        </Button>
                      </React.Fragment>
                    )}
                    {speechRequired === true &&
                      activeStep === steps.length - 3 ? (
                      ""
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                        disabled={disablebutton}
                      >
                        {activeStep === steps.length - 1
                          ? "Mandar a firma"
                          : activeStep === steps.length - 2 && frente
                            ? "Firmar"
                            : activeStep === steps.length - 3
                              ? "Cargar ID"
                              : activeStep === steps.length - 4
                                ? "Siguiente"
                                : null}
                      </Button>
                    )}
                  </div>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        <Copyright />
      </main>
      <div>
        <Dialog
          open={openComemt}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Escriba el motivo de rechazo"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <TextField
                  id="comment"
                  label="Comentario"
                  multiline
                  rows={4}
                  variant="outlined"
                  onChange={handleInputComment}
                  inputProps={{ maxLength: 128 }}
                />
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={closeComment}
              variant="contained"
              color="secondary"
            >
              Cerrar
            </Button>
            <Button
              onClick={handleRechazar}
              variant="contained"
              color="primary"
              disabled={disableform}
            >
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Backdrop className={classes.backdrop} open={success}>
        <Grid>
          <CircularProgress size={44} className={classes.buttonProgress} />
          <br></br>
          <Typography variant="body2" color="textSecondary" align="center">
            Espera un poco
          </Typography>
        </Grid>
      </Backdrop>
      {/*DIALOGO INFORMACIÓN DEL PROCESO*/}
      <Dialog
        open={dialogoInfo}
        TransitionComponent={Transition}
        keepMounted
        fullScreen={false}
        fullWidth={true}
        maxWidth={"lg"}
        // onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title"></DialogTitle>
        <DialogContent>
          <DetallesProceso />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogoInfo(false);
            }}
            color="success"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      {/*FIN DIALOGO INFO*/}
      <Dialog
        open={dialogoQR}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Escanea el código QR"}
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <CodigoQR />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setDialogoQR(false);
            }}
            style={{ backgroundColor: "#00acc1", color: "white" }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isIncompatibleBrowser && speechRequired}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {
            "El emisor de este documento, solicitó realizar prueba de vida de las personas que lo firmarán."
          }
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <DialogContentText id="alert-dialog-description">
              Su navegador no es compatible para realizar prueba de vida.
              Intenta ingresar con Chrome, Edge o Firefox.
            </DialogContentText>
            <CopyToClipboard text={window.location.href}>
              <Button
                variant="contained"
                startIcon={<LinkIcon />}
                onClick={() => {
                  getlink();
                }}
                style={{ backgroundColor: "#00acc1", color: "white" }}
              >
                Copiar URL
              </Button>
            </CopyToClipboard>
          </Grid>
        </DialogContent>
      </Dialog>

      <MySnackbar
        openAlert={openSnack}
        onClose={handleCloseSnack}
        error={snackError} //Opcional
        message={snackMessage} //Opcional
        severity={snackSeverity} //Opcional
        duration={10000}
      />
    </React.Fragment>
  );
}
