import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Catalogos from "./Catalogos";
import Grupos from "./Grupos";
import Flujos from "./Flujos";
import NewFlow from "./NewFlow";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  button:{
    margin:10,
  },
}));

export default function Inicio() {
 const classes = useStyles();
 const [opcion, setOpcion] = React.useState(0);
  return (
    <React.Fragment>
    <Button
      className={classes.button}
      disabled={opcion==0?true:false}
      variant="contained"
      color="primary"
      onClick={()=>{setOpcion(0)}}
      >
      Catálogos
    </Button>
    <Button
      className={classes.button}
      disabled={opcion==1?true:false}
      variant="contained"
      color="primary"
      onClick={()=>{setOpcion(1)}}
    >
      Grupos
    </Button>
    <Button
      className={classes.button}
      disabled={opcion==2?true:false}
      variant="contained"
      color="primary"
      onClick={()=>{setOpcion(2)}}
    >
      Flujos
    </Button>
    <hr />
    {opcion==0 ?<Catalogos />:null}
    {opcion==1 ?<Grupos />:null}
    {opcion==2 ?<Flujos />:null}
    {opcion==3 ?<NewFlow />:null}
    </React.Fragment>
  )

}
