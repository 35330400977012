import React from "react";
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import ReactFlow, {
  removeElements,
  addEdge,
  Controls,
  Handle
} from "react-flow-renderer";

const useStyles = makeStyles((theme) => ({

  paper: {
    width: "80%",
    margin: 2,
    textAlign: "center",
    margin: "auto"
  },
  paper1: {
    width: "80%",
    margin: 2,
    textAlign: "center"
  },
  paper2: {
    width: "80%",
    margin: 1,
    //textAlign: "center"
  },
  paper3: {
    width: "80%",
    margin: 0,
    textAlign: "right",
    backgroundColor: "white"
  },
  formControl: {
    margin: theme.spacing(1),
    //minWidth: 120,
    width: "80%"
  },
  botondanger: {
    backgroundColor: "red",
    color: "white"
  },
  botongreen: {
    backgroundColor: "green",
    color: "white",
    margin: 10
  },
  gridsmall: {
    margin: 10,
    backgroundColor: "white",
    border: "1px solid #777",
  },
  iconsmall: {
    fontSize: 10,
    margin: 2,
    marginTop: 11, //15 center
    padding: 0,
  },
  iconsmall2: {
    fontSize: 10,
    margin: 2,
    marginTop: 5, //15 center
    padding: 0,
  },
  iconsmall3: {
    fontSize: 8,
    //margin:2,
    //marginTop:5, //15 center
    //padding:0,
  },
  colapse: {
    marginLeft: 20,
  },
  nombrecatalogo: {
    fontSize: 14,
    marginTop: 0,
    marginLeft: 10,
    textAlign: "left"
  },
  nombrearchivo: {
    marginTop: 6,
    fontSize: 12,
  },
  iconarchivo: {
    marginTop: 0,
    fontSize: 10,
  },
  p: {
    width: "90%",
    margin: "0",
    lineHeight: "1",
    fontSize: "6px"
  },
  divtittle: {
    backgroundColor: "#3f51b5",
    //backgroundColor:"#fb8c00",
    //backgroundColor:"#00acc1",
    color: "white"
  },
}));

export default function ExpedienteGraph(props) {
  const classes = useStyles();
  const [grupos, setGrupos] = React.useState("");
  const [catalogos, setCatalogos] = React.useState("");


  const InputNode = ({ type, data, id }) => {
    const classes = useStyles();
    function nombreGrupo(idgrupo) {
      const nombre = ""
      if (data.lista_grupos)
        data.lista_grupos.find(item => item._id == idgrupo);
      if (nombre) {
        return (nombre.name);
      }
      return (null);
    }
    function nombreCatalogo(idcatalogo) {
      const nombre = ""
      if (data.lista_catalogos)
        data.lista_catalogos.find(item => item._id == idcatalogo);
      if (nombre) {
        return (nombre.name);
      }
      return (null);
    }

    return (
      <>
        <Handle type="target" position="left" />
        <div className={classes.divtittle} style={{ backgroundColor: data.status == 0 ? "red" : "green" }}>
          <p className={classes.p}><font style={{ marginLeft: 2 }}><b>Proceso:</b></font></p>
          <p className={classes.p}><font style={{ marginLeft: 2 }}>{data.value}</font></p>
        </div>
        {/*
          <p className={classes.p}>
          {data.status==0?(
            <font style={{fontSize:"5px"}} color="red">
              <b>Estatus:</b> Pendiente
              </font>
          ):(
            <font style={{fontSize:"5px"}} color="green">
              <b>Estatus:</b> Completado
              </font>
          )}
          </p>
        <hr style={{margin:0}} />
        */}
        <p className={classes.p}>&nbsp;</p>
        <p className={classes.p}></p>
        <p className={classes.p}><font><b>Responsable:</b></font></p>
        <p className={classes.p}><font>{nombreGrupo(data.workgroup_id)}</font></p>
        <p className={classes.p}>&nbsp;</p>
        <p className={classes.p}></p>
        <p className={classes.p}><font><b>Catálogos:</b></font></p>
        {data.catalogos && data.catalogos.map((item, key) => (
          <p key={key} className={classes.p}><font><b>-</b>{nombreCatalogo(item)}</font></p>
        ))}
        <Handle type="source" position="right" id="a" />
      </>
    );
  };

  const nodeTypes = {
    InputNode: InputNode,
  };

  const onLoad = (reactFlowInstance) => {
    console.log('flow loaded:', reactFlowInstance);
    reactFlowInstance.fitView();
  };


  function DisplayFlowGraph() {
    //console.log("Grafico");
    var nodos = [];
    var x = 0;
    //ordenar
    //ordenar
    props.expediente.nodes.sort(function (a, b) {
      if (a.step_id > b.step_id) {
        return 1;
      }
      if (a.step_id < b.step_id) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    //fin ordenar
    //fin ordenar
    for (var i = 0; i < props.expediente.nodes.length; i++) {
      x = x + 100;
      nodos = [...nodos,
      {
        type: 'InputNode',
        id: props.expediente.nodes[i].step_id,
        data: {
          value: props.expediente.nodes[i].name,
          status: props.expediente.nodes[i].status,
          workgroup_id: props.expediente.nodes[i].workgroup_id,
          catalogos: props.expediente.nodes[i].catalogos,
          flow_id: props.expediente._id,
          lista_grupos: grupos,
          lista_catalogos: catalogos,
        },
        position: { x: x, y: 25 },
        style: {
          border: "1px solid #3f51b5",
          padding: 3,
          width: 80
        }
      }
      ]
    }
    //flechas
    for (var i = 0; i < props.expediente.nodes.length; i++) {
      if (props.expediente.nodes[i].prev_step != "null" && props.expediente.nodes[i].prev_step) {
        nodos = [...nodos,
        {
          arrowHeadType: 'arrowclosed',
          id: props.expediente.nodes[i].prev_step + "-" + props.expediente.nodes[i].step_id,
          source: props.expediente.nodes[i].prev_step,
          sourceHandle: "a",
          target: props.expediente.nodes[i].step_id,
          targetHandle: null
        }
        ]
      }
    }
    console.log("elements", nodos);
    //setElements(nodos);

    let height = 300;
    if (props.height)
      height = props.height;
    return (
      <div style={{ height: height, width: "100%", margin: 0 }}>
        <ReactFlow
          elements={nodos}
          onLoad={onLoad}
          //defaultZoom={0.5}
          snapToGrid={true}
          snapGrid={[15, 15]}
          nodeTypes={nodeTypes}
        >
          {/*  <Controls /> */}
        </ReactFlow>
      </div>
    )
  }


  async function getGrupos() {
    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_URL}api/workgroups`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    };
    axios(config)
      .then(function (response) {
        setGrupos(response.data);
      })
      .catch(function (error) {
        console.log(error);
        setGrupos("");
      });
  }



  async function getCatalogos() {
    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_URL}api/catalogos`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    };
    axios(config)
      .then(function (response) {
        setCatalogos(response.data);
      })
      .catch(function (error) {
        console.log(error);
        setCatalogos("");
      });
  }

  React.useEffect(() => {
    getGrupos();
    getCatalogos();
  }, []);

  if (props.expediente)
    return <DisplayFlowGraph />
  return (null)

}
