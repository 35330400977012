import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
//import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import MaterialTable from "material-table";
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DialogComp } from "components/Dialog/DialogComp";
import PDFView from "containers/Expedientes/PDFView";
import Paper from '@material-ui/core/Paper';
import Select from "@material-ui/core/Select"
import FormControl from "@material-ui/core/FormControl"
import MenuItem from "@material-ui/core/MenuItem"
import InputLabel from "@material-ui/core/InputLabel"

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  botonupload: {
    marginLeft: 5,
  },
  titulo: {
    color: "black",
    fontWeight: "bold"
  },
}));
const useStylestable = makeStyles(styles2);

const localization = {
  body: {
    emptyDataSourceMessage: "No hay datos por mostrar",
    addTooltip: "Añadir",
    deleteTooltip: "Eliminar",
    editTooltip: "Editar",
    filterRow: {
      filterTooltip: "Filtrar",
    },
  },
  header: {
    actions: "",
  },
  pagination: {
    firstAriaLabel: "Primera página",
    firstTooltip: "Primera página",
    labelDisplayedRows: "{from}-{to} de {count}",
    labelRowsPerPage: "Filas por página:",
    labelRowsSelect: "filas",
    lastAriaLabel: "Ultima página",
    lastTooltip: "Ultima página",
    nextAriaLabel: "Pagina siguiente",
    nextTooltip: "Pagina siguiente",
    previousAriaLabel: "Pagina anterior",
    previousTooltip: "Pagina anterior",
  },
  toolbar: {
    exportAriaLabel: "Exportar",
    exportTitle: "Exportar",
    exportCSVName: "Exportar a CVS",
    exportPDFName: "Exportar a PDF",
    searchPlaceholder: "Buscar",
    searchTooltip: "Buscar",
  },
};

export default function Adjuntos(props) {
  const classes = useStyles();
  const classes2 = useStylestable();
  const [nombreAdjunto, setNombreAdjunto] = useState("");
  const [adjuntos, setAdjuntos] = useState("");
  const [entro, setEntro] = React.useState(0);
  const [id, setId] = React.useState("");
  const [openZip, setOpenZip] = useState(false);
  const [base64PDF, setbase64PDF] = useState();
  const [corporaciones, setCorporaciones] = useState([]);
  const [clases, setClases] = useState([]);


  const [modalPDF, setModalPDF] = React.useState(false);

  const [tipoRoles, setTiporoles] = React.useState({
    codigo: "Seleccione el nombre del remitente",
    name: "Seleccione el nombre del remitente",
  })
  const [tipoClases, setTipoClases] = React.useState({
    codigo: "Selecciona la clase de documento ",
    name: "Selecciona la clase de documento ",
  })
  const [idCompany, setIdCompany] = React.useState("")
  const [idClases, setIdClases] = React.useState("")
  const [selectRol, setSelectRol] = React.useState([])
  const [selectClases, setSelectClases] = React.useState([])
  //console.log("documento",props.documento);

  const handlechangeRoles = (event) => {
    console.log("eventssd", event.target.value)
    setIdCompany(event.target.value)
    let datal = {
      codigo: event.target.value,
      name: event.target.value,
    }
    setTiporoles(datal)
  }
  const handlechangeClases = (event) => {
    console.log("idclases", event.target.value)
    setIdClases(event.target.value)
    let datal = {
      codigo: event.target.value,
      name: event.target.value,
    }
    setTipoClases(datal)
  }


  if (props.documento && entro == 0) {
    getAdjuntos(props.documento._id);
    handleCorporativo();
    setId(props.documento._id);
    setEntro(1);
  } else {
    if (props.documento._id != id) setEntro(0);
  }


  useEffect(() => {
    handleclases();
  }, []);
  //funcion para visualizar imagenes adjuntas
  const previewAdjuntoPDF = (idDocu, fileName) => {
    //const nameFormat = fileName.slice(0, -4);
    //setOpenZip(true);
    let token = sessionStorage.getItem("Token");
    axios({
      url: `${process.env.REACT_APP_URL}api/adjuntos/download/${idDocu}`,
      method: "GET",
      responseType: "blob", // important
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const imageBlob = new Blob([response.data]);
        const reader = new FileReader();
        reader.readAsDataURL(imageBlob);
        reader.onloadend = () => {
          const base64data = reader.result;
          setbase64PDF(base64data);
          setModalPDF(true);
        };
      })
      .catch((error) => {
        console.error(error);
      });
  };



  //funcion para descargar Acta
  const downloadAdjunto = (idDocu, fileName) => {
    setOpenZip(true);
    let token = sessionStorage.getItem("Token");
    axios({
      url: `${process.env.REACT_APP_URL}api/adjuntos/download/${idDocu}`,
      method: "GET",
      responseType: "blob", // important
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}`);
        document.body.appendChild(link);
        link.click();
        setOpenZip(false);
      })
      .catch((error) => {
        console.error(error);
        setOpenZip(false);
      });
  };

  //get corporaciones en caso de no tener
  //const handleCorporativo = () => {
  function handleCorporativo() {
    let token = sessionStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/corporaciones`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("RESPCORPO", res.data);
        var RESPCORPO = res.data;
        var collection = []
        RESPCORPO.map(function (data) {
          var rolesItem = {
            codigo: data._id,
            name: data.name,
          }
          collection.push(rolesItem)
        })
        console.log("COLLECTION", collection)
        setSelectRol(collection)
        setCorporaciones(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  function handleclases() {
    let token = sessionStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/clases`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("RESPCORPO", res.data);
        var RESPCORPO = res.data;
        var collection = []
        RESPCORPO.map(function (data) {
          var clasesItem = {
            codigo: data._id,
            name: data.name,
          }
          collection.push(clasesItem)
        })
        console.log("COLLECTION", collection)
        setSelectClases(collection)
      })
      .catch((error) => {
        console.error(error);
      });
  };
  async function getAdjuntos(id_documento) {
    if (id_documento) {
      /*
          let token = sessionStorage.getItem("Token");
          var config = {
          method: 'get',
          url: `${process.env.REACT_APP_URL}api/adjuntos/${id_documento}`,
          headers: {
            'Accept': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        };
    
        axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data));
          setAdjuntos(response.data);
        })
        .catch(function (error) {
          console.log(error);
          setAdjuntos([]);
        });
    */
    }
  }

  async function handleSolicitar() {
    const data = new FormData();
    data.append("fc_clase_id", idClases);

    let token = sessionStorage.getItem("Token");
    let url = `${process.env.REACT_APP_URL}api/documentos/fechacierta/${props.documento._id}/${idCompany}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: " application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .put(url, data, config)
      .then((response) => {
        // Respuesta del servidor
        console.log(response);
        //getAdjuntos(props.documento._id)
        //setNombreAdjunto("");
        props.refreshTables();
        props.handleCloseFechaCierta();
      })
      .catch((error) => {
        // Error 😨
        alert("Debe agregar Corporación");
      });
  };

  async function handleCancelar() {
    console.log("ID", props.documento._id);
    let token = sessionStorage.getItem("Token");
    let url = `${process.env.REACT_APP_URL}api/documentos/cancelarfechacierta/${props.documento._id}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: " application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .put(url, {}, config)
      .then((response) => {
        // Respuesta del servidor
        console.log(response);
        //getAdjuntos(props.documento._id)
        //setNombreAdjunto("");
        props.refreshTables();
        props.handleCloseFechaCierta();
      })
      .catch((error) => {
        // Error 😨
        alert("Debe agregar Corporación");
      });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <h3 className={classes.titulo}>{props.documento.filename}</h3>
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
        {(props.documento.fcstatus == null) ? (
          <>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <font color="black">¿Desea solicitar Fecha Cierta para este documento?</font>
              </Paper>
            </Grid>
            {props.documento.corporaciones_id ? null : (
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  {corporaciones.length > 0 ? (
                    <>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="remitente">Compañía del solicitante</InputLabel>

                        <Select
                          labelId="remitente"
                          id="remitenteid"
                          value={tipoRoles.codigo}
                          onChange={handlechangeRoles}
                        >
                          <MenuItem value="Seleccione el nombre del remitente" disabled>
                            Selecciona compañía del solicitante
                          </MenuItem>
                          {selectRol.map(({ codigo, name }, index) => (
                            <MenuItem key={index} value={codigo}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <br></br>
                    </>
                  ) : (
                    ""
                  )}
                  {selectClases.length > 1 ? (
                    <FormControl className={classes.formControl}>
                      <InputLabel id="clase">Clases de documento</InputLabel>

                      <Select
                        labelId="clase"
                        id="clase"
                        value={tipoClases.codigo}
                        onChange={handlechangeClases}
                      >
                        <MenuItem value="Selecciona la clase de documento " disabled>
                          Selecciona  la clase de documento
                        </MenuItem>
                        {selectClases.map(({ codigo, name }, index) => (
                          <MenuItem key={index} value={codigo}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : ""}
                  <br></br>
                </Paper>
              </Grid>
            )}
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  disabled={idCompany ? false : true}
                  onClick={() => {
                    //if(props.documento.corporaciones_id)
                    handleSolicitar();
                    /*
                  else
                    alert("Agregar corporación")//handleCorporativo();
                    */
                  }}
                >
                  ACEPTAR
                </Button>
                {props.handleCloseFechaCierta ? (
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    style={{ marginLeft: "10px" }}
                    onClick={props.handleCloseFechaCierta}
                  >
                    CANCELAR
                  </Button>
                ) : null}
              </Paper>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              Fecha Cierta: {props.documento.fcstatus.name}<br />
              Fecha de Solicitud: { }
              {props.documento.fc_date_requested
                ? moment(
                  props.documento.fc_date_requested
                ).format("DD/MM/YYYY")
                : null}<br />
            </Grid>
            {(props.documento.fc_status_id == 1 || props.documento.fc_status_id == 2) ? (
              <Button
                variant="contained"
                size="small"
                color="secondary"
                onClick={() => {
                  handleCancelar();
                }}
              >
                CANCELAR SOLICITUD
              </Button>
            ) : null}
          </>
        )}

      </Grid>
      <hr />
      <Grid container>
        <Grid item xs={12}>
          {adjuntos ? (
            <MaterialTable
              localization={localization}
              options={{
                //exportButton: { csv: false, pdf: false },
                exportAllData: true,
                draggable: false,
                pageSize: 5, // make initial page size
                pageSizeOptions: [5, 10, 20],
                actionsColumnIndex: -1,
              }}
              columns={[
                {
                  title: "Nombre",
                  field: "filename",
                },
                {
                  title: "Descripción",
                  field: "description",
                },
                {
                  title: "Fecha",
                  field: "created_at",
                  render: (row) => (
                    <span>
                      {row["created_at"]
                        ? moment(
                          row["created_at"]
                        ).format("DD/MM/YYYY")
                        : null}
                    </span>
                  ),
                },
                {
                  title: "Registrado por",
                  field: "users.name",
                },
              ]}
              data={adjuntos}
              title={"Adjuntos (" + adjuntos.length + ")"}
              actions={[
                (rowData) => ({
                  icon: () => (
                    <VisibilityIcon
                      className={
                        classes2.tableActionButtonIcon +
                        " " +
                        classes2.edit
                      }
                    />
                  ),
                  tooltip: "Visualizar adjunto",
                  onClick: (event, rowData) => {
                    previewAdjuntoPDF(rowData._id, rowData.filename);
                  },
                  // disabled: rowData.type.id === 5,
                }),
                /*
                {
                  icon: () => (
                    <VisibilityIcon
                      className={
                        classes2.tableActionButtonIcon +
                        " " +
                        classes2.edit
                      }
                    />
                  ),
                  tooltip: "Visualizar adjunto",
                  onClick: (event, rowData) => {
                    //if(rowData.filename.split(".")[1]=="png" || rowData.split(".")[1]=="pdf"){
                    //console.log(rowData.filename.split(".")[1])
                    if(rowData.filename.split(".")[1]=='png' || rowData.filename.split(".")[1]=='jpeg' || rowData.filename.split(".")[1]=='jpg' || rowData.filename.split(".")[1]=='pdf' ){
                      if(rowData.filename.split(".")[1]=='pdf')
                        previewAdjuntoPDF(rowData._id, rowData.filename);
                      else
                        previewAdjuntoImagen(rowData._id, rowData.filename);
                    }
                    else{
                      downloadAdjunto(rowData._id, rowData.filename);
                    }
                  },
                },
                */
                {
                  icon: () => (
                    <GetAppIcon
                      className={
                        classes2.tableActionButtonIcon +
                        " " +
                        classes2.edit
                      }
                    />
                  ),
                  tooltip: "Descargar acta",
                  onClick: (event, rowData) => {
                    downloadAdjunto(rowData._id, rowData.filename);
                  },
                },
              ]}
            />
          ) : null}
        </Grid>
      </Grid>

      {/* Dialogo de espera de descarga de adjunto */}
      <DialogComp
        open={openZip}
        title="Descarga de archivo adjunto"
        content="El archivo se descargará en el lugar habitual de Descargas. Esto puede tardar unos segundos..."
        grid={true}
      />

      {/* Dialogo para preview pdf */}
      <DialogComp
        open={modalPDF}
        title=""
        content={
          <>
            {base64PDF ? <PDFView base64={base64PDF} /> : ("NO hay PDF")}
          </>
        }
        buttons={[
          {
            buttonEvent: () => setModalPDF(false),
            buttonName: "Cerrar",
            buttonColor: "primary",
          },
        ]}
      />
    </>
  );
}
