import Http from './HttpProvider';

const CdoAuth = {
    async checkin(idDocumento, idFirmante, checkinWithFirmante = false) {
        const result = await Http.Fetch('GET', `api/auth/checkin/${idDocumento}/${idFirmante}?checkinWithFirmante=${checkinWithFirmante}`, null, 'application/json', false, false);
        if (!result || !result.token_name || !result.bearer_token)
            throw new Error("Could not generate temporary signature token.");

        return result.bearer_token;
    },
}

export default CdoAuth;