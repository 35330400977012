import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import ControlPanel from "components/controlPanel/ControlPanel";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";

export default function PDFView(props){
  var zoom=1.0
  if(props.scale){
    zoom=props.scale
  }
  const [scale, setScale] = React.useState(zoom);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pdfHeigth, setpdfHeigth] = React.useState(575);
  const [pdfWidth, setpdfWidth] = React.useState(660);


  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  return (
    <React.Fragment>
    {props.base64.length === 0 ? (
      <Backdrop>
        <CircularProgress size={44} />
      </Backdrop>
    ) : (
      <div align="center">
          <ControlPanel
            scale={scale}
            setScale={setScale}
            numPages={numPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            file={props.base64}
          />
        <Document
          error="Error al intentar cargar el PDF"
          loading="Cargando..."
          //file={`data: application / pdf; base64, ${base64} `}
          file={props.base64}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page
            scale={scale}
            height={pdfHeigth}
            width={pdfWidth}
            renderTextLayer={false}
            pageNumber={pageNumber}
          />
        </Document>

        <div className="text-center">
          <p>
            Página {pageNumber || (numPages ? 1 : "--")} de{" "}
            {numPages || "--"}
          </p>
          <button
            className="btn btn-dark"
            type="button"
            disabled={pageNumber <= 1}
            onClick={previousPage}
          >
            Atrás
          </button>{" "}
          <button
            className="btn btn-dark"
            type="button"
            disabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            Siguiente
          </button>
        </div>
      </div>
    )}
    </React.Fragment>
  )
}
