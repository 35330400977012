import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import { makeStyles } from "@material-ui/core/styles";
import rec from "assets/img/rec.gif";
import Button from "@material-ui/core/Button";
import WebcamStreamCapture from "./testVideo";
import Speech from "assets/img/speechToText.svg";
import { green } from "@material-ui/core/colors";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 500,
    },
    layout: {
        width: "auto",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(1000 + theme.spacing(2) * 2)]: {
            width: 1000,
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    buttonProgress2: {
        color: green[500],
        alignItems: "center",
        top: "50%",
        left: "50%",
        marginTop: 12,
        marginLeft: 12,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },

    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));

export default function TestVoice(props) {
    const classes = useStyles();

    const {
        recordedChunks,
        setRecordedChunks,
        numeroRan,
        setNumeroRan,
        handleNext,
    } = props;
    const [resultadoVoice, SetResulado] = React.useState("");
    const [escuchar, SetEscuchar] = React.useState(false);
    const [succes, SetSucces] = React.useState(false);
    const [dialogError, SetDialogError] = React.useState(false);
    const [frase, setFrase] = React.useState("");
    const [openSnack, setOpenSnack] = React.useState(false);
    const [progrees, setProgrees] = React.useState(false);

    const [recording, setRecording] = React.useState(false);

    React.useEffect(() => {
        numero();

        console.log("frase", frase);
    }, []);

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    const numero = () => {
        var randon = Math.floor(Math.random() * (10000 - 1000)) + 1000;
        setNumeroRan(randon);
        setFrase(
            `leí y estoy de acuerdo con el contenido del documento que se me presentó clave ${randon}`
        );
    };
    function navegador() {
        var agente = window.navigator.userAgent;
        var navegadores = [
            "Chrome",
            "Firefox",
            "Safari",
            "Opera",
            "Trident",
            "MSIE",
            "Edge",
        ];
        for (var i in navegadores) {
            if (agente.indexOf(navegadores[i]) != -1) {
                return navegadores[i];
            }
        }
    }
    var recognition = null;
    // PASO 2 ::: SI EL NAVEGADOR ES COMPATIBLE CONFIGURAR EL RECONOCIMIENTO DE VOZ
    if (
        navegador() == "Chrome" ||
        navegador() == "Edge" ||
        navegador() == "Safari"
    ) {
        // 2.1 Esta api tiene nombres distintos según el navegador porque aún está en fase experimental, por eso las listamos todas e instanciamos la primera que consiga
        recognition = new (window.SpeechRecognition ||
            window.webkitSpeechRecognition ||
            window.mozSpeechRecognition ||
            window.msSpeechRecognition)();
        // 2.2 Definimos el idioma a escuchar https://en.wikipedia.org/wiki/Language_localisation#:~:text=Examples%20of%20language%20tags
        recognition.lang = "es-US";
        // 2.3 Configuramos que cuando termine de reconocer algo vuelva a escuchar
        // 2.3 Pasamos la función que se llamará cuando haya un resultado del reconocimiento de voz

        recognition.onresult = (resultado) => {
            manejarResultado(resultado);
        };

        // 2.4 Empezamos a escuchar
    } else {
        alert("El Navegador no es compatible con el Reconocimiento de voz");
    }

    // PASO 3 DEFINIMOS LA FUNCIÓN QUE MANEJARÁ RESULTADO DEL RECONOCIMIENTO DE VOZ
    const manejarResultado = (resultado) => {
        // 3.1 PINTAMOS LOS RESULTADOS EN EL HTML

        SetResulado(resultado.results[0][0].transcript);
        if (resultado.results[0][0].transcript.toLowerCase().trim() == frase) {
            setProgrees(true);
            setOpenSnack(true);
            setTimeout(() => handleFinalizar(), 4500);
        } else {
            SetDialogError(true);
        }
    };

    const handleFinalizar = () => {
        SetSucces(true);
        setProgrees(false);
        handleNext();
    };
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenSnack(false);
    };

    const handlestart = () => {
        recognition.start();
    };
    return (
        <React.Fragment>
            <center>
                <div className={classes.typo}>
                    <h3> Lee en voz alta la frase en color azul.</h3>
                </div>
                <div className={classes.typo}>
                    <h3
                        style={{
                            color: "#376CFA",
                        }}
                    >
                        "Lei y estoy de acuerdo con el contenido del documento que se me
                        presentó. Clave {numeroRan}"
                    </h3>
                </div>
                <div>
                    {recording == true ? (
                        <img src={rec} alt="logo" width="80" height="30" />
                    ) : (
                        ""
                    )}
                    <WebcamStreamCapture
                        handlestart={handlestart}
                        resultadoVoice={resultadoVoice}
                        SetEscuchar={SetEscuchar}
                        succes={succes}
                        SetSucces={SetSucces}
                        recordedChunks={recordedChunks}
                        setRecordedChunks={setRecordedChunks}
                        SetResulado={SetResulado}
                        dialogError={dialogError}
                        SetDialogError={SetDialogError}
                        numero={numero}
                        setRecording={setRecording}
                    />
                </div>
            </center>

            <Snackbar
                open={openSnack}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={3500}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success">
                    Prueba exitosa
                </Alert>
            </Snackbar>
            <Backdrop className={classes.backdrop} open={progrees}>
                <CircularProgress size={44} className={classes.buttonProgress} />
            </Backdrop>
        </React.Fragment>
    );
}
