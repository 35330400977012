import React from "react";
import axios from "axios";
import moment from "moment";
import { makeStyles } from '@material-ui/core/styles';
import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import PDFView from "./PDFView";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStylestable = makeStyles(styles2);
const useStyles = makeStyles((theme) => ({
  grafico: {
    //backgroundColor:"#3f51b5",
    //color:"white",
    margin: 0,
    borderStyle: "groove",
    borderColor: "#3f51b5",
    //borderColor: "#fb8c00",
    //borderColor: "#00acc1",
    width: "100%",
  },
  appbar: {
    width: "100%",
    backgroundColor: "#3f51b5",
    //backgroundColor:"#fb8c00",
    //backgroundColor:"#00acc1",
    color: "white",
    margin: 0,
    textAlign: "center",
    //    borderStyle: "groove",
    //    borderColor: "#3f51b5",
  },
  nodoname: {
    width: "100%",
    //backgroundColor:"#3f51b5",
    backgroundColor: "GhostWhite",
    //color:"white",
    margin: 0,
    textAlign: "left",
    //    borderStyle: "groove",
    //    borderColor: "#3f51b5",
  },
  iconsmall: {
    fontSize: 12,
    margin: 2,
    marginTop: 8, //15 center
    padding: 0,
  },
  iconsmall2: {
    fontSize: 10,
    margin: 2,
    marginTop: 5, //15 center
    padding: 0,
  },
  iconsmall3: {
    fontSize: 8,
    //margin:2,
    //marginTop:5, //15 center
    //padding:0,
  },
  iconcheck: {
    fontSize: 8,
    //color:"green",
    maxWidth: "8em",
    maxHeight: "2em",
    minWidth: "8em",
    minHeight: "2em",
    backgroundColor: "green",
    color: "white",
    margin: 2,
    //marginTop:5, //15 center
    //padding:0,
  },
  iconanexos: {
    fontSize: 8,
    //color:"green",
    maxWidth: "12em",
    maxHeight: "2em",
    minWidth: "8em",
    minHeight: "2em",
    //backgroundColor:"green",
    //color:"white",
    margin: 2,
    //marginTop:5, //15 center
    //padding:0,
  },
  iconopen: {
    fontSize: 8,
    //color:"red",
    maxWidth: "8em",
    maxHeight: "2em",
    minWidth: "8em",
    minHeight: "2em",
    backgroundColor: "red",
    color: "white",
    margin: 2,
    //marginTop:5, //15 center
    //padding:0,
  },
  iconsave: {
    fontSize: 5,
    //color:"green",
    maxWidth: "8em",
    maxHeight: "2em",
    minWidth: "8em",
    minHeight: "2em",
    //backgroundColor:"green",
    //color:"white",
    margin: 2,
    //marginTop:5, //15 center
    //padding:0,
  },
  icondescartar: {
    fontSize: 5,
    //color:"green",
    maxWidth: "8em",
    maxHeight: "2em",
    minWidth: "8em",
    minHeight: "2em",
    //backgroundColor:"red",
    //color:"white",
    margin: 2,
    //marginTop:5, //15 center
    //padding:0,
  },
  colapse: {
    marginLeft: 20,
    color: "orange",
  },
  colapse1: {
    marginLeft: 20,
  },
  nombrecatalogo: {
    fontSize: 14,
    marginTop: 0,
    marginLeft: 10,
    textAlign: "left",
  },
  nombrearchivo: {
    marginTop: 6,
    fontSize: 10,
  },
  iconarchivo: {
    marginTop: 0,
    fontSize: 8,
    //color:"orange"
  },
  iconperson: {
    color: "black",
    marginTop: 0,
    fontSize: 10,
  },
  semaforopendiente: {
    color: "red",
    fontSize: "10",
    marginRight: 2,
  },
  semaforocompletado: {
    color: "green",
    fontSize: "10",
    marginRight: 2,
  },
  botondanger: {
    backgroundColor: "red",
    color: "white",
  },
  botongreen: {
    fontSize: "8",
    backgroundColor: "green",
    color: "white",
  },
  input: {
    display: "none",
  },
  botonupload: {
    marginLeft: 5,
  },
}));

const localization = {
  body: {
    emptyDataSourceMessage: "No hay datos por mostrar",
    addTooltip: "Añadir",
    deleteTooltip: "Eliminar",
    editTooltip: "Editar",
    filterRow: {
      filterTooltip: "Filtrar",
    },
  },
  header: {
    actions: "",
  },
  pagination: {
    firstAriaLabel: "Primera página",
    firstTooltip: "Primera página",
    labelDisplayedRows: "{from}-{to} de {count}",
    labelRowsPerPage: "Filas por página:",
    labelRowsSelect: "filas",
    lastAriaLabel: "Ultima página",
    lastTooltip: "Ultima página",
    nextAriaLabel: "Pagina siguiente",
    nextTooltip: "Pagina siguiente",
    previousAriaLabel: "Pagina anterior",
    previousTooltip: "Pagina anterior",
  },
  toolbar: {
    exportAriaLabel: "Exportar",
    exportTitle: "Exportar",
    exportCSVName: "Exportar a CVS",
    exportPDFName: "Exportar a PDF",
    searchPlaceholder: "Buscar",
    searchTooltip: "Buscar",
  },
};

export default function ExpedientesTable(props) {
  const classes = useStyles();
  const classes2 = useStylestable();
  const [modalVer, setModalVer] = React.useState(false);
  const [base64, setBase64] = React.useState(false);
  const [nombreArchivo, setNombreArchivo] = React.useState("");

  const [openMensaje, setOpenMensaje] = React.useState(false);
  const [mensaje, setMensaje] = React.useState(false);
  const [severity, setSeverity] = React.useState("info");
  const handleCloseMensaje = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMensaje(false);
  };

  function alerta(texto, severidad) {
    setOpenMensaje(true);
    setMensaje(texto);
    setSeverity(severidad);
  }



  let datostabla = [];
  for (var i = 0; i < props.expedientes.length; i++) {
    for (var j = 0; j < props.expedientes[i].documentos.length; j++) {

      datostabla = [...datostabla, {
        codigo_expediente: props.expedientes[i].custom_id,
        nombre_expediente: props.expedientes[i].name,
        fecha_expediente: props.expedientes[i].created_at,
        estatus_expediente: props.expedientes[i].status == 2 ? "CERRADO" : "ABIERTO",
        nombre_documento: props.expedientes[i].documentos[j].filename,
        id_documento: props.expedientes[i].documentos[j]._id,
        codigo_documento: props.expedientes[i].documentos[j].meta.custom_id,
        status_documento: props.expedientes[i].documentos[j].status.name,
        usuario_documento: props.expedientes[i].documentos[j].users.name,
      }]
    }
  }

  function handleBase64(id) {
    let token = sessionStorage.getItem("Token");
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/documentos/${id}/preview/download`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        //console.log("base64:",response.data)
        setBase64("data: application / pdf; base64, " + response.data);
        setModalVer(true);
      })
      .catch(function (error) {
        console.log(error);
        setBase64("");
      });
  }

  return (
    <React.Fragment>
      <Grid container style={{ marginTop: 10 }}>
        <div style={{ width: "100%" }}>
          <MaterialTable
            localization={localization}
            isLoading={props.isLoading}
            options={{
              exportButton: { csv: true, pdf: false },
              exportAllData: true,
              draggable: false,
              pageSize: 20, // make initial page size
              pageSizeOptions: [20, 50, 100, 500, 1000],
              actionsColumnIndex: -1,
              rowStyle: {
                fontSize: 10,
              }
            }}
            columns={[
              {
                title: "Expediente",
                field: "codigo_expediente",
              },
              {
                title: "Nombre",
                field: "nombre_expediente",
              },
              {
                title: "Fecha",
                field: "fecha_expediente",
                render: (row) => (
                  <span>
                    {row["fecha_expediente"]
                      ? moment(
                        row["fecha_expediente"]
                      ).format("DD/MM/YYYY")
                      : null}
                  </span>
                ),
              },
              {
                title: "Estatus",
                field: "estatus_expediente",
              },
              {
                title: "Documento",
                field: "nombre_documento",
              },
              {
                title: "Código",
                field: "codigo_documento",
              },
              /*
              {
                title: "Estatus",
                field: "status_documento",
              },
              */
              {
                title: "Usuario",
                field: "usuario_documento",
              },
            ]}
            data={datostabla}
            title={""}
            actions={[
              {
                icon: () => (
                  <VisibilityIcon
                    className={
                      classes2.tableActionButtonIcon +
                      " " +
                      classes2.edit
                    }
                  />
                ),
                tooltip: "Ver documento",
                onClick: (event, rowData) => {
                  console.log(rowData);
                  alerta(
                    "Abriendo documento, por favor espere unos segundos",
                    "info"
                  );
                  setNombreArchivo(rowData.nombre_documento)
                  handleBase64(rowData.id_documento);
                  //setExpediente(rowData);
                  //setModalExpedienteArchivos(true);
                },
              },
            ]}
          />
        </div>
      </Grid>
      {/*MENSAJE*/}
      <Snackbar
        open={openMensaje}
        autoHideDuration={3000}
        //style={{ height: "100%" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handleCloseMensaje}
      >
        <Alert onClose={handleCloseMensaje} severity={severity}>
          {mensaje}
        </Alert>
      </Snackbar>
      {/*FIN MENSAJE*/}

      {/*DIALOGO VER PDF*/}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={modalVer}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
        </DialogTitle>
        <DialogContent>
          {base64 ? <PDFView base64={base64} /> : "NO hay PDF"}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            //color="primary"
            onClick={() => {
              setModalVer(false);
            }}
            className={classes.botondanger}
          >
            Cerrar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              //setModalVer(false)
              //const linkSource = `data:application/pdf;base64,${pdf}`;
              const downloadLink = document.createElement("a");
              //const fileName = "vct_illustration.pdf";
              downloadLink.href = base64;
              //downloadLink.download = nombreArchivo + ".pdf";
              downloadLink.download = nombreArchivo;
              downloadLink.click();
            }}
          //className={classes.botondanger}
          >
            Descargar
          </Button>
        </DialogActions>
      </Dialog>
      {/* FIN VER PDF*/}


    </React.Fragment>
  )
}
