/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import logoCDO from "assets/img/LOGOCDO.png";
import Grid from "@material-ui/core/Grid";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              {/* <a
                href="https://certificaciondigital.online/"
                className={classes.block}
                target="_blank"
              >
                Blog
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="https://certificaciondigital.online/"
                className={classes.block}
                target="_blank"
              >
                Licenses
              </a> */}
            </ListItem>
          </List>
        </div>
        <div className={classes.right}>
          <Grid container justify="center" alignItems="center">
            <img src={logoCDO} alt="Con-Certeza" width="30" height="35" />
            Powered by{"  "}
            <a
              href="https://certificaciondigital.online/"
              className={aClasses}
              target="_blank"
            >
              &nbsp;Certificacion Digital Online
            </a>{" "}
          </Grid>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
