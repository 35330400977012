import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";

import Accessibility from "@material-ui/icons/Accessibility";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/CardNew";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIconNew.js";
import Grid from "@material-ui/core/Grid";
import Table from "./tablej.js";
import List from "./list";

import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={2}>
          <Card style={{ height: "130px" }}>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <DateRange
                  style={{ width: "21px", height: "21px" }}
                ></DateRange>
              </CardIcon>
              <p className={classes.cardCategory}>TOTAL</p>
              <br />
              <br />
              <h3 className={classes.cardTitle}>148</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Danger>
                  <Warning style={{ width: "21px", height: "21px" }} />
                </Danger>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Get more space
                </a>
              </div>
            </CardFooter>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Card style={{ height: "130px" }}>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store style={{ width: "21px", height: "21px" }} />
              </CardIcon>
              <p className={classes.cardCategory}>FIRMADOS</p>
              <br />
              <br />
              <h3 className={classes.cardTitle}>108</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Last 24 Hours
              </div>
            </CardFooter>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Card style={{ height: "130px" }}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Store style={{ width: "21px", height: "21px" }} />
              </CardIcon>
              <p className={classes.cardCategory}>EN ESPERA</p>
              <br />
              <br />
              <h3 className={classes.cardTitle}>75</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                Tracked
              </div>
            </CardFooter>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Card style={{ height: "130px" }}>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Accessibility style={{ width: "21px", height: "21px" }} />
              </CardIcon>
              <p className={classes.cardCategory}>RECHAZADOS</p>
              <br />
              <br />
              <h3 className={classes.cardTitle}>20</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Card style={{ height: "130px" }}>
            <CardHeader color="primary" stats icon>
              <CardIcon color="primary">
                <Accessibility style={{ width: "21px", height: "21px" }} />
              </CardIcon>
              <p className={classes.cardCategory}>REVISADOS</p>
              <br />
              <br />
              <h3 className={classes.cardTitle}>0</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Card style={{ height: "130px" }}>
            <CardHeader color="rose" stats icon>
              <CardIcon color="rose">
                <Accessibility style={{ width: "21px", height: "21px" }} />
              </CardIcon>
              <p className={classes.cardCategory}>REGISTRADOS</p>
              <br />
              <br />
              <h3 className={classes.cardTitle}>0</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={6} md={7}>
          <Table />
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <List />
        </Grid>
      </Grid>
    </div>
  );
}
