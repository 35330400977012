import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormData, { getHeaders } from "form-data";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Tooltip from "@material-ui/core/Tooltip";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import ErrorIcon from "@material-ui/icons/Error";
import moment from "moment";
import mensaje from "assets/img/mensaje.png";
import errordocumento from "./errordocumento.png";
import CargaDocumento from "./CargaDocumento";
import VistaPrevia from "./VistaPrevia";
import Firmantes from "./Firmantes";
import ButtonC from "components/CustomButtons/Button.js";
import Container from "@material-ui/core/Container";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TextField from "@material-ui/core/TextField";
import StepConnector from "@material-ui/core/StepConnector";
import clsx from "clsx";
import PropTypes from "prop-types";
import PublishIcon from "@material-ui/icons/Publish";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import Check from "@material-ui/icons/Check";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { dashboardRoutes } from "routes.js";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import bgImage from "assets/img/fondoblack.jpg";
import logo from "assets/img/ConCertezaGris.png";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";

import SnackbarMensaje from "@material-ui/core/Snackbar";
import Snackbar from "components/Snackbar/Snackbar.js";
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

//credenciales API
const username = "ce2af9cab947ed15288e058ecd5350f5";
const password = "9c9dd0db9b4a23eddf64b96e28b1214b";
const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");
//const PROXY_URL = 'https://sistemas.lemperroni.com/';
const PROXY_URL = "";
const colorinfo = "#00acc1";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      " linear-gradient(79deg, rgba(76,175,80,1) 0%, rgba(102,187,106,1) 63%, rgba(92,184,96,1) 100%)",
  },
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "30%",
      marginRight: "auto",
      marginTop: "5%",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  divacciones: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  input: {
    display: "none",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    float: "right",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  footer: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(1),
      padding: theme.spacing(3),
    },
  },
}));

const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Powered "}
      <Link color="inherit" href="https://certificaciondigital.online/">
        by CDO
      </Link>{" "}
    </Typography>
  );
}

function getSteps() {
  return ["Carga", "Vista previa", "Registra"];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return "Cargar el Documento";
    case 1:
      return "Vista Previa";
    case 2:
      return "Registro de Firmantes";
    default:
      return "Unknown stepIndex";
  }
}

export default function Flujo() {
  function handleGoBackHome() {
    console.log("backhome");
    let uri = localStorage.getItem("uri");
    history.push(`/${uri}/dashboard`);
  }
  const history = useHistory();

  const onChange = async (event) => {
    var file = document.querySelector('input[type="file"]').files[0];
    console.log("file", file);
    setArchivo(file);

    const base64 = await convertBase64(file);
    setPDF(base64);
    registra(file);
    handleNext();
  };

  // alert functions
  const [openGuardado, setOpenGuardado] = React.useState(false);
  const handleCloseGuardado = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenGuardado(false);
  };

  const [openSnack, setOpenSnack] = React.useState(false);
  const [messageError, setMessageError] = React.useState("");
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const openAlert = (error) => {
    setOpenSnack(true);
    setMessageError(error);
  };

  // styles
  const classes = useStyles();
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  //layout
  const mainPanel = React.createRef();
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  //layout

  const [pdf, setPDF] = React.useState("");
  const [archivo, setArchivo] = React.useState("");
  const [enviar, setEnviar] = React.useState(true);
  const [mensajeFirma, setMensajeFirma] = React.useState(false);
  const [mensajeError, setMensajeError] = React.useState(false);
  //contrato CDO
  const [contrato, setContrato] = React.useState("");
  //contrato Con Certeza
  const [contratoCC, setContratoCC] = React.useState("");
  const [user, setUser] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  //metadatos
  const [descripcion, setDescripcion] = React.useState("");
  const [inicio, setInicio] = React.useState("");
  const [termino, setTermino] = React.useState("");
  const [alerta, setAlerta] = React.useState("");
  const [openAlerta, setOpenAlerta] = React.useState("");
  const [botones, setBotones] = React.useState(false);
  function getUser() {
    let tokenCC = sessionStorage.getItem("Token");
    //console.log(tokenCC);

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/auth/user`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };

    return new Promise(function (resolve, reject) {
      axios(config).then(
        (response) => {
          var result = response.data;
          console.log("dataUSER:", response.data);
          setUser(response.data);
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });

    /*
  axios(config)
    .then(function (response) {
      console.log("dataUSER:",response.data);
      setUser(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
    */
  }

  const showNotificacion = (message) => {
    setOpenAlerta(true);
    setMessageError(message);
    setTimeout(function () {
      setOpenAlerta(false);
    }, 6000);
  };

  React.useEffect(() => {
    getUser();
  }, []);

  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
      1: <PublishIcon />,
      2: <PictureAsPdfIcon />,
      3: <GroupAddIcon />,
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {completed ? <Check /> : icons[String(props.icon)]}
      </div>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  //steps
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    //BORRA EL DOCUMENTO EN CDO
    console.log("contrato CDO", contrato);
    if (contrato) {
      const URL =
        "https://api.certificaciondigital.online/api/contrato/" +
        contrato.id +
        "/delete";
      var config = {
        method: "get",
        url: PROXY_URL + URL,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Basic " + token,
        },
      };
      axios(config)
        .then(function (response) {
          console.log("ContratoCDO borrado:", response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    //BORRA EL DOCUMENTO EN CON CERTEZA
    if (contratoCC) {
      let tokenCC = sessionStorage.getItem("Token");

      var config = {
        method: "delete",
        url: `${process.env.REACT_APP_URL}api/documentos/${contratoCC[1]._id}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + tokenCC,
        },
      };

      axios(config)
        .then(function (response) {
          console.log("ContratoCC borrado:", response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    setActiveStep(0);
    setPDF("");
    setContrato("");
  };

  function mofificarStatusCC(idContratoCC, newStatus) {
    //let tokenCC = sessionStorage.getItem("Token");
    let tokenCC = `${process.env.REACT_APP_TOKEN}`;

    var config = {
      method: "PUT",
      url: `${process.env.REACT_APP_URL}api/documentos/${idContratoCC}?documento_status_id=${newStatus}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("ContratoCC Actualizado:", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleFirmar = () => {
    //agregar notificador usuario en sesión

    //mandar a firma
    const URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      contrato.id +
      "/enable";
    var config = {
      method: "get",
      url: PROXY_URL + URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Proceso iniciado CDO:", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    mofificarStatusCC(contratoCC[1]._id, 2);

    //set estados iniciales
    setMensajeFirma(true);
    setActiveStep(0);
    setPDF("");
    setContrato("");
  };

  //API CREAR CONTRATO CDO
  async function makeGetRequestContrato(archivo) {
    var userid = "";
    if (user) {
      userid = user.user.id;
    } else {
      let data = await getUser();
      userid = data.user.id;
    }

    const URL =
      "https://api.certificaciondigital.online/api/contrato/registrar";

    const data = new FormData();
    //data.append('contrato', fs.createReadStream('/path/to/file'));
    data.append("contrato", archivo);
    data.append("nombre", archivo.name);
    data.append("descripcion", archivo.name);
    data.append("catalogo", "concerteza\\" + userid);
    data.append("internaluid", userid);
    data.append("tipo_documento", "6b3650a1-9006-4d8c-95a4-dcf19a03a134");
    data.append("fecha_documento", moment(new Date()).format("DD/MM/YYYY"));
    console.log("DATAbodyfirmante", data);

    var urlDocumento = PROXY_URL + URL;
    var config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    return new Promise(function (resolve, reject) {
      axios.post(urlDocumento, data, config, {}).then(
        (response) => {
          var result = response.data;
          console.log("Processing Request");
          resolve(result);
        },
        (error) => {
          reject(error);
          //setMensajeError(true);
          handleReset();
          openAlert(
            "Imposible cargar el documento debido a que ha sido firmado con anterioridad"
          );
        }
      );
    });
  }

  //API CREAR CONTRATO CON-CERTEZA
  function makeGetRequestContratoCC(contratocdo, archivo) {
    const URL = `${process.env.REACT_APP_URL}api/documentos`;

    let tokenCC = sessionStorage.getItem("Token");

    const data = new FormData();
    data.append("filename", archivo);
    //data.append("descripcion", archivo.name);
    data.append("documento_types_id", "3");
    data.append("cdo_contratos_id", contratocdo);
    //console.log("DATAbodyfirmante", data);

    if (descripcion !== "") {
      data.append("description", descripcion);
    }

    if (inicio !== "") {
      data.append("meta[vigencia_start]", inicio + "00:00:00");
    }
    if (termino !== "") {
      data.append("meta[vigencia_end]", termino + "23:59:59");
    }

    var urlDocumento = URL;
    var config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };
    return new Promise(function (resolve, reject) {
      axios.post(urlDocumento, data, config, {}).then(
        (response) => {
          var result = response.data;
          console.log("Processing Request");
          resolve(result);
        },
        (error) => {
          reject(error);
          //setMensajeError(true);
          //openAlert(textoerror.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''));
          let textoerror = JSON.stringify(error.response.data.errors);
          if (textoerror) {
            openAlert(textoerror.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""));
          }
        }
      );
    });
  }

  async function registra(archivo) {
    var result = await makeGetRequestContrato(archivo);
    console.log(result);
    setContrato(result);
    console.log("Contrato OK");
    var resultCC = await makeGetRequestContratoCC(result.id, archivo);
    console.log("RESULTCC:", resultCC);
    //mensaje guardado
    setOpenGuardado(true);
    setContratoCC(resultCC);
    setBotones(true);
    //handleNext();
  }

  const handleRegistra = () => {
    //registra();
    handleNext();
  };

  //fin steps

  return (
    <React.Fragment>
      <div>
        <Sidebar
          routes={dashboardRoutes}
          logo={logo}
          image={image}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
        />
      </div>

      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={dashboardRoutes}
          handleDrawerToggle={handleDrawerToggle}
        />

        <main
          style={{
            width: "55%",
            marginLeft: "30%",
            marginRight: "auto",
            marginTop: "5%",
          }}
        >
          <Paper className={classes.paper}>
            <Typography component="h2" variant="h5" align="center">
              FIRMA ELECTRÓNICA AVANZADA
              {/*
              <Tooltip title="Consultar Procesos" placement="top-end">
                <IconButton aria-label="Consultar" href="/fiel/consultas">
                  <SearchIcon style={{ fontSize: 12 }} />
                  </IconButton>
              </Tooltip>
              */}
            </Typography>

            <Stepper
              activeStep={activeStep}
              className={classes.stepper}
              connector={<ColorlibConnector />}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>

            <div className={classes.divacciones}>
              {activeStep === steps.length - 1 ? (
                <React.Fragment>
                  <Grid container>
                    <Grid item xs={6} sm={12}>
                      <Firmantes
                        setEnviar={setEnviar}
                        contrato={contrato}
                        contratoCC={contratoCC}
                      />
                    </Grid>
                    <Grid item xs={6} sm={12}>
                      <ButtonC
                        variant="contained"
                        color="danger"
                        size="md"
                        type="button"
                        onClick={handleReset}
                        className={classes.button}
                      >
                        Eliminar
                      </ButtonC>
                      <ButtonC
                        variant="contained"
                        color="danger"
                        size="md"
                        type="button"
                        className={classes.button}
                        style={{ backgroundColor: colorinfo, color: "white" }}
                        onClick={() => {
                          handleGoBackHome();
                        }}
                      >
                        Guardar en registrados
                      </ButtonC>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={handleFirmar}
                        disabled={enviar}
                        style={{ float: "right" }}
                      >
                        <Typography variant="overline">
                          Enviar a firmar
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </React.Fragment>
              ) : (
                <div>
                  {activeStep === 0 ? (
                    <React.Fragment>
                      <Container maxWidth="xs">
                        <Box width={1} bgcolor="grey.300" p={1} my={0.5}>
                          <Paper elevation={3} style={{ padding: 20 }}>
                            <Grid container>
                              <Grid item xs={6} sm={12}>
                                <p align="center">
                                  <b>Información general del documento</b>
                                </p>
                                <hr />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                Descripción:
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextareaAutosize
                                  aria-label="minimum height"
                                  maxlength="360"
                                  rowsMin={2}
                                  placeholder=""
                                  FormHelperTextProps={{
                                    className: classes.helperText,
                                  }}
                                  helperText={`${descripcion.length}/128`}
                                  //style={{ width: "100%" }}
                                  onChange={(e) => {
                                    console.log("descripcion", e.target.value); // Works
                                    setDescripcion(e.target.value);
                                  }}
                                />
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                Inicio vigencia:
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  id="dateDesde"
                                  type="date"
                                  onChange={(e) => {
                                    console.log("inicio", e.target.value); // Works
                                    setInicio(e.target.value);
                                    if (e.target.value > termino) {
                                      setAlerta(
                                        "La fecha de término debe ser superior a la fecha de inicio"
                                      );
                                    } else {
                                      setAlerta("");
                                    }

                                    var date = new Date(e.target.value);
                                    var f = new Date();

                                    var hoy = new Date("Y-m-d");
                                    if (date.getFullYear() > 2300) {
                                      showNotificacion(
                                        "La fecha de inicio es inválida"
                                      );
                                    }
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                Término vigencia:
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  id="dateDesde"
                                  type="date"
                                  onChange={(e) => {
                                    console.log("termino", e.target.value); // Works
                                    setTermino(e.target.value);
                                    if (e.target.value < inicio) {
                                      //alert("La fecha de término debe ser superior a la fecha de inicio")
                                      setAlerta(
                                        "La fecha de término debe ser superior a la fecha de inicio"
                                      );
                                    } else {
                                      setAlerta("");
                                    }
                                    var date = new Date(e.target.value);
                                    if (date.getFullYear() > 2300) {
                                      showNotificacion(
                                        "La fecha de término es inválida"
                                      );
                                    }
                                  }}
                                  //value={desde}
                                  //className={classes.textField}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </Grid>
                              {alerta ? (
                                <Grid item xs={12} sm={12}>
                                  <font size="2" color="red">
                                    <b>{alerta}</b>
                                  </font>
                                </Grid>
                              ) : null}
                              <Grid item xs={6} sm={12}>
                                <font size="1" color="darkblue">
                                  <b>Estos datos son opcionales</b>
                                </font>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Box>
                      </Container>

                      <Container>
                        <Grid container>
                          <Grid item xs={12} sm={12}>
                            <input
                              accept="application/pdf"
                              className={classes.input}
                              id="contained-button-file"
                              multiple
                              type="file"
                              name="PDF"
                              onChange={onChange}
                            />
                            <br />
                            <label htmlFor="contained-button-file">
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                style={{ marginLeft: "20%" }}
                              >
                                <Typography
                                  variant="h6"
                                  style={{ textTransform: "none" }}
                                >
                                  CARGA DOCUMENTO PDF
                                </Typography>
                              </Button>
                            </label>
                          </Grid>
                        </Grid>
                      </Container>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <VistaPrevia handleNext={handleNext} pdf={pdf} />
                      <div>
                        {botones ? (
                          <React.Fragment>
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.buttons}
                              onClick={handleRegistra}
                            >
                              <Typography variant="overline">
                                Registrar firmantes
                              </Typography>
                            </Button>
                            <Button
                              variant="contained"
                              color="info"
                              className={classes.buttons}
                              style={{
                                backgroundColor: colorinfo,
                                color: "white",
                              }}
                              onClick={() => {
                                handleGoBackHome();
                              }}
                            >
                              <Typography variant="overline">
                                Guardar en registrados
                              </Typography>
                            </Button>
                          </React.Fragment>
                        ) : (
                          <CircularProgress style={{ float: "right" }} />
                        )}

                        {/*
                        <Button
                          className={classes.buttons}
                          onClick={handleReset}
                        >
                          <Typography
                            variant="button"
                            style={{ textTransform: "none", color: "red" }}
                          >
                            Cancelar
                          </Typography>
                        </Button>
                        */}
                        <ButtonC
                          color="danger"
                          size="md"
                          type="button"
                          onClick={handleReset}
                        //className={classes.button}
                        >
                          Eliminar
                        </ButtonC>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              )}
            </div>
          </Paper>
          {/*<Copyright />*/}
        </main>

        <div className={classes.footer}>{/* <Footer /> */}</div>
      </div>
      {/*CUADRO DE DIÁLOGO PARA INDICAR INICIO DE PROCESO DE FIRMA*/}
      <Dialog
        open={mensajeFirma}
        transition={Transition}
        keepMounted
        fullScreen={false}
        onClose={() => setMensajeFirma(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        ></DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <div>
            <Typography
              variant="h6"
              gutterBottom
              align="center"
              style={{
                textTransform: "none",
              }}
            >
              Creación de proceso de firma exitoso, se notificará a los involucrados.
            </Typography>

            <div align="center">
              <img src={mensaje} alt="logo" width="260" height="220" />
            </div>
          </div>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button onClick={() => setMensajeFirma(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      {/*CUADRO DE DIÁLOGO PARA INDICAR ERROR*/}
      <Dialog
        open={mensajeError}
        transition={Transition}
        keepMounted
        fullScreen={false}
        onClose={() => setMensajeError(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        ></DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <div>
            <Typography variant="h5" gutterBottom>
              No es posible crear el proceso de firma
            </Typography>
            <Typography variant="subtitle1" style={{ textTransform: "none" }}>
              El documento ya está registrado o posee una firma previa
            </Typography>
            <div align="center">
              <img src={errordocumento} alt="logo" width="260" height="220" />
            </div>
          </div>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button onClick={() => setMensajeError(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      {/*MENSAJE ERROR*/}
      <div>
        <Snackbar
          place="tc"
          color="danger"
          icon={ErrorIcon}
          message={messageError}
          open={openSnack}
          closeNotification={handleCloseSnack}
          close
        />
        {/*
        <Snackbar
          open={openSnack}
          autoHideDuration={9000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={handleCloseSnack}
        >
          <Alert onClose={handleCloseSnack} severity="error">
            {messageError}
          </Alert>
        </Snackbar>
        */}
      </div>
      {/*MENSAJE ERROR*/}
      <Snackbar
        place="tc"
        color="danger"
        icon={ErrorIcon}
        message={messageError}
        open={openAlerta}
        closeNotification={() => setOpenAlerta(false)}
        close
      />
      <SnackbarMensaje
        open={openGuardado}
        autoHideDuration={4000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handleCloseGuardado}
      >
        <Alert onClose={handleCloseGuardado} severity="info">
          Documento Guardado en Registrados
        </Alert>
      </SnackbarMensaje>
    </React.Fragment>
  );
}
