import React from "react";
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';
import PersonIcon from '@material-ui/icons/Person';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import FolderIcon from '@material-ui/icons/Folder';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import GroupIcon from '@material-ui/icons/Group';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({

  paper: {
    width: "80%",
    margin: 2,
    textAlign: "center",
    margin: "auto"
  },
  paper1: {
    width: "80%",
    margin: 2,
    textAlign: "center"
  },
  paper2: {
    width: "80%",
    margin: 1,
    //textAlign: "center"
  },
  paper3: {
    width: "80%",
    margin: 0,
    textAlign: "right",
    backgroundColor: "white"
  },
  formControl: {
    margin: theme.spacing(1),
    //minWidth: 120,
    width: "80%"
  },
  botondanger: {
    backgroundColor: "red",
    color: "white"
  },
  botongreen: {
    backgroundColor: "green",
    color: "white",
    margin: 10
  },
  gridsmall: {
    margin: 10,
    backgroundColor: "white",
    border: "1px solid #777",
  },
  iconsmall: {
    fontSize: 10,
    margin: 2,
    marginTop: 11, //15 center
    padding: 0,
  },
  iconsmall2: {
    fontSize: 10,
    margin: 2,
    marginTop: 5, //15 center
    padding: 0,
  },
  iconsmall3: {
    fontSize: 8,
    //margin:2,
    //marginTop:5, //15 center
    //padding:0,
  },
  colapse: {
    marginLeft: 20,
    color: "orange"
  },
  colapse1: {
    marginLeft: 20,
  },
  nombrecatalogo: {
    fontSize: 13,
    marginTop: 0,
    marginLeft: 10,
    textAlign: "left"
  },
  nombrearchivo: {
    marginTop: 6,
    fontSize: 10,
  },
  iconarchivo: {
    marginTop: 0,
    fontSize: 10,
  },
  p: {
    width: "90%",
    margin: "0",
    lineHeight: "0.7"
  },
  divtittle: {
    backgroundColor: "#3f51b5",
    color: "white"
  },
  appbar: {
    backgroundColor: "#3f51b5",
    color: "white",
    margin: 0,
    //    borderStyle: "groove",
    //    borderColor: "#3f51b5",
  },
  semaforopendiente: {
    color: "red",
    fontSize: "10",
    marginRight: 2
  },
  semaforocompletado: {
    color: "green",
    fontSize: "10",
    marginRight: 2
  }
}));

export default function ExpedienteNodos(props) {
  const classes = useStyles();
  const [grupos, setGrupos] = React.useState("");
  const [catalogos, setCatalogos] = React.useState("");

  async function getGrupos() {
    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_URL}api/workgroups`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    };
    axios(config)
      .then(function (response) {
        setGrupos(response.data);
      })
      .catch(function (error) {
        console.log(error);
        setGrupos("");
      });
  }



  async function getCatalogos() {
    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_URL}api/catalogos`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    };
    axios(config)
      .then(function (response) {
        setCatalogos(response.data);
      })
      .catch(function (error) {
        console.log(error);
        setCatalogos("");
      });
  }

  function DisplayCatalogo(props) {
    if (catalogos) {
      const catalogo = catalogos.find(item => item._id == props.idcatalogo);
      if (catalogo)
        return (
          <React.Fragment key={catalogo._id}>
            <TreeView
              className={classes.root}
              defaultCollapseIcon={<FolderOpenIcon color="primary" className={classes.colapse} />}
              defaultExpandIcon={<FolderIcon color="primary" className={classes.colapse} />}
            >
              <TreeItem nodeId="0"
                label={
                  <Grid container>
                    <Grid item xs={10}>
                      <Typography className={classes.nombrecatalogo}>
                        <font size="2">{catalogo.name}</font>
                      </Typography>
                    </Grid>
                  </Grid>
                }
                className={classes.root}
              >
                {catalogo.documentos.map((item, key) => (
                  <TreeItem nodeId={item._id} key={key} label={
                    <Grid container>
                      <Grid item>
                        <DescriptionIcon
                          className={classes.iconarchivo}
                        />
                      </Grid>
                      <Grid item>
                        <Typography className={classes.nombrearchivo}>
                          <font size="2">{item.name}</font>
                        </Typography>
                      </Grid>
                    </Grid>
                  } />
                ))}
              </TreeItem>
            </TreeView>
          </React.Fragment>
        );
      return (null)
    }
    return (null);
  }
  function DisplayGrupo(props) {
    const grupo = grupos.find(item => item._id == props.idgrupo);
    if (grupo) {
      return (
        <React.Fragment key={grupo._id}>
          <TreeView
            className={classes.root}
            defaultCollapseIcon={<PeopleOutlineIcon color="primary" className={classes.colapse1} />}
            defaultExpandIcon={<GroupIcon color="primary" className={classes.colapse1} />}
          >
            <TreeItem nodeId="0"
              label={
                <Grid container>
                  <Grid item xs={11}>
                    <Typography className={classes.nombrecatalogo}>
                      <font size="2">{grupo.name}</font>
                    </Typography>
                  </Grid>
                </Grid>
              }
              className={classes.root}
            >
              {grupo.members.map((item, key) => (
                <TreeItem nodeId={item._id} key={key} label={
                  <Grid container>
                    <Grid item>
                      <PersonIcon
                        className={classes.iconarchivo}
                      />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.nombrearchivo}>
                        <font size="2">{item.user.name}</font>
                      </Typography>
                    </Grid>
                  </Grid>
                } />
              ))}
            </TreeItem>
          </TreeView>
        </React.Fragment>
      );
    }
    return (null)
  }

  function DisplayFlujo() {
    return (
      <React.Fragment>
        {props.expediente.nodes && props.expediente.nodes.map((item, key) => (
          <React.Fragment key={key} >
            <Grid item xs={4} className={classes.gridsmall}>
              <div className={classes.appbar}>
                <Grid container>
                  <Grid item xs={11} style={{ textAlign: "left" }}>
                    <font size="1" style={{ textAlign: "left", marginLeft: 10 }}><b>{key + 1 + "- " + item.name}</b></font>
                  </Grid>

                  <Grid item xs={1} style={{ textAlign: "right" }}>
                    {item.status == 0 ?
                      (
                        <Tooltip title="Pendiente" placement="top">
                          <FiberManualRecordIcon className={classes.semaforopendiente} style={{ fontSize: 10 }} />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Completado" placement="top">
                          <CheckCircleIcon className={classes.semaforocompletado} style={{ fontSize: 10 }} />
                        </Tooltip>
                      )}
                  </Grid>

                </Grid>
              </div>
              <div style={{ margin: 0 }}>
              </div>
              {grupos ?
                <DisplayGrupo idgrupo={item.workgroup_id} />
                : null}
              {item.catalogos && item.catalogos.map((item, key) => (
                <DisplayCatalogo key={key} idcatalogo={item} />
              ))}
            </Grid>

          </React.Fragment>
        ))}

      </React.Fragment>
    )
  }

  React.useEffect(() => {
    getGrupos();
    getCatalogos();
  }, []);

  return (
    <DisplayFlujo />
  )
}
