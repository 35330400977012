import React, { useState, useEffect } from "react";
import { useParams, withRouter } from "react-router-dom";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import firmado from "assets/img/bandeja.png";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import logo from "assets/img/ConCertezaAzul.png";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const useStyles = makeStyles((theme) => ({
    appBar: {
      position: "relative",
      padding: theme.spacing(1),
    },
    layout: {
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 680,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    stepper: {
      padding: theme.spacing(3, 0, 5),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
      textAlign: "center",
    },

  }));

  function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {"Powered"}
        <Link color="inherit" href="https://certificaciondigital.online/">
          {" "}
          by CDO
        </Link>{" "}
      </Typography>
    );
  }

export default function DescargarCompartido(){
    const classes = useStyles();
    let { idDoc } = useParams();
    const [openZip, setOpenZip] = React.useState(false);
    
    const getZipBundle = (id) => {
        setOpenZip(true);
        let token = sessionStorage.getItem("Token");
        //let token = `${process.env.REACT_APP_TOKEN}`;
        axios({
          url: `${process.env.REACT_APP_URL}api/expedientes/sharebundle/${id}/bundle`,
          method: "GET",
          responseType: "blob", // important
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${id}-CERTIFICADO.zip`);
          document.body.appendChild(link);
          link.click();
          setOpenZip(false);
        });
      };

    useEffect(() => {
        getZipBundle(idDoc)
    }, []);

    return(
        <>
        <AppBar position="absolute" color="default" className={classes.appBar}>
            <Toolbar>
                <a href="https://certificaciondigital.online/" target="_blank">
                    <img src={logo} alt="logo" width="200" height="50" />
                </a>  
            </Toolbar>
        </AppBar>
        <Paper className={classes.paper}>
            <React.Fragment>
                <Typography variant="h5" gutterBottom align={"center"}>
                  Expediente descargado
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{ textTransform: "none" }}
                  align={"center"}
                >
                  <React.Fragment>
                    <br />
                    El archivo con los documentos del expediente esta disponible en el lugar
                    habitual de Descargas
                    <br />
                  </React.Fragment>
                  Con-Certeza agradece su participación.
                  <br />
                  <a
                    href="https://certificaciondigital.online/"
                    target="_blank"
                  >
                    https://certificaciondigital.online/
                  </a>{" "}
                </Typography>
                <div align="center">
                  <img src={firmado} alt="logo" width="350" height="240" />
                </div>
              </React.Fragment>
              <Copyright />
        </Paper>
        
           {/*DIALOGO DE ESPERA PARA DESCARGA*/}
            <Dialog
                open={openZip}
                TransitionComponent={Transition}
                keepMounted
            >
                <DialogTitle id="alert-dialog-slide-title">
                {"Descarga de expediente"}
                </DialogTitle>
                <DialogContent>
                <>
                    <Typography>
                    El archivo con los documentos del expediente se descargará al lugar
                    habitual de Descargas: Esto puede tardar unos segundos...
                    </Typography>
                    <br />
                    <CircularProgress />
                </>
                </DialogContent>
            </Dialog>
            {/*FIN DIALOGO DE ESPERA PARA DESCARGA*/}
        </>
    )
}