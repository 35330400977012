import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import ControlPanel from "components/controlPanel/ControlPanel";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "components/CustomButtons/Button.js";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import axios from "axios";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import { isMobile } from "react-device-detect";
import { BrowserRouter as Router, useParams } from "react-router-dom";
import { saveLog, saveLogCapture } from "containers/Logs/funcionesLog.js";
import logo from "assets/img/ConCertezaAzul.png";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function Notificacion(props) {


  //pdf
  const [base64, setbase64] = React.useState("");
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pdfHeigth, setpdfHeigth] = React.useState(475);
  const [pdfWidth, setpdfWidth] = React.useState(550);
  const [marginTop, setMarginTop] = React.useState(700);
  const [scale, setScale] = React.useState(0.7);

  const [openPrivacidad, setopenPrivacidad] = React.useState(true);

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }


  //pdf
  let { idfirmante, idDoc } = useParams();
  const token = sessionStorage.getItem("Token");
  axios
    .get(`${process.env.REACT_APP_URL}api/documentos/${idDoc}/signed/download`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      setbase64(res.data);
    })
    .catch((error) => {
      console.error(error);
    });

  const clickDownload = () => {
    console.log("download");
    const linkSource = `data:application/pdf;base64,${base64}`;
    const downloadLink = document.createElement("a");
    const fileName = "NOTIFICACION-CERTIFICADA.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const handleClose = () => {
    let tokenCC = sessionStorage.getItem("Token");
    let newDate = new Date();
    let date_accepted = moment(newDate).format(
      "YYYYMMDD HH:mm:ss"
    );
    var config = {
      method: "PUT",
      url: `${process.env.REACT_APP_URL}api/firmantes/${idfirmante}?date_accepted=${date_accepted}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("Firmante Actualizado:", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    setopenPrivacidad(false);
  };


  React.useEffect(() => {

    if (isMobile) {
      setpdfHeigth(500);
      setpdfWidth(290);
    }

    //Guardar log visualizó
    //buscar datos firmante
    axios
      .get(`${process.env.REACT_APP_URL}api/firmantes/${idfirmante}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        saveLog(
          token,
          res.data.name + " " + res.data.last_name + " Visualizó notificación " + idDoc
        );
        saveLogCapture(
          token,
          res.data.name + " " + res.data.last_name + " Visualizó notificación " + idDoc
        );
      })
      .catch((error) => {
        console.error(error);
      });
    //fin log

  }, []);

  return (
    <React.Fragment>
      <AppBar position="absolute" color="default">
        <Toolbar>
          <img src={logo} alt="logo" width="100" height="auto" />
        </Toolbar>
      </AppBar>
      {base64.length === 0 ? (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <CircularProgress />
        </div>
      ) : (
        <div align="center" style={{ marginTop: "8%" }}>
          <div className="text-center">

            <p>
              Página {pageNumber || (numPages ? 1 : "--")} de{" "}
              {numPages || "--"}
            </p>

            <button
              className="btn btn-dark"
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
            >
              Atrás
            </button>{" "}
            <button
              className="btn btn-dark"
              type="button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
            >
              Siguiente
            </button>
          </div>
          <ControlPanel
            scale={scale}
            setScale={setScale}
            numPages={numPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            file={`data: application / pdf; base64, ${base64} `}
          />
          <Document
            error="Error al intentar cargar el PDF"
            loading="Cargando..."
            file={`data: application / pdf; base64, ${base64} `}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              scale={scale}
              height={pdfHeigth}
              width={pdfWidth}
              renderTextLayer={false}
              pageNumber={pageNumber}
            />
          </Document>

          <Button
            size="sm"
            color="info"
            type="button"
            onClick={clickDownload}>
            <CloudDownloadIcon />
          </Button>
        </div>
      )}

      {/*AVISO DE PRIVACIDAD*/}
      <Dialog
        open={openPrivacidad}
        TransitionComponent={Transition}
        keepMounted
        //onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Aviso de privacidad"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography>
              <div>
                <div>
                  Al ingresar al servicio de firma electrónica se entenderá
                  que estás de acuerdo con el Aviso de Privacidad de
                  Certificación Digital Online. Si deseas conocer el Aviso de
                  Privacidad, ingresa a: {""}
                  <a
                    href="https://certificaciondigital.online/contenido/terminos"
                    target="_blank"
                  >
                    Aviso de privacidad
                  </a>{" "}
                </div>
                <Grid>
                  <Button
                    size="large"
                    variant="contained"
                    onClick={() => {
                      //setopenPrivacidad(false);
                      handleClose();
                    }}
                  >
                    Aceptar
                  </Button>
                </Grid>
              </div>
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {/*FIN AVISO */}

    </React.Fragment>
  )

}
