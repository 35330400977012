import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { isMobile } from 'react-device-detect';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import MaterialTable from "material-table";
import InfoIcon from "@material-ui/icons/Info";
import Close from "@material-ui/icons/Close";
//modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
//icons
import PersonPinIcon from "@material-ui/icons/PersonPin";
import DescriptionIcon from "@material-ui/icons/Description";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import GestureIcon from "@material-ui/icons/Gesture";
import PollIcon from "@material-ui/icons/Poll";
import CircularProgress from '@material-ui/core/CircularProgress';
import BarChartIcon from '@material-ui/icons/BarChart';
//components
import GraficaDetalle from "./GraficaDetalle";
import GraficaPie from "./GraficaPie";
import GraficaClientes from "./Grafica";
import GraficaPrincipal from "./GraficaPrincipal";
import GraficaPromedioFirma from "./GraficaPromedioFirma";

import moment from "moment";
import axios from "axios";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
const useStyles = makeStyles(styles);
const useStylestable = makeStyles(styles2);

export default function Documentos() {
  const colorStatistics = "#024ebc";
  const classes = useStyles();
  const classes2 = useStylestable();
  const [modalDocumentos, setModalDocumentos] = React.useState(false);
  const [modalFirmas, setModalFirmas] = React.useState(false);
  const [modalGrafica, setModalGrafica] = React.useState(false);
  const [dataModalGrafica, setDataModalGrafica] = React.useState("");
  const [dataModalGraficaDias, setDataModalGraficaDias] = React.useState("");
  const [dataModalGraficaMeses, setDataModalGraficaMeses] = React.useState("");

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  const [agrupado, setAgrupado] = React.useState([{}]);
  const [clientes, setClientes] = React.useState([{}]);
  const [datos, setDatos] = React.useState(null);
  const [tablaDocumentos, setTablaDocumentos] = React.useState([{}]);
  const [tablaFirmas, setTablaFirmas] = React.useState([{}]);
  const [loading, setLoading] = React.useState(true);
  const [dataPie, setDataPie] = React.useState("");

  function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }


  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  React.useEffect(() => {
    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    //DOCUMENTOS
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/documentos`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then(function (response) {
        let arr = response.data;
        console.log("DOCUMENTOS", arr);
        //llenar datos para gráfico pie

        var s1 = 0;
        var s2 = 0;
        var s3 = 0;
        var s4 = 0;
        var s5 = 0;
        for (var i = 0; i < arr.length; i++) {
          switch (arr[i].status.id) {
            case 1:
              s1++;
              break;
            case 2:
              s2++;
              break;
            case 3:
              s3++;
              break;
            case 4:
              s4++;
              break;
            case 5:
              s5++;
              break;
          }
        }
        setDataPie([
          { angle: s1, color: "#808080", name: "Registrados", label: s1 },
          { angle: s2, color: "#008080", name: "Proceso", label: s2 },
          { angle: s3, color: "#008000", name: "Firmados", label: s3 },
          { angle: s4, color: "#FF0000", name: "Rechazados", label: s4 },
          { angle: s5, color: "#800000", name: "Detenidos", label: s5 }
        ])

        const agrupado = groupBy(arr, (p) => p.users.company);
        console.log("AGRUPADO", agrupado);
        setAgrupado(agrupado);

        let cli = Array.from(agrupado.keys());
        console.log("CLIENTES", cli);
        setClientes(Array.from(agrupado.keys()));

        let table = "<hr />";
        var data = [];
        for (var i = 0; i < cli.length; i++) {
          let numDocs = agrupado.get(cli[i]);
          let cont = 0;
          let asistidos = 0;
          for (var j = 0; j < numDocs.length; j++) {
            cont = cont + numDocs[j].firmantes.length;
            if (numDocs[j].asistido) {
              asistidos++;
            }

          }

          data = [
            ...data,
            {
              cliente: cli[i],
              detalle: numDocs,
              documentos: numDocs.length,
              asistidos: asistidos,
              firmas: cont,
            },
          ];
        }
        setDatos(data);
        setLoading(false);
        console.log("data", data);
      })
      .catch(function (error) {
        console.log(error);
      });

    //FIN DOCUMENTOS
  }, []);


  function TablaClientes() {
    if (datos) {
      return (
        <React.Fragment>
          <MaterialTable
            localization={localization}
            options={{
              exportButton: true,
              rowStyle: { fontFamily: "Roboto" },
              draggable: false,
              headerStyle: {
                backgroundColor: colorStatistics,
                color: "#FFF",
              },

              pageSize: 10, // make initial page size
              pageSizeOptions: [10, 20, 50, 100, 500],
              actionsColumnIndex: -1,
              exportButton: true,
              //actionsColumnIndex: 0,
            }}
            columns={[
              { title: "Compañía", field: "cliente" },
              { title: "Documentos", field: "documentos" },
              { title: "Asistidos", field: "asistidos" },
              { title: "Firmas", field: "firmas" },
            ]}
            data={datos}
            title={""}
            actions={[
              {
                icon: () =>
                  <DescriptionIcon
                    className={
                      classes2.tableActionButtonIcon +
                      " " +
                      classes2.edit
                    }
                  />,
                tooltip: "Ver Documentos",
                onClick: (event, rowData) => {
                  setTablaDocumentos(rowData.detalle);
                  setModalDocumentos(true);
                },
              },
              {
                icon: () => <GestureIcon
                  className={
                    classes2.tableActionButtonIcon +
                    " " +
                    classes2.edit
                  }
                />,
                tooltip: "Ver Firmas",
                onClick: (event, rowData) => {
                  let data = [];
                  for (var i = 0; i < rowData.detalle.length; i++) {
                    //var fila= person.detalle[i].firmantes;
                    //console.log(person.detalle[i].users.name)
                    for (
                      var j = 0;
                      j < rowData.detalle[i].firmantes.length;
                      j++
                    ) {
                      var fila = rowData.detalle[i].firmantes[j];
                      data = [...data, fila];
                    }
                  }
                  //console.log(data);
                  setTablaFirmas(data);
                  setModalFirmas(true);
                },
              },
              {
                icon: () => <BarChartIcon
                  className={
                    classes2.tableActionButtonIcon +
                    " " +
                    classes2.edit
                  }
                />,
                tooltip: "Ver Gráficas",
                onClick: (event, rowData) => {
                  //console.log(rowData.detalle);
                  //data grafic pie
                  var s1 = 0;
                  var s2 = 0;
                  var s3 = 0;
                  var s4 = 0;
                  var s5 = 0;
                  for (var i = 0; i < rowData.detalle.length; i++) {
                    switch (rowData.detalle[i].status.id) {
                      case 1:
                        s1++;
                        break;
                      case 2:
                        s2++;
                        break;
                      case 3:
                        s3++;
                        break;
                      case 4:
                        s4++;
                        break;
                      case 5:
                        s5++;
                        break;
                    }
                  }
                  /*
                  const leyenda=[
                    {color: "#ab47bc", name: "Registrados"},
                    {color: "#ffa726", name: "Proceso"},
                    {color: "#ef5350", name: "Rechazados"},
                    {color: "#66bb6a", name: "Firmados"}
                  ]
                  */
                  setDataModalGrafica([
                    { angle: s1, color: "#ab47bc", name: "Registrados", label: s1 },
                    { angle: s2, color: "#ffa726", name: "Proceso", label: s2 },
                    { angle: s4, color: "#ef5350", name: "Rechazados", label: s4 },
                    { angle: s3, color: "#66bb6a", name: "Firmados", label: s3 },
                    { angle: s5, color: "#ab47bc", name: "Detenidos", label: s5 }
                  ]);
                  //fin data grafica pie
                  //crea grafica tipo bar agrupando por fecha
                  function extract() {
                    var groups = {};

                    rowData.detalle.forEach(function (val) {
                      var date = val.created_at.split('T')[0];
                      if (date in groups) {
                        groups[date].push(val.filename);
                      } else {
                        groups[date] = new Array(val.filename);
                      }
                    });

                    console.log(groups);
                    //var today = moment(new Date()).format("YYYY-MM-DD");
                    //var oneDaysAgo = new Date(new Date() - 1000 * 60 * 60 * 24 * 1);
                    //console.log(today)

                    var dias = [];
                    Object.keys(groups).forEach(function (n) {
                      var v = groups[n];
                      //console.log("key:" + n + "numero:" + v.length);
                      dias = [
                        ...dias,
                        { x: n, y: v.length }
                      ];
                    });
                    //console.log(dias);
                    return dias;
                    //return groups;
                  }
                  var dias = extract();
                  console.log(dias);
                  //buscar ultimos días
                  function ultimosDias(n) {
                    var ultimosdias = [];
                    for (var i = 1; i <= n; i++) {
                      var daysAgo = moment(new Date(new Date() - 1000 * 60 * 60 * 24 * i)).format("YYYY-MM-DD");
                      var resultado = dias.find(fecha => fecha.x === daysAgo);
                      if (resultado) {
                        ultimosdias = [
                          ...ultimosdias,
                          resultado
                        ];
                      }
                      else {
                        ultimosdias = [
                          ...ultimosdias,
                          { x: daysAgo, y: 0 }
                        ];
                      }
                    }

                    return ultimosdias
                  }
                  setDataModalGraficaDias(ultimosDias(7))
                  //calculo de ultimos 12 meses
                  function extractMeses() {
                    var groups = {};
                    rowData.detalle.forEach(function (val) {
                      var date = val.created_at.substring(0, 7);
                      if (date in groups) {
                        groups[date].push(val.filename);
                      } else {
                        groups[date] = new Array(val.filename);
                      }
                    });
                    console.log(groups);
                    var meses = [];
                    Object.keys(groups).forEach(function (n) {
                      var v = groups[n];
                      //console.log("key:" + n + "numero:" + v.length);
                      meses = [
                        ...meses,
                        { x: n, y: v.length }
                      ];
                    });
                    console.log(meses);
                    return meses;
                  }
                  //var meses = extractMeses();
                  setDataModalGraficaMeses(extractMeses())
                  //fin calculo ultimos meses
                  //fin grafica tipo var
                  setModalGrafica(true);
                },
              },
            ]}
          />
        </React.Fragment>

      )

    } else {
      return null
    }
  }

  function Clientes() {
    if (datos) {
      return (
        <Grid container style={{ marginTop: 0 }}>
          {datos.map((person) => (
            <Grid item xs={6} sm={4}>
              <Card variant="outlined">
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textPrimary"
                    gutterBottom
                  >
                    <b>{person.cliente}</b>
                  </Typography>
                  <hr />
                  <Button
                    color="primary"
                    onClick={() => {
                      setTablaDocumentos(person.detalle);
                      setModalDocumentos(true);
                    }}
                  >
                    <i className="fa fa-book" />
                    &nbsp;
                    <font size="1">{person.documentos} Documentos</font>
                  </Button>
                  <br />
                  <Button
                    color="primary"
                    onClick={() => {
                      let data = [];
                      for (var i = 0; i < person.detalle.length; i++) {
                        //var fila= person.detalle[i].firmantes;
                        //console.log(person.detalle[i].users.name)
                        for (
                          var j = 0;
                          j < person.detalle[i].firmantes.length;
                          j++
                        ) {
                          var fila = person.detalle[i].firmantes[j];
                          data = [...data, fila];
                        }
                      }
                      console.log(data);
                      setTablaFirmas(data);
                      setModalFirmas(true);
                    }}
                  >
                    <i className="fa fa-users" />
                    &nbsp;
                    <font size="1">{person.firmas} firmas</font>
                  </Button>
                  {/*GRAFICA COMPAÑÍA*/}
                  <Button
                    color="primary"
                    onClick={() => {
                      console.log(person.detalle);
                      //data grafic pie
                      var s1 = 0;
                      var s2 = 0;
                      var s3 = 0;
                      var s4 = 0;
                      var s5 = 0;
                      for (var i = 0; i < person.detalle.length; i++) {
                        switch (person.detalle[i].status.id) {
                          case 1:
                            s1++;
                            break;
                          case 2:
                            s2++;
                            break;
                          case 3:
                            s3++;
                            break;
                          case 4:
                            s4++;
                            break;
                          case 5:
                            s5++;
                            break;
                        }
                      }
                      setDataModalGrafica([
                        { angle: s1, color: "#808080", name: "Registrados", label: s1 },
                        { angle: s2, color: "#008080", name: "Proceso", label: s2 },
                        { angle: s3, color: "#008000", name: "Firmados", label: s3 },
                        { angle: s4, color: "#FF0000", name: "Rechazados", label: s4 },
                        { angle: s5, color: "#800000", name: "Detenidos", label: s5 }
                      ]);
                      //fin data grafica pie
                      //crea grafica tipo bar agrupando por fecha
                      function extract() {
                        var groups = {};

                        person.detalle.forEach(function (val) {
                          var date = val.created_at.split('T')[0];
                          if (date in groups) {
                            groups[date].push(val.filename);
                          } else {
                            groups[date] = new Array(val.filename);
                          }
                        });

                        console.log(groups);
                        //var today = moment(new Date()).format("YYYY-MM-DD");
                        //var oneDaysAgo = new Date(new Date() - 1000 * 60 * 60 * 24 * 1);
                        //console.log(today)

                        var dias = [];
                        Object.keys(groups).forEach(function (n) {
                          var v = groups[n];
                          //console.log("key:" + n + "numero:" + v.length);
                          dias = [
                            ...dias,
                            { x: n, y: v.length }
                          ];
                        });
                        //console.log(dias);
                        return dias;
                        //return groups;
                      }
                      var dias = extract();
                      console.log(dias);
                      //buscar ultimos días
                      function ultimosDias(n) {
                        var ultimosdias = [];
                        for (var i = 1; i <= n; i++) {
                          var daysAgo = moment(new Date(new Date() - 1000 * 60 * 60 * 24 * i)).format("YYYY-MM-DD");
                          var resultado = dias.find(fecha => fecha.x === daysAgo);
                          if (resultado) {
                            ultimosdias = [
                              ...ultimosdias,
                              resultado
                            ];
                          }
                          else {
                            ultimosdias = [
                              ...ultimosdias,
                              { x: daysAgo, y: 0 }
                            ];
                          }
                        }

                        return ultimosdias
                      }
                      setDataModalGraficaDias(ultimosDias(7))
                      //calculo de ultimos 12 meses
                      function extractMeses() {
                        var groups = {};
                        person.detalle.forEach(function (val) {
                          var date = val.created_at.substring(0, 7);
                          if (date in groups) {
                            groups[date].push(val.filename);
                          } else {
                            groups[date] = new Array(val.filename);
                          }
                        });
                        console.log(groups);
                        var meses = [];
                        Object.keys(groups).forEach(function (n) {
                          var v = groups[n];
                          //console.log("key:" + n + "numero:" + v.length);
                          meses = [
                            ...meses,
                            { x: n, y: v.length }
                          ];
                        });
                        console.log(meses);
                        return meses;
                      }
                      //var meses = extractMeses();
                      setDataModalGraficaMeses(extractMeses())
                      //fin calculo ultimos meses
                      //fin grafica tipo var
                      setModalGrafica(true);
                    }}
                  >
                    <i className="fa fa-pie-chart" />
                    &nbsp;
                    <font size="1"> Gráfica</font>
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      );
    } else {
      return null;
    }
  }

  const localization = {
    body: {
      emptyDataSourceMessage: "No hay datos por mostrar",
      addTooltip: "Añadir",
      deleteTooltip: "Eliminar",
      editTooltip: "Editar",
      filterRow: {
        filterTooltip: "Filtrar",
      },
    },
    header: {
      actions: "Acciones",
    },
    pagination: {
      firstAriaLabel: "Primera página",
      firstTooltip: "Primera página",
      labelDisplayedRows: "{from}-{to} de {count}",
      labelRowsPerPage: "Filas por página:",
      labelRowsSelect: "filas",
      lastAriaLabel: "Ultima página",
      lastTooltip: "Ultima página",
      nextAriaLabel: "Pagina siguiente",
      nextTooltip: "Pagina siguiente",
      previousAriaLabel: "Pagina anterior",
      previousTooltip: "Pagina anterior",
    },
    toolbar: {
      exportAriaLabel: "Exportar",
      exportTitle: "Exportar",
      exportCSVName: "Exportar a CVS",
      exportPDFName: "Exportar a PDF",
      searchPlaceholder: "Buscar",
      searchTooltip: "Buscar",
    },
  };

  return (
    <React.Fragment>
      <Grid container style={{ marginTop: 0 }}>
        <Grid item xs={12}>
          {/*
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography color="primary" gutterBottom>
              <font size="5">
                <b>COMPAÑÍAS</b>
              </font>
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography color="dark" gutterBottom>
              <font size="1">
                <b>RESUMEN DE LAS COMPAÑÍAS AFILIADAS A CON CERTEZA</b>
              </font>
            </Typography>
          </Box>
          */}
          {
            loading ? (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <CircularProgress />
              </div>
            ) : null
          }

        </Grid>
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
              <TablaClientes />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Dialog
        open={modalDocumentos}
        transition={Transition}
        keepMounted
        fullScreen={false}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => setModalDocumentos(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <b>DOCUMENTOS</b>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <div>
            <MaterialTable
              localization={localization}
              options={{
                exportButton: true,
                draggable: false,
                headerStyle: {
                  backgroundColor: colorStatistics,
                  color: "#FFF",
                },
                pageSize: 20, // make initial page size
                pageSizeOptions: [20, 50, 100, 500, 1000],
                actionsColumnIndex: -1,
                exportButton: true,
                actionsColumnIndex: 0,
              }}
              columns={[
                { title: "COMPAÑÍA", field: "users.company" },
                { title: "USUARIO", field: "users.name" },
                { title: "DOCUMENTO", field: "filename" },
                { title: "TIPO", field: "type.name" },
                {
                  title: "ASISTIDO",
                  field: "asistido",
                  render: (row) => (
                    <span>
                      {(row["asistido"]) ? "SI" : null}
                    </span>
                  )
                },
                {
                  title: "FECHA",
                  field: "created_at",
                  render: (row) => (
                    <span>
                      {(row["created_at"]) ? moment(row["created_at"]).format("DD/MM/YYYY") : null}
                    </span>
                  ),
                },
                {
                  title: "HORA",
                  field: "created_at",
                  render: (row) => (
                    <span>
                      {(row["created_at"]) ? moment(row["created_at"]).format("HH:mm:ss") : null}
                    </span>
                  ),
                },
                { title: "ESTATUS", field: "status.name" },
                { title: "FIRMANTES", field: "firmantes_names" },
                { title: "ID", field: "_id" },
                { title: "HASH", field: "hash" },
                { title: "HASH FIRMADO", field: "hash_signed" },
              ]}
              data={tablaDocumentos}
              title={""}
            />
          </div>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button onClick={() => setModalDocumentos(false)} color="success">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={modalFirmas}
        transition={Transition}
        keepMounted
        fullScreen={false}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => setModalFirmas(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <b>FIRMAS</b>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <div>
            <MaterialTable
              localization={localization}
              options={{
                draggable: false,
                exportButton: true,
                headerStyle: {
                  backgroundColor: colorStatistics,
                  color: "#FFF",
                },
                pageSize: 20, // make initial page size
                pageSizeOptions: [20, 50, 100, 500, 1000],
                actionsColumnIndex: -1,
                exportButton: true,
                actionsColumnIndex: 0,
              }}
              columns={[
                {
                  title: "FECHA",
                  field: "created_at",
                  render: (row) => (
                    <span>
                      {(row["created_at"]) ? moment(row["created_at"]).format("DD/MM/YYYY") : null}
                    </span>
                  ),
                },
                {
                  title: "HORA",
                  field: "created_at",
                  render: (row) => (
                    <span>
                      {(row["created_at"]) ? moment(row["created_at"]).format("HH:mm:ss") : null}
                    </span>
                  ),
                },
                { title: "EMPRESA", field: "company" },
                { title: "NOMBRE", field: "name" },
                { title: "APELLIDO", field: "last_name" },
                { title: "ROL", field: "role" },
                { title: "CORREO", field: "email" },
                { title: "TELÉFONO", field: "phone" },
                { title: "ID", field: "_id" },
              ]}
              data={tablaFirmas}
              title={""}
            />
          </div>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button onClick={() => setModalFirmas(false)} color="success">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      {/*
      <GraficaPie
        data={dataPie}
       />
       */}
      {/*
       <GraficaPrincipal />
      <hr />
      <GraficaPromedioFirma />
     <hr />
      <GraficaDetalle
        status={0}
        titulo={"EN TOTAL"}
        color={colorStatistics}
      />
      <hr />
      <GraficaDetalle
        status={3}
        titulo={"FIRMADOS"}
        color={"#66bb6a"}
      />
      <hr />
      <GraficaDetalle
        status={2}
        titulo={"EN PROCESO"}
        color={"#ffa726"}
      />
      <hr />
      <GraficaDetalle
        status={4}
        titulo={"RECHAZADOS"}
        color={"#ef5350"}
      />
      <hr />
      <GraficaDetalle
        status={5}
        titulo={"DETENIDOS"}
        color={"#ab47bc"}
      />
      <hr />
      <GraficaDetalle
        status={1}
        titulo={"REGISTRADOS"}
        color={"#ab47bc"}
      />
*/}
      {/*MODAL DE LA GRÁFICA DE CADA COMPAÑÍA*/}
      <Dialog
        open={modalGrafica}
        transition={Transition}
        keepMounted
        onClose={() => setModalGrafica(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalGrafica(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <b>DOCUMENTOS</b>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <div>
            <GraficaPie
              data={dataModalGrafica}
              modal={true}
            />
            <GraficaClientes
              titulo={"Últimos 7 días"}
              data={dataModalGraficaDias}
              color={colorStatistics}
              width={500}
              height={300}
            />
            <GraficaClientes
              titulo={"Últimos meses"}
              data={dataModalGraficaMeses}
              color={colorStatistics}
              width={500}
              height={300}
            />
          </div>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button onClick={() => setModalGrafica(false)} color="success">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
