let initialState = {
  signToken: "",
  user: "",
  permissions: "",
  imgLogo: "",
  frontUrl: "",
  dataUser: "",
};
export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SIGN_TOKEN": {
      return { ...state, signToken: action.payload };
    }
    case "SET_LOGO": {
      return { ...state, imgLogo: action.payload };
    }
    case "SET_PERMISSIONS": {
      return { ...state, permissions: action.payload };
    }
    case "SET_USER": {
      return { ...state, user: action.payload };
    }
    case "SET_SIGN": {
      const { user } = state;
      if (action.payload) {
        // console.log(action.payload, "ACTIONPAYLOAD");
        // Si viene la firma
        user.my_assets.firma = null; //Se limpia la firma
        user.my_assets.firma = action.payload; //Se le asigna la firma
      } else {
        user.my_assets.firma = null; // Se borra la firma
      }
      return {
        ...state,
        user,
      };
    }
    case "SET_INE_FRONT": {
      const { user } = state;
      // console.log(action.payload, "Reducer-Redux INE FRONT");
      if (action.payload) {
        // Si viene la firma
        user.my_assets.ine_front = null; //Se limpia la firma
        user.my_assets.ine_front = action.payload; //Se le asigna la firma
      } else {
        user.my_assets.ine_front = null; // Se borra la firma
      }
      return {
        ...state,
        user,
      };
    }
    case "SET_INE_BACK": {
      const { user } = state;
      // console.log(action.payload, "Reducer-Redux INE BACK");
      if (action.payload) {
        // Si viene la firma
        user.my_assets.ine_back = null; //Se limpia la firma
        user.my_assets.ine_back = action.payload; //Se le asigna la firma
      } else {
        user.my_assets.ine_back = null; // Se borra la firma
      }
      return {
        ...state,
        user,
      };
    }
    case "SET_PASSPORT": {
      const { user } = state;
      // console.log(action.payload, "Reducer-Redux INE BACK");
      if (action.payload) {
        // Si viene la firma
        user.my_assets.pasaporte = null; //Se limpia la firma
        user.my_assets.pasaporte = action.payload; //Se le asigna la firma
      } else {
        user.my_assets.pasaporte = null; // Se borra la firma
      }
      return {
        ...state,
        user,
      };
    }
    case "SET_FRASE": {
      const { frase } = state;
      return {
        ...state,
        frase,
      }
    }
    case "SET_DATA_USER": {
      return { ...state, dataUser: action.payload };

    }
    default: {
      return state;
    }
  }
};
