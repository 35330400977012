//CheckoutNew
import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import Check from "@material-ui/icons/Check";
import { useHistory } from "react-router-dom";
// import CssBaseline from "@material-ui/core/CssBaseline";
// import AppBar from "@material-ui/core/AppBar";
// import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import ButtonC from "components/CustomButtons/Button.js";
import firmasimple from "assets/img/firmaSimple2.png";
import firmacerti from "assets/img/NEWFIRMACONIDE.png";
import firmaAvanzada from "assets/img/firmaFiel.png";
import AUTOFIRMA from "assets/img/logoAutoFirma.png";
import FIRMARAPIDA from "assets/img/firmasincodigo.png";
import ContainerAutoSign from "containers/autoSign/autoSingContainer";

import PublishIcon from "@material-ui/icons/Publish";
import Typography from "@material-ui/core/Typography";
import FormFirmantes from "./Firmante/NewFormFirmantes";
import Previewpdf from "./PreviewPDF/PreviewPDF";
import AddressFormDocument from "./Firmante/Document";
import mensaje from "assets/img/mensaje.png";
import Slide from "@material-ui/core/Slide";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dashboard from "views/Dashboard/Dashboard";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import StepConnector from "@material-ui/core/StepConnector";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { MySnackbar } from "components/Snackbar/MySnackbar";
import { DialogComp } from "components/Dialog/DialogComp";
import {
  Avatar,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      " linear-gradient(79deg, rgba(76,175,80,1) 0%, rgba(102,187,106,1) 63%, rgba(92,184,96,1) 100%)",
  },
});

const useStylespro = makeStyles(styles);
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginTop: theme.spacing(-9),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(1) * 1)]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  appBar: {
    top: "auto",
    position: "absolute",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  paper: {
    // marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    borderRadius: "5px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CheckoutNew(props) {
  const ref = React.createRef();
  function goTop() {
    ref.current.scrollIntoView();
  }

  const history = useHistory();
  const [tokenstate, setTokenstate] = useState("");
  const [mobileOpen, setMobileOpen] = useState(false);
  const [idFlow, setIFLow] = useState(false);
  const [openDelete, setopenDelete] = useState(false);
  const [Register, setRegister] = useState(false);
  const [openGuardado, setOpenGuardado] = useState(false);
  const [isOpenEdit, setisOpenEdit] = useState(true);
  const [goDashboard, setgoDashboard] = useState(false);
  const handleCloseGuardado = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenGuardado(false);
  };

  const mainPanel = React.createRef();
  const classespro = useStylespro();
  const {
    tabs,
    idDocumentotable,
    idprocces,
    idDocumentoDetenido,
    setOpenForm,
    setActiveRender,
    roles,
    idStatus,
    setProgrees,
  } = props;
  console.log(idDocumentotable, "idDocumentotable");

  // let { id } = useParams();

  // Asignar nuevo tipo de proceso de firma\
  const [currentStep, setCurrentStep] = useState("");
  const [isAnotherType, setisAnotherType] = useState(false);

  const [newAssignProcess, setNewAssign] = useState({
    openNewAssignProcess: false,
    currentIdProcess: Number(idprocces),
    valueAssignNew: 0,
    disabledAssign: true,
  });

  const {
    openNewAssignProcess,
    currentIdProcess,
    disabledAssign,
    valueAssignNew,
  } = newAssignProcess;

  const [idRequired, setIdRequired] = useState(false);
  const [asistido, setAsistido] = useState(false);
  const [fechaCierta, setFechaCierta] = useState(false);

  const [descripcion, setDescripcion] = useState("");
  const [inicio, setInicio] = useState("");
  const [termino, setTermino] = useState("");
  const [diasAlert, setDiasAlert] = useState(0);
  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);
  const [idDocumento, setidDocumento] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [progress, setprogress] = useState(false);
  const [widthstate, setWidth] = useState(600);
  const [corporaciones, setCorporaciones] = useState([]);
  const [valueNotificaciones, setValueNotificaciones] = React.useState("1");

  const steps = ["Carga", "Registra"];

  React.useEffect(() => {
    window.scrollTo(0, 0);
    handleCorporativo();
    setidDocumento("");
    console.log("dkf", roles);
    let token = sessionStorage.getItem("Token");
    console.log("TABS2", currentIdProcess);
    setTokenstate(token);

    if (tabs == "1") {
      if (idDocumentoDetenido) {
        setidDocumento(idDocumentoDetenido);
        setRegister(true);

        handleDialog();
      } else {
        handleDialog();
        setidDocumento(idDocumentotable);
        setRegister(true);
      }
    } else if (currentIdProcess == "2") {
      setIFLow(true);

      console.log("TABS2", currentIdProcess);
      setIdRequired(true);
    }
  }, [idDocumentotable]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  console.log("valueNotificaciones", valueNotificaciones);

  const modificarOrden = () => {
    console.log("entro a modificar orden");
    let sync_signState = 0;
    if (valueNotificaciones == 2) {
      sync_signState = 1;
    }
    const URL = `${process.env.REACT_APP_URL}api/documentos/${idDocumento}`;

    let data = {
      sync_sign: sync_signState,
    };

    let token = sessionStorage.getItem("Token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: " application/json",
      },
    };
    axios
      .put(URL, data, config)
      .then((res) => {
        const data = res.data;
        handleGoBackHome();

        console.log("MODIFDOCU.", data);
      })
      .catch((error) => {
        alert("se produjo un error ");
        console.error(error);
      });
  };
  // console.log("tokenLOCAL STORAGE CHECKOU", tokenstate);

  const handleCorporativo = () => {
    let token = sessionStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/corporaciones`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("RESPCORPO", res.data);
        setCorporaciones(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
      1: <PublishIcon />,
      2: <GroupAddIcon />,
      3: <VideoLabelIcon />,
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {completed ? <Check /> : icons[String(props.icon)]}
      </div>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };
  function onChangeValue(event) {
    setIdRequired(event.target.value);
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };
  const handleDialog = () => {
    setActiveStep(1);
  };

  const senFirmante = () => {
    setTimeout(handleNext, 800);
  };

  const sendDocument = () => {
    setTimeout(handleNext, 500);
  };

  // alert functions

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const openAlert = (error) => {
    setOpenSnack(true);
    setMessageError(error);
  };

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${tokenstate}`,
      Accept: " application/json",
      type: "formData",
    },
  };

  function saveMetadata(idDoc) {
    console.log("SAVE METADATA");
    let token = sessionStorage.getItem("Token");
    let endpoint = `${process.env.REACT_APP_URL}api/documentos/${idDoc}?description=${descripcion}&meta[inicio]=${inicio}&meta[termino]=${termino}`;
    console.log(endpoint);
    /*
    let endpoint="";
    if(descripcion!==""){
      endpoint =`${process.env.REACT_APP_URL}api/documentos/${idDoc}?description=${descripcion}&meta[inicio]=${inicio}&meta[termino]=${termino}`;
    }
    else{
      endpoint =`${process.env.REACT_APP_URL}api/documentos/${idDoc}?meta[inicio]=${inicio}&meta[termino]=${termino}`;
    }
    */

    var config = {
      method: "PUT",
      url: endpoint,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("METADATA ACTUALIZADA:", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleGoBackHome = async () => {
    const Location = window.location.pathname;
    let uri = localStorage.getItem("uri");
    if (Location == `/${uri}/dashboard`) {
      setOpenForm(0);
      console.log("entrovalidacion");
    } else {
      history.push(`/${uri}/dashboard`);
      console.log("no entro");
    }
  };

  const onChangePDF = async (idCompany, metaData, idClases, grupo) => {
    const data = new FormData();
    setprogress(true);
    var file = document.querySelector('input[type="file"]').files[0];
    data.append("filename", file);
    if (grupo) {
      data.append("roles_id", grupo);
    }

    if (idClases) {
      data.append("fc_clase_id", idClases);
    }
    var valorasistido;
    asistido ? (valorasistido = 1) : (valorasistido = 0);
    data.append("asistido", valorasistido);
    if (idCompany) {
      data.append("corporaciones_id", idCompany);
    }

    if (fechaCierta) {
      data.append("fc_status_id", 1);
    }

    data.append("documento_types_id", currentIdProcess);

    if (descripcion !== "") {
      data.append("description", descripcion);
    }
    data.append("meta[info]", JSON.stringify(metaData));
    if (idprocces == 12) {
      data.append("cert_required", true);
    }
    if (inicio !== "") {
      data.append("meta[vigencia_start]", inicio + "00:00:00");
    }
    if (termino !== "") {
      data.append("meta[vigencia_end]", termino + "23:59:59");
    }
    if (diasAlert > 0) {
      data.append("meta[dias_alerta]", diasAlert);
    }
    console.log("fiLE NEW", data);
    let url = `${process.env.REACT_APP_URL}api/documentos`;

    axios
      .post(url, data, config, {
        // receive two parameter endpoint url ,form data
      })
      .then((response) => {
        // Respuesta del servidor
        let data = response.data[0];
        setidDocumento(data.id);
        sendDocument();
        setprogress(false);
        //saveMetadata(data.id);
        //muestra mensaje autoguardado
        setOpenGuardado(true);
      })
      .catch((error) => {
        // Error 😨
        if (error.response.data.errors) {
          //openAlert("El archivo debe ser de tipo PDF sin ninguna encriptacion");
          let errors = error.response.data.errors;
          if (errors["meta.vigencia_start"]) {
            openAlert(
              "Por favor indique una fecha de inicio de vigencia o borre la fecha de fin de vigencia"
            );
          } else {
            let textoerror = JSON.stringify(error.response.data.errors);
            openAlert(textoerror.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""));
          }
        } else if (error.response.data.message) {
          openAlert(error.response.data.message);
        } else {
          openAlert("Su documento tiene algun problema");
        }

        setprogress(false);
      });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <AddressFormDocument
            onChangePDF={onChangePDF}
            progress={progress}
            setAsistido={setAsistido}
            setFechaCierta={setFechaCierta}
            asistido={asistido}
            fechaCierta={fechaCierta}
            descripcion={descripcion}
            setDescripcion={setDescripcion}
            setInicio={setInicio}
            setTermino={setTermino}
            setDiasAlert={setDiasAlert}
            setActiveRender={setActiveRender}
            roles={roles}
            corporaciones={corporaciones}
          />
        );
      case 1:
        return (
          <FormFirmantes
            setisOpenEdit={setisOpenEdit}
            idDocumento={idDocumento}
            senFirmante={senFirmante}
            handleDelete={handleDelete}
            idRequired={idRequired}
            setIdRequired={setIdRequired}
            Register={Register}
            idprocces={currentIdProcess}
            idDocumentoDetenido={idDocumentoDetenido}
            valueNotificaciones={valueNotificaciones}
            setValueNotificaciones={setValueNotificaciones}
          />
        );

      default:
        throw new Error("Unknown step");
    }
  };

  const dialogDelete = () => {
    setopenDelete(true);
  };

  const handleDelete = () => {
    goTop();
    let urlDeleteDoc = `${process.env.REACT_APP_URL}api/documentos/${idDocumento}`;
    console.log("URL DELETE");
    axios
      .delete(urlDeleteDoc, {
        headers: {
          Authorization: `Bearer ${tokenstate}`,
        },
        data: {},
        // receive two parameter endpoint url ,form data
      })
      .then((response) => {
        // Respuesta del servidor
        setopenDelete(false);
        console.log("respuest a data", response);
        setActiveStep(0);
      })
      .catch((error) => {
        // Error 😨
        if (error.response) {
          console.log(error.response.data.message);
          openAlert(error.response.data.message);
        } else if (error.request) {
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  if (activeStep === 1 && widthstate === 600) {
    setWidth(1000);
  }
  if (activeStep === 0 && widthstate === 1000) {
    setWidth(600);
  }
  const handleVolver = () => {
    let uri = localStorage.getItem("uri");
    if (setActiveRender) {
      setActiveRender(0);
    } else {
      history.push(`/${uri}/nuevoProceso`);
    }
  };

  const handleVolver2 = () => {
    setgoDashboard(true);
  };
  const handleCloseAssign = () => {
    setNewAssign({
      ...newAssignProcess,
      openNewAssignProcess: false,
      valueAssignNew: 0,
      disabledAssign: true,
    });
    // setCurrentStep("");
  };

  const handleOpenTypes = () => {
    setNewAssign({
      ...newAssignProcess,
      openNewAssignProcess: true,
    });
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    setNewAssign({
      ...newAssignProcess,
      valueAssignNew: Number(value),
      disabledAssign: false,
    });
  };

  const handleChangeProcess = () => {
    setCurrentStep("");
    setProgrees(true);
    setNewAssign({
      ...newAssignProcess,
      openNewAssignProcess: false,
      disabledAssign: true,
    });
    let newValueAsign = valueAssignNew;

    let token = sessionStorage.getItem("Token");
    let endpoint = `${process.env.REACT_APP_URL}api/documentos/${idDocumentotable}?documento_types_id=${newValueAsign}`;
    var config = {
      method: "PUT",
      url: endpoint,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then(function (response) {
        setProgrees(false);
        setNewAssign({
          currentIdProcess: Number(newValueAsign),
          valueAssignNew: 0,
          disabledAssign: true,
          openNewAssignProcess: false,
        });
        if (newValueAsign == 7) {
          setCurrentStep(2);
          setisAnotherType(true);
        }
        // refreshTables();
        // receive two parameter endpoint url ,form data
      })
      .catch((error) => {
        // Error 😨
        console.log(error, "Error =>");
        setProgrees(false);
        if (error.response.data.errors) {
          //openAlert("El archivo debe ser de tipo PDF sin ninguna encriptacion");
        } else if (error.response.data.message) {
          openAlert(error.response.data.message);
        } else {
          openAlert("Su documento tiene algun problema");
        }
      });
    //  handleOpenForm(rowDataAssign, valueAssignNew);
  };
  // console.log(activeStep);
  return (
    <>
      {goDashboard ? (
        <Dashboard mainTab={4} />
      ) : (
        <React.Fragment>
          {activeStep == 0 ? (
            <Button
              size="large"
              style={{ marginTop: "-9px", height: "30px" }}
              startIcon={<ArrowBackIcon />}
              onClick={() => handleVolver()}
            >
              Regresar
            </Button>
          ) : idDocumentotable ? (
            <Button
              size="large"
              style={{ marginTop: "-9px", height: "30px" }}
              startIcon={<ArrowBackIcon />}
              onClick={() => handleVolver2()}
            >
              Regresar
            </Button>
          ) : (
            ""
          )}

          {isAnotherType ? (
            <>
              <ContainerAutoSign
                setProgrees={setProgrees}
                tabs={tabs}
                idprocces={idprocces}
                idDocumentotable={idDocumento}
                setOpenForm={setOpenForm}
                idStatus={idStatus}
                currentStep={currentStep}
              />
            </>
          ) : (
            <>
              <main
                className={classes.layout}
                style={{ maxWidth: widthstate }}
                ref={ref}
              >
                <Paper className={classes.paper}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={10}>
                      {currentIdProcess == 1 ? (
                        <div>
                          <Typography
                            component="h1"
                            variant="h5"
                            align="center"
                          >
                            FIRMA SIMPLE CON
                          </Typography>
                          <Typography
                            component="h1"
                            variant="h5"
                            align="center"
                          >
                            CONFIRMACIÓN POR CÓDIGO
                          </Typography>
                        </div>
                      ) : currentIdProcess == 2 ? (
                        <Typography component="h1" variant="h5" align="center">
                          FIRMA SIMPLE CON IDENTIFICACIÓN
                        </Typography>
                      ) : currentIdProcess == 8 ? (
                        <div>
                          <Typography
                            component="h1"
                            variant="h5"
                            align="center"
                          >
                            FIRMA SIMPLE CONFIRMACIÓN
                          </Typography>
                          <Typography
                            component="h1"
                            variant="h5"
                            align="center"
                          >
                            CON CASILLA DE VERIFICACIÓN
                          </Typography>
                        </div>
                      ) : currentIdProcess == 12 ? (
                        <div>
                          <Typography
                            component="h1"
                            variant="h5"
                            align="center"
                          >
                            FIRMA ELECTRÓNICA
                          </Typography>
                          <Typography
                            component="h1"
                            variant="h5"
                            align="center"
                          >
                            AVANZADA
                          </Typography>
                        </div>
                      ) : currentIdProcess == 13 ? (
                        <div>
                          <Typography
                            component="h1"
                            variant="h5"
                            align="center"
                          >
                            FIRMA HÍBRIDA
                          </Typography>
                        </div>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <div align="center">
                        {isOpenEdit &&
                          (idStatus == 1 ? (
                            <Tooltip title="Cambiar tipo de proceso de firma">
                              <IconButton
                                onClick={handleOpenTypes}
                                aria-label="change"
                              >
                                <EditIcon fontSize="large" />
                              </IconButton>
                            </Tooltip>
                          ) : null)}
                      </div>
                    </Grid>
                  </Grid>

                  <DialogComp
                    open={openNewAssignProcess}
                    title={"Seleccione el proceso de firma "}
                    content={
                      <>
                        <List className={classes.root}>
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="quiz"
                              name="quiz"
                              value={valueAssignNew}
                              onChange={handleRadioChange}
                            >
                              {currentIdProcess != 1 && (
                                <ListItem>
                                  <FormControlLabel
                                    value={1}
                                    control={<Radio />}
                                  />
                                  <ListItemAvatar>
                                    <Avatar>
                                      <img
                                        src={firmasimple}
                                        alt="Con-Certeza"
                                        width="65"
                                        height="65"
                                      />
                                    </Avatar>
                                  </ListItemAvatar>
                                  <Tooltip
                                    placement="top-start"
                                    title="Solicita que el firmante confirme el proceso con un código de validación."
                                  >
                                    <ListItemText primary="FIRMA SIMPLE CON CONFIRMACIÓN POR CÓDIGO" />
                                  </Tooltip>
                                </ListItem>
                              )}
                              {currentIdProcess != 2 && (
                                <ListItem>
                                  <FormControlLabel
                                    value={2}
                                    control={<Radio />}
                                  />
                                  <ListItemAvatar>
                                    <Avatar>
                                      <img
                                        src={firmacerti}
                                        alt="Con-Certeza"
                                        width="65"
                                        height="63"
                                      />
                                    </Avatar>
                                  </ListItemAvatar>
                                  <Tooltip
                                    placement="top-start"
                                    title="Solicita que el firmante adjunte su identificación oficial durante el proceso de firma."
                                  >
                                    <ListItemText primary="FIRMA SIMPLE CON IDENTIFICACIÓN" />
                                  </Tooltip>
                                </ListItem>
                              )}
                              {currentIdProcess != 7 && (
                                <ListItem>
                                  <FormControlLabel
                                    value={7}
                                    control={<Radio />}
                                  />
                                  <ListItemAvatar>
                                    <Avatar>
                                      <img
                                        src={AUTOFIRMA}
                                        alt="Con-Certeza"
                                        width="65"
                                        height="63"
                                      />
                                    </Avatar>
                                  </ListItemAvatar>
                                  <Tooltip
                                    placement="top-start"
                                    title="Emite y firma tus propios documentos."
                                  >
                                    <ListItemText primary="AUTOFIRMA" />
                                  </Tooltip>
                                </ListItem>
                              )}
                              {currentIdProcess != 8 && (
                                <ListItem>
                                  <FormControlLabel
                                    value={8}
                                    control={<Radio />}
                                  />
                                  <ListItemAvatar>
                                    <Avatar>
                                      <img
                                        src={FIRMARAPIDA}
                                        alt="Con-Certeza"
                                        width="67"
                                        height="65"
                                      />
                                    </Avatar>
                                  </ListItemAvatar>
                                  <Tooltip
                                    placement="top-start"
                                    title="Solicita que el firmante confirme el proceso completando una casilla de verificación."
                                  >
                                    <ListItemText primary="FIRMA SIMPLE CONFIRMACIÓN CON CASILLA DE VERIFICACIÓN" />
                                  </Tooltip>
                                </ListItem>
                              )}
                            </RadioGroup>
                          </FormControl>
                        </List>
                      </>
                    }
                    maxWidth={"sm"}
                    buttons={[
                      {
                        buttonEvent: handleCloseAssign,
                        buttonName: "Cancelar",
                        buttonColor: "secondary",
                      },
                      {
                        buttonDisabled: disabledAssign,
                        buttonEvent: handleChangeProcess,
                        buttonName: "Confirmar",
                        buttonColor: "primary",
                      },
                    ]}
                  />
                  <Stepper
                    activeStep={activeStep}
                    className={classes.stepper}
                    connector={<ColorlibConnector />}
                  >
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>
                          {label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <React.Fragment>
                    {activeStep === steps.length ? (
                      <React.Fragment>
                        <Typography
                          variant="h6"
                          gutterBottom
                          align="center"
                          style={{ textTransform: "none" }}
                        >
                          Creación de proceso de firma exitoso, se notificará a
                          los involucrados.
                        </Typography>

                        <div align="center">
                          <img
                            src={mensaje}
                            alt="logo"
                            width="260"
                            height="220"
                          />
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {getStepContent(activeStep)}
                        {activeStep === steps.length - 1 ? (
                          <div className={classes.buttons}>
                            <ButtonC
                              color="danger"
                              size="md"
                              type="button"
                              onClick={dialogDelete}
                              className={classes.button}
                            >
                              Eliminar
                            </ButtonC>
                            <ButtonC
                              color="info"
                              size="md"
                              type="button"
                              onClick={() => {
                                modificarOrden();
                              }}
                              className={classes.button}
                            >
                              Guardar cambios
                            </ButtonC>
                          </div>
                        ) : activeStep === steps.length - 2 ? (
                          ""
                        ) : (
                          <React.Fragment>
                            <div className={classes.buttons}>
                              {activeStep !== 0 && (
                                <Button
                                  onClick={handleDelete}
                                  className={classes.button}
                                  color="secondary"
                                >
                                  <Typography
                                    variant="button"
                                    style={{ textTransform: "none" }}
                                  >
                                    Eliminar
                                  </Typography>
                                </Button>
                              )}
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                                className={classes.button}
                              >
                                {activeStep === steps.length - 1 ? (
                                  "Mandar a firma"
                                ) : activeStep === steps.length - 2 ? (
                                  <Typography
                                    variant="button"
                                    style={{ textTransform: "none" }}
                                  >
                                    Registrar firmantes
                                  </Typography>
                                ) : activeStep === steps.length - 3 ? (
                                  "Ver documento"
                                ) : (
                                  "siguiente"
                                )}
                              </Button>
                            </div>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                </Paper>
              </main>

              <div className={classes.root}>
                <Snackbar
                  open={openSnack}
                  autoHideDuration={9000}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  onClose={handleCloseSnack}
                >
                  <Alert onClose={handleCloseSnack} severity="error">
                    {messageError}
                  </Alert>
                </Snackbar>
              </div>
              <div>
                <Dialog
                  // fullWidth={true}
                  // maxWidth="md"

                  open={openDelete}
                  TransitionComponent={Transition}
                  keepMounted
                  // onClose={handleCloseDialog}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogTitle id="alert-dialog-slide-title">
                    {"Eliminar documento"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      <Typography
                        variant="subtitle1"
                        style={{ textTransform: "none" }}
                      >
                        ¿Está seguro de eliminar este documento?
                      </Typography>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setopenDelete(false)}>Cerrar</Button>
                    <Button onClick={handleDelete} color="secondary">
                      Eliminar
                    </Button>
                  </DialogActions>
                </Dialog>
                {/* <MySnackbar
          openAlert={openGuardado}
          onClose={handleCloseGuardado}
          error={snackError}
          title={snackTitle}
          duration={20000}
        /> */}
                <Snackbar
                  open={openGuardado}
                  autoHideDuration={4000}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  onClose={handleCloseGuardado}
                >
                  <Alert onClose={handleCloseGuardado} severity="info">
                    Documento Guardado en Registrados
                  </Alert>
                </Snackbar>
              </div>
            </>
          )}
        </React.Fragment>
      )}
    </>
  );
}
