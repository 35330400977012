/**
 * Prueba de vida, procesador de voz.
 * Si se detecta el API De SpeechRecognition en el navegador se utiliza.
 * Si no, el microservicio de Apollo Azure en Con-Certeza.
 */

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
const MediaRecorder = window.MediaRecorder;

const PVSpeechProcessor = {
    _recognizer: null,
    _speechCache: null,
    onSpeech: null,
    /**
     * Returns if the SpeechRecognition is available or not.
     * @returns {Boolean}
     */
    isSpeechRecognitionAvailable() {
        return SpeechRecognition !== undefined;
    },
    isMediaRecorderAvailable() {
        if (!MediaRecorder || typeof MediaRecorder.isTypeSupported !== "function")
            return false;

        return MediaRecorder.isTypeSupported("video/webm") === true;
    },
    isCaptureAvailable() {
        const el = document.createElement('input')
        const supported = el.capture !== undefined
        el.remove();
        return supported;
    },
    /**
     * Returns a boolean, whether if the SpeechRecognition could be started or not.
     * @returns {Boolean}
     */
    async startRecognition() {
        if (!this.isSpeechRecognitionAvailable())
            return false;

        if (this._recognizer) {
            this._recognizer.stop();
            await new Promise((solve) => setTimeout(solve, 100));
        }

        const recognition = new SpeechRecognition();
        recognition.lang = "es-US";
        recognition.continuous = true; // Solucion audio cortado Chrome/Edge
        recognition.onresult = (ev) => this.processSpeech(ev);
        recognition.start();

        console.log("[*] Speech recognition started.");

        this._recognizer = recognition;
        this._speechCache = null;
        return true;
    },
    processSpeech(event) {
        this._speechCache = event;
        if (!event || !event.results || event.results.length <= 0)
            return '';

        const { transcript } = event.results[0][0];
        const msg = transcript && transcript.length > 0 ? transcript : '';
        console.log("[*] Got speech result: '" + msg + "'");
        if (typeof this.onSpeech === "function") {
            this.onSpeech(msg);
        }
        return msg;
    },
    /**
     * Stops SpeechRecognition API, returns empty string if is not available, String with the STT result if possible.
     * @returns {String|null}
     */
    async stopRecognition() {
        if (!this.isSpeechRecognitionAvailable())
            return '';

        this._recognizer.stop();
        await new Promise((solve) => setTimeout(solve, 2000));
        return this.getCachedResult();
    },
    /**
     * Returns the cached String Speech-To-Text result, if possible. Empty string if not available.
     * @returns {String}
     */
    getCachedResult() {
        const event = this._speechCache;

        if (!event || !event.results || event.results.length <= 0)
            return '';

        const { transcript } = event.results[0][0];
        const msg = transcript && transcript.length > 0 ? transcript : '';
        return msg;
    }
}

export default PVSpeechProcessor;