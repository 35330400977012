import React from "react";
import IconButton from "@material-ui/core/IconButton";
import PrintIcon from "@material-ui/icons/Print";

const PDFPrinter = ({ file }) => {
  const print = () => {
    const pdfFrame = document.createElement("iframe");
    pdfFrame.style.visibility = "hidden";
    pdfFrame.src = file;

    document.body.appendChild(pdfFrame);

    pdfFrame.contentWindow.focus();
    pdfFrame.contentWindow.print();
  };
  return (
    <IconButton color="inherit" aria-label="open drawer" onClick={print}>
      <PrintIcon />
    </IconButton>
  );
};

export default PDFPrinter;
