import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MaterialTable from "material-table";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InfoIcon from "@material-ui/icons/Info";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Snackbar from "components/Snackbar/Snackbar.js";
import ErrorIcon from "@material-ui/icons/Error";
import StarIcon from "@material-ui/icons/Star";
import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
const useStylestable = makeStyles(styles2);
const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    float: "right",
  },
  buttons2: {
    display: "flex",
    justifyContent: "flex-end",
    float: "right",
    // marginTop: theme.spacing(1),
  },
}));
const regexRFC = /^[a-zA-Z]{3,4}(\d{6})((\D|\d){2,3})?$/;

//credenciales API
const username = "ce2af9cab947ed15288e058ecd5350f5";
const password = "9c9dd0db9b4a23eddf64b96e28b1214b";
const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");
//const PROXY_URL = 'https://cors-anywhere.herokuapp.com/';
//const PROXY_URL = "https://sistemas.lemperroni.com/";
const PROXY_URL = "";

export default function Firmantes(props) {
  const classes = useStyles();
  const classes2 = useStylestable();
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });
  const [modal, setModal] = React.useState(false);
  const [modalNotificados, setModalNotificados] = React.useState(false);
  const [listaFirmantes, setListaFirmantes] = React.useState([]);
  const [listaNotificados, setListaNotificados] = React.useState([]);
  const [agregarTitulo, setAgregarTitulo] = React.useState("AGREGAR FIRMANTE");
  const [datosFirmantesPrevios, setDatosFirmantesPrevios] = React.useState([
    {},
  ]);
  const [firmantesPrevios, setFirmantesPrevios] = React.useState(false);
  const [notificadosPrevios, setNotificadosPrevios] = React.useState(false);
  const [
    datosNotificadosFrecuentes,
    setDatosNotificadosFrecuentes,
  ] = React.useState([{}]);
  const [textoValidacionMail, setTextoValidacionMail] = React.useState("");
  const myRef = React.createRef();
  const myRefn = React.createRef();
  const [datos, setDatos] = React.useState({
    nombre: "",
    apellidos: "",
    correo: "",
    //  celular: "",
    rfc: "",
    compañia: "",
  });
  const [datosNotificados, setDatosNotificados] = React.useState({
    nombren: "",
    apellidosn: "",
    correon: "",
  });

  // alert functions
  const [openSnack, setOpenSnack] = React.useState(false);
  const [messageError, setMessageError] = React.useState("");
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const openAlert = (error) => {
    setOpenSnack(true);
    setMessageError(error);
    setTimeout(function () {
      setOpenSnack(false);
    }, 6000);
  };

  function getFirmantesPrevios() {
    //busca los firmantes del usuario en curso
    let token = sessionStorage.getItem("Token");
    var config = {
      method: "get",
      //url: `${process.env.REACT_APP_URL}api/firmantes`,
      url: `${process.env.REACT_APP_URL}api/firmantesfrecuentes`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("Firmantes previos:", response.data);
        setDatosFirmantesPrevios(response.data);
        setFirmantesPrevios(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getNotificadosFrecuentes() {
    let token = sessionStorage.getItem("Token");

    //busca los firmantes del usuario en curso
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/notificadosfrecuentes`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("PREVIOS", response.data);
        setDatosNotificadosFrecuentes(response.data);
        setNotificadosPrevios(true);
      })
      .catch(function (error) {
        console.log(error);
        setNotificadosPrevios(false);
      });
  }

  function bn(email) {
    for (var i = datosNotificadosFrecuentes.length; i--;) {
      if (datosNotificadosFrecuentes[i].email === email) {
        return datosNotificadosFrecuentes[i];
      }
    }
    return null;
  }

  const onChangen = (event, value) => {
    console.log("change");
    //console.log(value.email);
    if (value) {
      var existente = bn(value.email);
      console.log("existente", existente);

      if (existente) {
        setDatosNotificados({
          ...datosNotificados,
          nombren: existente.name,
          apellidosn: existente.last_name,
          correon: existente.email,
        });
      } else {
        setDatosNotificados({
          nombren: "",
          apellidosn: "",
          correon: "",
        });
      }
    } else {
      setDatosNotificados({
        nombren: "",
        apellidosn: "",
        correon: "",
      });
    }
  };

  const eliminarFirmanteCDO = (idfirmante) => {
    const URL =
      "https://api.certificaciondigital.online/api/contrato/firmante/" +
      idfirmante +
      "/delete";
    var config = {
      method: "get",
      url: PROXY_URL + URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Eliminado:", response.data);
        actualizarFirmantes();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function eliminarFCC(id) {
    let tokenCC = sessionStorage.getItem("Token");

    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_URL}api/firmantes/${id}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("FirmanteCC eliminado:", response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //const eliminarFirmanteCC = (contratoCC, emailfirmante) => {
  const eliminarFirmanteCC = (contratoCC, rfc) => {
    let tokenCC = sessionStorage.getItem("Token");

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/documentos/${contratoCC}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };

    axios(config)
      .then(function (response) {
        var firmantesCC = response.data.firmantes;
        const found = firmantesCC.find((element) => element.rfc === rfc);
        if (found) {
          eliminarFCC(found._id);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const actualizarFirmantes = () => {
    const URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      props.contrato.id +
      "/firmante/lista";
    var config = {
      method: "get",
      url: PROXY_URL + URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Firmantes:", response.data);
        if (response.data.length === 0) {
          setAgregarTitulo("AGREGAR FIRMANTE");
          props.setEnviar(true);
        } else {
          setAgregarTitulo("AGREGAR FIRMANTE ADICIONAL");
          props.setEnviar(false);
        }
        setListaFirmantes(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const actualizarNotificados = () => {
    //se toma la información del contrato
    //const URL = "https://api.certificaciondigital.online/api/contrato/"+props.contrato.id+"/notificador/lista";
    const URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      props.contrato.id +
      "/get";
    var config = {
      method: "get",
      url: PROXY_URL + URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("Notificados:", response.data.notificadores);
        setListaNotificados(response.data.notificadores);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  React.useEffect(() => {
    //console.log("ContratoCDO:", props.contrato.id);
    //console.log("ContratoCC:", props.contratoCC[1]._id);
    getFirmantesPrevios();
    getNotificadosFrecuentes();
    actualizarFirmantes();
    actualizarNotificados();
  }, []);

  //FIRMANTES CDO
  function makeGetRequestFirmantes() {
    const URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      props.contrato.id +
      "/firmante/save";

    const data = new FormData();
    data.append("nombre", datos.nombre + " " + datos.apellidos);
    data.append("rfc", datos.rfc);
    data.append("email", datos.correo);

    var urlDocumento = PROXY_URL + URL;
    var config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    return new Promise(function (resolve, reject) {
      axios.post(urlDocumento, data, config, {}).then(
        (response) => {
          var result = response.data;
          console.log("Processing Request");
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  //FIRMANTES CON CERTEZA
  function makeGetRequestFirmantesCC() {
    let tokenCC = sessionStorage.getItem("Token");
    const data = new FormData();
    data.append("documentos_id", props.contratoCC[1]._id);
    data.append("name", datos.nombre);
    data.append("last_name", datos.apellidos);
    data.append("notification_types_id", "1");
    data.append("email", datos.correo);
    data.append("role", "No Aplica");
    data.append("firmante_involvements_id", "0");
    data.append("company", datos.compañia);
    data.append("rfc", datos.rfc);

    //var  urlDocumento= PROXY_URL+URL;
    var urlDocumento = process.env.REACT_APP_URL + "api/firmantes";
    var config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer  " + tokenCC,
      },
    };
    return new Promise(function (resolve, reject) {
      axios.post(urlDocumento, data, config, {}).then(
        (response) => {
          var result = response.data;
          console.log("Processing Request");
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  function makeGetRequestNotificados() {
    const URL =
      "https://api.certificaciondigital.online/api/contrato/" +
      props.contrato.id +
      "/notificador/save";

    const data = new FormData();
    data.append(
      "nombre",
      datosNotificados.nombren + " " + datosNotificados.apellidosn
    );
    data.append("email", datosNotificados.correon);

    var urlDocumento = PROXY_URL + URL;
    var config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    return new Promise(function (resolve, reject) {
      axios.post(urlDocumento, data, config, {}).then(
        (response) => {
          var result = response.data;
          console.log("Processing Request");
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  async function registraFirmantes() {
    if (firmantesPrevios) {
      myRef.current
        .getElementsByClassName("MuiAutocomplete-clearIndicator")[0]
        .click();
    }

    //verificar si existe RFC y correo
    let guardar = true;
    for (var i = 0; i < listaFirmantes.length; i++) {
      //if( (listaFirmantes[i].rfc.toUpperCase() === datos.rfc.toUpperCase()) || (listaFirmantes[i].email===datos.correo) ) {
      if (listaFirmantes[i].rfc.toUpperCase() === datos.rfc.toUpperCase()) {
        guardar = false;
      }
    }

    if (guardar) {
      var result = await makeGetRequestFirmantes();
      console.log(result);
      console.log("Firmante CDO OK");

      var resultCC = await makeGetRequestFirmantesCC();
      console.log(resultCC);
      console.log("Firmante CC OK");
      setDatos({
        nombre: "",
        apellidos: "",
        correo: "",
        rfc: "",
        compañia: "",
      });
      actualizarFirmantes();
      //      console.log("Firmante OK");
    } else {
      openAlert(
        "No es posible agregar el firmante. Se encontró un registro duplicado"
      );
    }
  }

  async function registraNotificados() {
    if (notificadosPrevios) {
      myRefn.current
        .getElementsByClassName("MuiAutocomplete-clearIndicator")[0]
        .click();
    }
    var result = await makeGetRequestNotificados();
    console.log(result);
    setDatosNotificados({
      nombren: "",
      apellidosn: "",
      correon: "",
    });
    actualizarNotificados();
    console.log("Notificado OK");
  }

  const handleClickNotificados = () => {
    registraNotificados();
  };

  const handleClick = () => {
    registraFirmantes();
  };

  const handleInput = (event) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };

  const handleInputNotificados = (event) => {
    setDatosNotificados({
      ...datosNotificados,
      [event.target.name]: event.target.value,
    });
  };

  let alertRFC = "";
  if (datos.rfc && datos.rfc !== "") {
    alertRFC = regexRFC.test(datos.rfc) ? "" : "RFC inválido.";
  }

  let disableform = true;
  if (
    datos.nombre !== "" &&
    datos.apellidos !== "" &&
    datos.correo !== "" &&
    datos.rfc !== "" &&
    datos.rfc !== undefined &&
    datos.compañia !== "" &&
    alertRFC === ""
  ) {
    disableform = false;
  }

  let disableformnotificados = true;
  if (datosNotificados.nombren !== "" && datosNotificados.correon !== "") {
    disableformnotificados = false;
  }

  const verFirmantes = () => {
    console.log(listaFirmantes);
    setModal(true);
  };

  const verNotificados = () => {
    setModalNotificados(true);
  };

  const localization = {
    body: {
      emptyDataSourceMessage: "No hay datos por mostrar",
      addTooltip: "Añadir",
      deleteTooltip: "Eliminar",
      editTooltip: "Editar",
      filterRow: {
        filterTooltip: "Filtrar",
      },
    },
    header: {
      actions: "",
    },
    pagination: {
      firstAriaLabel: "Primera página",
      firstTooltip: "Primera página",
      labelDisplayedRows: "{from}-{to} de {count}",
      labelRowsPerPage: "Filas por página:",
      labelRowsSelect: "filas",
      lastAriaLabel: "Ultima página",
      lastTooltip: "Ultima página",
      nextAriaLabel: "Pagina siguiente",
      nextTooltip: "Pagina siguiente",
      previousAriaLabel: "Pagina anterior",
      previousTooltip: "Pagina anterior",
    },
    toolbar: {
      exportAriaLabel: "Exportar",
      exportTitle: "Exportar",
      exportCSVName: "Exportar a CVS",
      exportPDFName: "Exportar a PDF",
      searchPlaceholder: "Buscar",
      searchTooltip: "Buscar",
    },
  };

  function validarMail(email) {
    let tokenSG =
      "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";
    var config = {
      async: true,
      crossDomain: true,
      method: "post",
      url: `https://api.sendgrid.com/v3/validations/email`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + tokenSG,
      },
      processData: false,
      data: `{\"email\":\"${email}\",\"source\":\"signup\"}`,
    };
    axios(config)
      .then(function (response) {
        console.log("Email Validation:", response);
        if (response.data.result.verdict === "Invalid") {
          setTextoValidacionMail("Correo no valido");
        } else {
          setTextoValidacionMail("");
        }
        //verdict: "Invalid"
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function handleInputChange(event, value) {
    //console.log(value);
    setDatos({
      ...datos,
      correo: value,
    });
  }

  function b(email) {
    for (var i = datosFirmantesPrevios.length; i--;) {
      if (datosFirmantesPrevios[i].email === email) {
        return datosFirmantesPrevios[i];
      }
    }
    return null;
  }

  const onChange = (event, value) => {
    //      console.log("change");
    //      console.log(value.email);
    if (value) {
      var existente = b(value.email);
      console.log("existente", existente);

      if (existente) {
        setDatos({
          ...datos,
          nombre: existente.name,
          apellidos: existente.last_name,
          correo: existente.email,
          rfc: existente.rfc,
          compañia: existente.company,
        });
      } else {
        setDatos({
          ...datos,
          nombre: "",
          apellidos: "",
          correo: "",
          rfc: "",
          Compañia: "",
        });
      }
    } else {
      setDatos({
        ...datos,
        nombre: "",
        apellidos: "",
        correo: "",
        rfc: "",
        Compañia: "",
      });
    }
  };

  function handleInputChangen(event, value) {
    setDatosNotificados({
      ...datosNotificados,
      correon: value,
    });
  }

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Typography
            variant="h6"
            gutterBottom
            style={{ textTransform: "none" }}
          >
            Datos de Firmante
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          {firmantesPrevios ? (
            <Autocomplete
              ref={myRef}
              required
              id="correo"
              name="correo"
              freeSolo
              options={datosFirmantesPrevios}
              getOptionLabel={(option) => option.email}
              onInputChange={handleInputChange}
              onChange={onChange}
              onBlur={() => {
                if (datos.correo) {
                  validarMail(datos.correo);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Correo electrónico" />
              )}
            />
          ) : (
            <TextField
              required
              id="correo"
              name="correo"
              label="Correo electrónico"
              value={datos.correo}
              fullWidth
              autoComplete="email"
              onChange={handleInput}
              onBlur={() => {
                if (datos.correo) {
                  validarMail(datos.correo);
                }
              }}
              inputProps={{ maxLength: 60 }}
            />
          )}
          <font color="red">{textoValidacionMail}</font>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="nombre"
            name="nombre"
            label="Nombre"
            value={datos.nombre}
            fullWidth
            autoComplete="given-name"
            onChange={handleInput}
            inputProps={{ maxLength: 30 }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="apellidos"
            name="apellidos"
            label="Apellidos"
            value={datos.apellidos}
            fullWidth
            autoComplete="family-name"
            onChange={handleInput}
            inputProps={{ maxLength: 60 }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="rfc"
            name="rfc"
            label="RFC"
            value={datos.rfc}
            fullWidth
            onChange={handleInput}
            //helperText={alertRFC}
            inputProps={{ maxLength: 13 }}
          />
          <FormHelperText style={{ color: "red" }}>{alertRFC}</FormHelperText>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="compañia"
            name="compañia"
            label="Compañía"
            value={datos.compañia}
            fullWidth
            onChange={handleInput}
            inputProps={{ maxLength: 60 }}
          />
        </Grid>
      </Grid>
      <Grid>
        <font size="1" color="gray">
          <b>
            *Las notificaciones del proceso de firma avanzada se realizan por
            correo electrónico.
          </b>
        </font>
      </Grid>
      <br />
      <Grid item xs={12} sm={12}>
        {
          //listaFirmantes.length > 0 ? (
          false ? (
            <React.Fragment>
              <Button
                variant="contained"
                className={classes.buttons}
                onClick={verFirmantes}
                style={{ backgroundColor: "#41b6e6", color: "white" }}
              >
                <Typography variant="button">
                  Firmantes registrados: {listaFirmantes.length}
                </Typography>
              </Button>
              <Button
                variant="contained"
                className={classes.buttons}
                onClick={verNotificados}
                style={{ backgroundColor: "#41b6e6", color: "white" }}
              >
                <Typography variant="button" style={{ textTransform: "none" }}>
                  NOTIFICADOS: {listaNotificados.length}
                </Typography>
              </Button>
            </React.Fragment>
          ) : null
        }

        <Button
          variant="contained"
          //color="primary"
          className={classes.buttons}
          onClick={verNotificados}
          style={{
            backgroundColor: "#41b6e6",
            color: "white",
            marginLeft: "10px",
          }}
        >
          <Typography variant="overline" style={{ textTransform: "none" }}>
            NOTIFICADOS
          </Typography>
        </Button>

        <React.Fragment>
          <Button
            variant="contained"
            color="primary"
            className={
              listaFirmantes.length > 0 ? classes.buttons : classes.buttons2
            }
            onClick={handleClick}
            disabled={disableform}
          >
            <Typography variant="overline" style={{ textTransform: "none" }}>
              {agregarTitulo}
            </Typography>
          </Button>
        </React.Fragment>
      </Grid>
      <br />
      <br />
      <MaterialTable
        localization={localization}
        options={{
          exportButton: false,
          search: false,
          headerStyle: {
            backgroundColor: "#009cde",
            color: "#FFF",
          },
          pageSize: 5, // make initial page size
          pageSizeOptions: [5, 10, 20, 50],
          actionsColumnIndex: -1,
        }}
        columns={[
          { title: "NOMBRE Y APELLIDOS", field: "nombre" },
          { title: "CORREO", field: "email" },
          { title: "RFC", field: "rfc" },
        ]}
        data={listaFirmantes}
        title={"Firmantes"}
        actions={[
          {
            icon: () => (
              <DeleteForeverIcon
                className={classes2.tableActionButtonIcon + " " + classes2.edit}
              />
            ),
            tooltip: "Eliminar firmante",
            onClick: (event, rowData) => {
              eliminarFirmanteCDO(rowData._id);
              //eliminarFirmanteCC(props.contratoCC[1]._id, rowData.email);
              eliminarFirmanteCC(props.contratoCC[1]._id, rowData.rfc);
            },
          },
        ]}
      />
      {/* MODAL FIRMANTES */}
      <Dialog
        open={modal}
        transition={Transition}
        keepMounted
        fullScreen={false}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => setModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        ></DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <div>
            <MaterialTable
              localization={localization}
              options={{
                exportButton: false,
                search: false,
                headerStyle: {
                  backgroundColor: "#009cde",
                  color: "#FFF",
                },
                pageSize: 5, // make initial page size
                pageSizeOptions: [5, 10, 20, 50],
                actionsColumnIndex: -1,
              }}
              columns={[
                { title: "NOMBRE Y APELLIDOS", field: "nombre" },
                { title: "CORREO", field: "email" },
                { title: "RFC", field: "rfc" },
              ]}
              data={listaFirmantes}
              title={"Firmantes"}
              actions={[
                {
                  icon: () => (
                    <DeleteForeverIcon
                      className={
                        classes2.tableActionButtonIcon + " " + classes2.edit
                      }
                    />
                  ),
                  tooltip: "Eliminar firmante",
                  onClick: (event, rowData) => {
                    eliminarFirmanteCDO(rowData._id);
                    eliminarFirmanteCC(props.contratoCC[1]._id, rowData.email);
                  },
                },
              ]}
            />
          </div>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button onClick={() => setModal(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL NOTIFICADOS */}
      <Dialog
        open={modalNotificados}
        transition={Transition}
        keepMounted
        fullScreen={false}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => setModalNotificados(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        ></DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" gutterBottom>
                  Notificados
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                {notificadosPrevios ? (
                  <Autocomplete
                    ref={myRefn}
                    required
                    id="correo"
                    name="correo"
                    freeSolo
                    options={datosNotificadosFrecuentes}
                    getOptionLabel={(option) => option.email}
                    onInputChange={handleInputChangen}
                    onChange={onChangen}
                    onBlur={() => {
                      if (datosNotificados.correon) {
                        validarMail(datosNotificados.correon);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Correo electrónico" />
                    )}
                  />
                ) : (
                  <TextField
                    required
                    id="correon"
                    name="correon"
                    label="Correo electrónico"
                    value={datosNotificados.correon}
                    fullWidth
                    autoComplete="email"
                    onChange={handleInputNotificados}
                    inputProps={{ maxLength: 60 }}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="nombren"
                  name="nombren"
                  label="Nombre"
                  value={datosNotificados.nombren}
                  fullWidth
                  autoComplete="given-name"
                  onChange={handleInputNotificados}
                  inputProps={{ maxLength: 30 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="apellidosn"
                  name="apellidosn"
                  label="Apellidos"
                  value={datosNotificados.apellidosn}
                  fullWidth
                  autoComplete="family-name"
                  onChange={handleInputNotificados}
                  inputProps={{ maxLength: 60 }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#41b6e6",
                  color: "white",
                  float: "right",
                }}
                onClick={handleClickNotificados}
                disabled={disableformnotificados}
              >
                <Typography variant="button" style={{ textTransform: "none" }}>
                  Agregar
                </Typography>
              </Button>
            </Grid>
            <br />
            <br />
            <MaterialTable
              localization={localization}
              options={{
                exportButton: false,
                search: false,
                headerStyle: {
                  backgroundColor: "#009cde",
                  color: "#FFF",
                },
                pageSize: 5, // make initial page size
                pageSizeOptions: [5, 10, 20, 50],
                actionsColumnIndex: -1,
              }}
              columns={[
                { title: "NOMBRE Y APELLIDOS", field: "nombre" },
                { title: "CORREO", field: "email" },
              ]}
              data={listaNotificados}
              title={""}
              actions={[
                {
                  icon: () => (
                    <DeleteForeverIcon
                      className={
                        classes2.tableActionButtonIcon + " " + classes2.edit
                      }
                    />
                  ),
                  tooltip: "Eliminar notificado",
                  onClick: (event, rowData) => {
                    const URL =
                      "https://api.certificaciondigital.online/api/contrato/notificador/" +
                      rowData._id +
                      "/delete";

                    var config = {
                      method: "get",
                      url: PROXY_URL + URL,
                      headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: "Basic " + token,
                      },
                    };
                    axios(config)
                      .then(function (response) {
                        console.log("Eliminado:", response.data);
                        actualizarNotificados();
                      })
                      .catch(function (error) {
                        console.log(error);
                      });
                  },
                },
              ]}
            />
          </div>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button onClick={() => setModalNotificados(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        place="tc"
        color="danger"
        icon={ErrorIcon}
        message={messageError}
        open={openSnack}
        closeNotification={handleCloseSnack}
        close
      />
    </React.Fragment>
  );
}
