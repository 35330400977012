import React from "react";
import axios from "axios";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import SearchIcon from '@material-ui/icons/Search';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Typography from "@material-ui/core/Typography";
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import FolderIcon from '@material-ui/icons/Folder';
import DescriptionIcon from '@material-ui/icons/Description';
import PersonIcon from '@material-ui/icons/Person';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import GroupIcon from '@material-ui/icons/Group';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import Tooltip from '@material-ui/core/Tooltip';
import AppBar from '@material-ui/core/AppBar';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  botondanger: {
    backgroundColor: "red",
    color: "white"
  },
  botongreen: {
    backgroundColor: "green",
    color: "white"
  },
  botonwhite: {
    backgroundColor: "white",
    color: "gray"
  },
  iconsmall: {
    fontSize: 16,
    margin: 2,
    marginTop: 11, //15 center
    padding: 0,
  },
  nombrecatalogo: {
    fontSize: 16,
    marginTop: 7,
    marginLeft: 10,
  },
  nombrearchivo: {
    marginTop: 6,
    fontSize: 14,
  },
  iconarchivo: {
    marginTop: 0,
    fontSize: 10,
  },
  paper: {
    width: "95%",
  },
  colapse: {
    marginLeft: 20
  },
  formControl: {
    margin: theme.spacing(1),
    backgroundColor: "white",
    //minWidth: 120,
    width: "100%"
  },

}));

export default function Grupos() {
  const classes = useStyles();
  const [grupos, setGrupos] = React.useState("");
  const [modalNuevo, setModalNuevo] = React.useState(false);
  const [nombreGrupo, setNombreGrupo] = React.useState("");
  const [grupoModificar, setGrupoModificar] = React.useState("");
  const [nombreGrupoModificar, setNombreGrupoModificar] = React.useState("");
  const [modalModificar, setModalModificar] = React.useState(false);
  const [modalEliminar, setModalEliminar] = React.useState(false);
  const [grupoEliminar, setGrupoEliminar] = React.useState("");
  const [usuarios, setUsuarios] = React.useState("");
  const [modalAgregar, setModalAgregar] = React.useState(false);
  const [idUsuario, setIdUsuario] = React.useState("");
  const [usuarioEliminar, setUsuarioEliminar] = React.useState("");
  const [modalEliminarUsuario, setModalEliminarUsuario] = React.useState(false);
  const [agregarSiguiente, setAgregarSiguiente] = React.useState(false);
  const [usuariosFiltrados, setUsuariosFiltrados] = React.useState("");
  const [order, setOrder] = React.useState("");

  const [openMensaje, setOpenMensaje] = React.useState(false);
  const [mensaje, setMensaje] = React.useState(false);
  const [severity, setSeverity] = React.useState("info");
  const handleCloseMensaje = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenMensaje(false);
  };

  function alerta(texto, severidad) {
    setOpenMensaje(true);
    setMensaje(texto);
    setSeverity(severidad);
  }

  function ListItemsTreeView() {
    return (
      <div>
        {grupos.map((grupo) => (
          <React.Fragment key={grupo._id}>
            <TreeView
              //key={grupo._id}
              className={classes.root}
              defaultCollapseIcon={<PeopleOutlineIcon color="primary" className={classes.colapse} />}
              defaultExpandIcon={<GroupIcon color="primary" className={classes.colapse} />}
            >
              <TreeItem nodeId="0"
                label={
                  <Grid container>
                    <Grid item xs={10}>
                      <Typography className={classes.nombrecatalogo}>
                        {grupo.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Tooltip title="Agregar o eliminar usuarios del grupo" placement="top">
                        <GroupAddIcon
                          className={classes.iconsmall}
                          color="primary"
                          onClick={() => {
                            setGrupoModificar(grupo);
                            //mostrar solo usuarios que no esten en el grupo

                            var filtrados = [];
                            for (var i = 0; i < usuarios.length; i++) {
                              let encontrado = false;
                              for (var j = 0; j < grupo.members.length; j++) {
                                if (usuarios[i].id == grupo.members[j].user.id) {
                                  encontrado = true;
                                }
                              }
                              if (encontrado == false) {
                                filtrados = [...filtrados, usuarios[i]]
                              }
                            }

                            setUsuariosFiltrados(filtrados);
                            setModalAgregar(true);
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Editar grupo" placement="top">
                        <EditIcon
                          className={classes.iconsmall}
                          color="primary"
                          onClick={() => {
                            setGrupoModificar(grupo);
                            setNombreGrupoModificar(grupo.name);
                            setModalModificar(true);
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Eliminar grupo" placement="top">
                        <DeleteIcon
                          className={classes.iconsmall}
                          color="primary"
                          onClick={() => {
                            setGrupoEliminar(grupo);
                            setModalEliminar(true);
                          }}
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                }
                className={classes.root}
              >
                {grupo.members && grupo.members.map((item, key) => (
                  <TreeItem nodeId={item._id} key={key} label={
                    <Grid container>
                      <Grid item>
                        <PersonIcon
                          className={classes.iconarchivo}
                        //color="primary"
                        />
                      </Grid>
                      <Grid item>
                        <Typography className={classes.nombrearchivo}>
                          {item.user ? item.user.name : null}
                        </Typography>
                      </Grid>
                    </Grid>
                  } />
                ))}
              </TreeItem>
            </TreeView>
            <hr />
          </React.Fragment>
        ))}
      </div>
    );
  };

  async function getGrupos() {
    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_URL}api/workgroups`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    };
    axios(config)
      .then(function (response) {
        console.log("Grupos:", response.data);
        setGrupos(response.data);
      })
      .catch(function (error) {
        console.log(error);
        setGrupos("");
      });
  }

  async function getUsuarios() {
    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_URL}api/users`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    };
    axios(config)
      .then(function (response) {
        console.log("usuarios:", response.data);
        setUsuarios(response.data);
      })
      .catch(function (error) {
        console.log(error);
        setUsuarios("");
      });
  }

  async function handleGuardarNuevo() {
    var dataForm = new FormData();
    dataForm.append('name', nombreGrupo);

    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_URL}api/workgroups`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: dataForm
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        setModalNuevo(false);
        getGrupos();
        alerta("Nuevo grupo creado", "info")
        setNombreGrupo("");
      })
      .catch(function (error) {
        console.log(error);
        alerta("Error al crear grupo", "console.error();")
      });

  }

  async function handleModificar() {
    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: 'put',
      url: `${process.env.REACT_APP_URL}api/workgroups/${grupoModificar._id}?name=${nombreGrupoModificar}`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        setModalModificar(false);
        getGrupos();
        alerta("Grupo modificado", "info")
      })
      .catch(function (error) {
        console.log(error);
        alerta("Error al modificar", "warning")
      });

  }

  function handleEliminar() {
    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: 'delete',
      url: `${process.env.REACT_APP_URL}api/workgroups/${grupoEliminar._id}`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    };
    axios(config)
      .then(function (response) {
        console.log("Grupo Eliminado:", response.data);
        getGrupos();
        setModalEliminar(false);
        alerta("El grupo ha sido eliminado", "warning")
      })
      .catch(function (error) {
        console.log(error);
        alerta("Error al eliminar grupo", "error")
      });
  }

  function handleEliminarUsuario() {
    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: 'delete',
      url: `${process.env.REACT_APP_URL}api/members/${usuarioEliminar._id}`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    };
    axios(config)
      .then(function (response) {
        console.log("Usuario Eliminado:", response.data);
        getGrupos();
        setGrupoModificar(response.data);
        setModalEliminarUsuario(false);

        //volver a filtrar
        let g = response.data;
        var filtrados = [];
        for (var i = 0; i < usuarios.length; i++) {
          let encontrado = false;
          for (var j = 0; j < g.members.length; j++) {
            if (usuarios[i].id == g.members[j].user.id) {
              encontrado = true;
            }
          }
          if (encontrado == false) {
            filtrados = [...filtrados, usuarios[i]]
          }
        }
        setUsuariosFiltrados(filtrados);
        alerta("El usuario ha sido eliminado", "warning")
      })
      .catch(function (error) {
        console.log(error);
        alerta("Error eliminando usuario", "error")
      });
  }

  async function handleAgregarUsuario() {
    var dataForm = new FormData();
    dataForm.append('workgroup_id', grupoModificar._id);
    dataForm.append('uid', idUsuario);

    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_URL}api/members`,
      headers: {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: dataForm
    };

    axios(config)
      .then(function (response) {
        console.log("respuesta:", response.data);
        getGrupos();
        setGrupoModificar(response.data);
        //volver a filtrar
        let g = response.data;
        var filtrados = [];
        for (var i = 0; i < usuarios.length; i++) {
          let encontrado = false;
          for (var j = 0; j < g.members.length; j++) {
            if (usuarios[i].id == g.members[j].user.id) {
              encontrado = true;
            }
          }
          if (encontrado == false) {
            filtrados = [...filtrados, usuarios[i]]
          }
        }
        setUsuariosFiltrados(filtrados);
        alerta("Usuario agregado", "info")
        setIdUsuario("");
      })
      .catch(function (error) {
        console.log(error);
        alerta("Error al agregar usuario", "warning");
      });

  }

  function handleOrdenar() {
    if (order == "" || order == "des") {
      setOrder("asc");
      grupos.sort(function (a, b) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    }
    else {
      setOrder("des");
      grupos.sort(function (a, b) {
        if (a.name < b.name) {
          return 1;
        }
        if (a.name > b.name) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    }
  }

  React.useEffect(() => {
    getGrupos();
    getUsuarios();
  }, []);

  let disabledNuevo = true;
  if (nombreGrupo != "") {
    disabledNuevo = false;
  }
  let disabledModificar = true;
  if (nombreGrupoModificar != "") {
    disabledModificar = false;
  }

  let disabledAgregar = true;
  if (idUsuario != "") {
    disabledAgregar = false;
  }

  return (
    <React.Fragment>
      <Grid container justify="center">
        <Paper elevation={3} className={classes.paper}>
          {/*BOTONES SUPERIORES*/}
          <IconButton
            aria-label="Add"
            color="primary"
            onClick={() => {
              setModalNuevo(true);
            }}
          >
            <Tooltip title="Crear nuevo grupo" placement="top">
              <AddCircleIcon />
            </Tooltip>
          </IconButton>

          <IconButton
            aria-label="Add"
            color="primary"
            onClick={() => {
              handleOrdenar();
            }}
          >
            <Tooltip title="Ordenar" placement="top">
              <SortByAlphaIcon />
            </Tooltip>
          </IconButton>

          {/*
      <IconButton aria-label="Add" color="primary">
        <Tooltip title="Buscar catálogo" placement="top">
          <SearchIcon />
        </Tooltip>
      </IconButton>
      */}
          {/*FIN BOTONES SUPERIORES*/}

          {/*LISTADO DE CATALOGOS*/}
          <AppBar position="static">
            <b><font style={{ margin: 10 }}>Grupos</font></b>
          </AppBar>
          <div style={{ background: "white" }}>
            {grupos ? (
              <ListItemsTreeView />
            ) : null}
          </div>
          {/*FIN LISTADO DE GRUPOS*/}
        </Paper>
      </Grid>

      {/*DIALOGO NUEVO GRUPO*/}
      <Dialog
        open={modalNuevo}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6" style={{ textTransform: "none" }}>
            Por favor ingresar datos:
          </Typography>
          <TextField
            autoFocus
            required
            label="Nombre del grupo"
            margin="dense"
            id="name"
            //label=""
            variant="outlined"
            fullWidth
            value={nombreGrupo}
            onChange={(e) => {
              setNombreGrupo(e.target.value)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setModalNuevo(false)
            }}
            className={classes.botondanger}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            disabled={disabledNuevo}
            onClick={() => {
              handleGuardarNuevo();
            }}
            className={classes.botongreen}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      {/* FIN NUEVO GRUPO*/}

      {/*DIALOGO MODIFICAR GRUPO*/}
      <Dialog
        open={modalModificar}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6" style={{ textTransform: "none" }}>
            Por favor ingresar nuevo nombre:
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            //label="Nombre del catálogo"
            variant="outlined"
            fullWidth
            value={nombreGrupoModificar}
            onChange={(e) => {
              setNombreGrupoModificar(e.target.value)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            //size="md"
            //type="button"
            onClick={() => {
              setModalModificar(false)
            }}
          //className={classes.botondanger}
          >
            Cerrar
          </Button>
          <Button
            variant="contained"
            disabled={disabledModificar}
            onClick={() => {
              handleModificar();
            }}
            className={classes.botongreen}
          >
            Modificar
          </Button>
        </DialogActions>
      </Dialog>
      {/* FIN MODIFICAR GRUPO*/}

      {/*DIALOGO ELIMINAR GRUPO*/}
      <Dialog
        open={modalEliminar}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6" style={{ textTransform: "none" }}>
            ¿Está seguro de eliminar el grupo?<br /> <b>{grupoEliminar.name}</b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setModalEliminar(false)
            }}
          //className={classes.botondanger}
          >
            Cerrar
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleEliminar();
            }}
            className={classes.botondanger}
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      {/* FIN ELIMINAR GRUPO*/}

      {/*DIALOGO AGREGAR USUARIOS*/}
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={modalAgregar}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {grupoModificar.name}
        </DialogTitle>
        <DialogContent>
          {usuariosFiltrados.length > 0 ?
            (
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-helper-label">Seleccione usuario *</InputLabel>
                {/*JSON.stringify(usuariosFiltrados)*/}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={idUsuario}
                  onChange={(e) => {
                    setIdUsuario(e.target.value)
                  }}
                >
                  {usuariosFiltrados.map((item, key) => (
                    <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}


          {grupoModificar.members ? grupoModificar.members.map((item, key) => (
            <Grid container key={key}>
              <Grid item xs={10}>
                <li>{item.user.name}
                  {/*
          </Grid>
          <Grid item xs={2}>
          */}
                  <Tooltip title="Eliminar usuario" placement="top">
                    <DeleteIcon
                      className={classes.iconsmall}
                      color="primary"
                      onClick={() => {
                        //console.log(item.user.name)
                        setUsuarioEliminar(item);
                        setModalEliminarUsuario(true);
                      }}
                    />
                  </Tooltip>
                </li>
              </Grid>
            </Grid>
          )) : null}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setModalAgregar(false);
              setAgregarSiguiente(false);
              setIdUsuario("");
            }}
          //className={classes.botondanger}
          >
            Cerrar
          </Button>
          <Button
            variant="contained"
            disabled={disabledAgregar}
            onClick={() => {
              setAgregarSiguiente(true);
              handleAgregarUsuario();
            }}
            className={classes.botongreen}
          >
            {agregarSiguiente ? "Agregar otro" : "Agregar"}
          </Button>
        </DialogActions>
      </Dialog>
      {/* FIN AGREGAR USUARIOS*/}

      {/*DIALOGO ELIMINAR USUARIO*/}
      <Dialog
        open={modalEliminarUsuario}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6" style={{ textTransform: "none" }}>
            ¿Está seguro de eliminar el usuario?<br /> <b>{usuarioEliminar.user ? usuarioEliminar.user.name : ""}</b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setModalEliminarUsuario(false)
            }}
          //className={classes.botondanger}
          >
            Cerrar
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleEliminarUsuario();
            }}
            className={classes.botondanger}
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      {/* FIN ELIMINAR USUARIO*/}

      {/*MENSAJE*/}
      <Snackbar
        open={openMensaje}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={handleCloseMensaje}>
        <Alert onClose={handleCloseMensaje} severity={severity}>
          {mensaje}
        </Alert>
      </Snackbar>
      {/*FIN MENSAJE*/}

    </React.Fragment>
  )
}
