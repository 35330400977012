import React from "react";
import Card from "components/Card/Card.js";
import Grid from "@material-ui/core/Grid";
import GridItem from "components/Grid/GridItem.js";

export default function NcwPermissionCard(props) {
    const { data: { id, title, description, icon }, selected, disabled } = props;
    const primaryColor = '#3f51b5';

    return (
        <a href="#" onClick={props.onClick} style={{ pointerEvents: disabled ? 'none' : undefined }}>
            <Card type="button" style={{
                paddingTop: "1rem",
                paddingBottom: "1rem",
                marginTop: "0px",
                alignItems: 'center',
                justifyContent: 'center',
                height: "7rem",
                width: "100%",
                border: selected ? "2px solid " + primaryColor : undefined,
                opacity: disabled ? 0.7 : undefined,
            }}>
                <Grid container>
                    <GridItem xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <props.data.icon fontSize={'large'} color={selected ? 'primary' : undefined} />
                    </GridItem>
                    <GridItem xs={10}>
                        <b style={{ display: 'block', marginBottom: '0px', color: selected ? primaryColor : undefined }}>{title}</b>
                        <span style={{ fontSize: '0.8rem', lineHeight: '1rem', display: 'block', color: selected ? primaryColor : undefined }}>{description}</span>
                    </GridItem>
                </Grid>
            </Card>
        </a>
    );
}