import React from "react";
import PropTypes from "prop-types";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const MySnackbar = ({
  openAlert,
  onClose,
  error,
  message,
  severity, // error, warning,info, success
  duration,
}) => {
  const isError = () => {
    if (error) {
      if (error.response.data.errors) {
        let errors = error.response.data.errors;
        let errores = [];
        let data = "";

        for (let clave in errors) {
          errores += errors[clave];
          errores += ",";
        }

        data = errores.split(",");

        var msj = data.map((item, index) => {
          if (item != "") {
            return <li key={index}>{item}</li>;
          }
        });
        return <ul>{msj}</ul>;
      } else {
        return error.response.data.message;
      }
    } else {
      return "Informacion Incorrecta";
    }
  };
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAlert}
        autoHideDuration={duration ? duration : 10000}
        onClose={onClose}
      >
        <Alert
          severity={error ? "error" : severity ? severity : "error"}
          onClose={onClose}
        >
          {error ? isError() : `${message}`}
        </Alert>
      </Snackbar>
    </div>
  );
};

MySnackbar.propTypes = {
  openAlert: PropTypes.bool,
  onClose: PropTypes.func,
  error: PropTypes.element,
  message: PropTypes.string,
  severity: PropTypes.string,
  duration: PropTypes.number,
};
