import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import axios from "axios";
//import IconButton from "@material-ui/core/IconButton";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { green } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import { useSelector } from "react-redux";
import ErrorIcon from "@material-ui/icons/Error";
import Snackbar from "components/Snackbar/Snackbar.js";

//LEMP
import Autocomplete from "@material-ui/lab/Autocomplete";
import UbicarFirma from "./ubicarFirma";
import Notificados from "./Notificados";
//LEMP
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: 5,
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function AddressForm(props) {
  const ref = React.createRef();
  function goTop() {
    console.log("REF");
    ref.current.scrollIntoView();
  }
  const history = useHistory();
  const [tokenstate, setTokenstate] = React.useState("");
  //data firmantes
  const [datosFirmantes, setDatosFirmantes] = React.useState([{}]);
  const [firmantesPrevios, setFirmantesPrevios] = React.useState(false);
  const [tiposActuacion, setTiposActuacion] = React.useState("");
  const [tipoActuacion, setTipoActuacion] = React.useState({
    codigo: 1,
    valor: "Por su Propio Derecho",
  });
  const [estiloCelular, setEstiloCelular] = React.useState("gray");
  const [modalNotificados, setModalNotificados] = React.useState(false);
  const [textoValidacionMail, setTextoValidacionMail] = React.useState("");

  function validarMail(email) {
    let tokenSG =
      "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";
    var config = {
      async: true,
      crossDomain: true,
      method: "post",
      url: `https://api.sendgrid.com/v3/validations/email`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + tokenSG,
      },
      processData: false,
      data: `{\"email\":\"${email}\",\"source\":\"signup\"}`,
    };
    axios(config)
      .then(function (response) {
        console.log("Email Validation:", response);
        if (response.data.result.verdict === "Invalid") {
          setTextoValidacionMail("Correo no valido");
        } else {
          setTextoValidacionMail("");
        }
        //verdict: "Invalid"
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  React.useEffect(() => {
    goTop();
    getTiposActuacion();
    let token = sessionStorage.getItem("Token");
    setTokenstate(token);
    // console.log(token);
    //LEMP
    //busca los firmantes del usuario en curso
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/firmantes`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        //se filtra la respuesta eliminado duplicados
        let arr = response.data;
        if (arr) {
          let pp = arr.filter(
            (ele, ind) =>
              ind === arr.findIndex((elem) => elem.email === ele.email)
          );
          console.log(pp);
          setDatosFirmantes(pp);
          setFirmantesPrevios(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  //LEMP TIPOS DE ACTUACIÓN
  function getTiposActuacion() {
    const token = sessionStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/firmantes/involvement/types`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("Tipos:", response.data);
        setTiposActuacion(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleChangeTipoActuacion = (event) => {
    var t = [];
    Object.keys(tiposActuacion).forEach(function (n, i) {
      t[i] = {
        codigo: n,
        valor: tiposActuacion[n],
      };
    });
    const tipo = t.find((ta) => ta.codigo === event.target.value);
    setTipoActuacion(tipo);
  };

  function SelectTipos() {
    var t = [];
    Object.keys(tiposActuacion).forEach(function (n, i) {
      t[i] = {
        codigo: n,
        valor: tiposActuacion[n],
      };
    });
    console.log("Tipos", t);
    return (
      <React.Fragment>
        <InputLabel id="demo-simple-select-label">Tipo de Actuación</InputLabel>
        <Select
          labelId="tipoactuacion"
          id="tipoactuacion"
          value={tipoActuacion.codigo}
          onChange={handleChangeTipoActuacion}
        >
          {t.map(({ codigo, valor }, index) => (
            <MenuItem key={index} value={codigo}>
              {valor}
            </MenuItem>
          ))}
        </Select>
      </React.Fragment>
    );
  }
  /*LEMP*/
  /*arreglo para almacenar el objeto coordenadas*/
  const [coordenadasFirma, setCoordenadasFirma] = React.useState([{}]);
  /*indicador para saber si estoy cargando un nuevo firmante*/
  const [nuevoFirmante, setNuevoFirmante] = React.useState(true);
  /*LEMP*/

  const classes = useStyles();
  const { idDocumento, senFirmante, handleDelete, idRequired } = props;
  const [value, setValue] = useState("1");
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [messageError, setMessageError] = React.useState("");
  const [openAlert, setOpenAlert] = React.useState("");
  const [numFirmante, setNumFirmante] = React.useState(1);
  const timer = React.useRef();
  const [datos, setDatos] = useState({
    nombre: "",
    apellidos: "",
    correo: "",
    codigo: "52",
    celular: "",
    rol: "",
    Compañia: "",
  });

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  function handleInputChange(event, value) {
    //console.log(value);
    setDatos({
      ...datos,
      correo: value,
    });
  }

  function b(email) {
    for (var i = datosFirmantes.length; i--;) {
      if (datosFirmantes[i].email === email) {
        return datosFirmantes[i];
      }
    }
    return null;
  }

  const onChange = (event, value) => {
    //      console.log("change");
    //      console.log(value.email);
    if (value) {
      var existente = b(value.email);
      console.log("existente", existente);

      if (existente) {
        //validación temporal teléfono mientras se envía código en campo separado
        var codigo;
        var tlf;
        if (existente.phone) {
          if (existente.phone === "") {
            codigo = "52";
          } else {
            if (existente.phone.substring(0, 1) === "1") {
              codigo = "1";
              tlf = existente.phone.slice(1);
            } else {
              codigo = "52";
              if (existente.phone.substring(0, 2) === "52") {
                tlf = existente.phone.slice(2);
              } else {
                tlf = existente.phone;
              }
            }
          }
          console.log("cel:", existente.phone.substring(0, 1));
          console.log("codigo:", codigo);
          console.log("Tlf:", tlf);
        } else {
          codigo = "52";
        }

        //fin validar teléfono
        setDatos({
          ...datos,
          nombre: existente.name,
          apellidos: existente.last_name,
          correo: existente.email,
          codigo: codigo,
          celular: tlf,
          rol: existente.role,
          Compañia: existente.company,
        });
      } else {
        setDatos({
          ...datos,
          nombre: "",
          apellidos: "",
          correo: "",
          codigo: "52",
          celular: "",
          rol: "",
          Compañia: "",
        });
      }
    } else {
      setDatos({
        ...datos,
        nombre: "",
        apellidos: "",
        correo: "",
        codigo: "52",
        celular: "",
        rol: "",
        Compañia: "",
      });
    }
  };

  const handleChange = async (event) => {
    await setValue(event.target.value);
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenstate}`,
      Accept: " application/json",
    },
  };

  var areYouReallySure = false;
  function areYouSure() {
    if (allowPrompt) {
      if (!areYouReallySure && true) {
        areYouReallySure = true;
        var confMessage = "Por favor termine de registrar el firmante ";

        return confMessage;
      }
    } else {
      allowPrompt = true;
    }
  }

  var allowPrompt = true;

  window.onbeforeunload = areYouSure;

  const handleGoBackHome = () => {
    let uri = localStorage.getItem("uri");

    history.push(`/${uri}/dashboard`);
  };
  const registerFirmante = async () => {
    goTop();
    // NUEVO LEMP usando JSON
    //manda a firmar
    setNuevoFirmante(true);
    const json = JSON.stringify(coordenadasFirma);
    var rol = datos.rol;
    if (rol === "") {
      rol = tipoActuacion.valor;
    }
    const data = `{\n    "documentos_id": "${idDocumento}",\n    "name": "${datos.nombre}",\n    "last_name": "${datos.apellidos}",\n    "email": "${datos.correo}",\n    "phone_country_code": "${datos.codigo}",\n    "phone": "${datos.celular}",\n    "role": "${rol}",\n    "firmante_involvements_id": "${tipoActuacion.codigo}",\n    "company": "${datos.Compañia}",\n    "notification_types_id":${value},\n    "id_required":${idRequired},\n    "signature_coords": ${json}\n}`;

    // console.log("DATAbodyfirmante", data);
    let urlFirmante = `${process.env.REACT_APP_URL}api/firmantes`;

    axios
      .post(urlFirmante, data, config, {
        // receive two parameter endpoint url ,form data
      })
      .then((response) => {
        // Respuesta del servidor
        let data = response.data[0];
        let dataID = data.id;
        setTimeout(handleInitProcess, 3500);
        // console.log("respuest a data", data);
        // console.log("id data", idDocumento);
        senFirmante();
      })
      .catch((error) => {
        // Error 😨
        if (error.response.data.errors.email) {
          showNotificacion(error.response.data.errors.email);
        } else if (error.response.data.errors.phone) {
          showNotificacion(error.response.data.errors.phone);
        } else if (error.response.data.message) {
          showNotificacion(error.response.data.message);
        }
      });

    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 8000);
    }
  };

  const registerNewFirmante = async () => {
    //nuevo LEMP usando JSON
    //AGREGAR OTRO FIRMANTE
    goTop();
    setNuevoFirmante(true);
    const config = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${tokenstate}`,
        Accept: " application/json",
      },
    };
    var rol = datos.rol;
    if (rol === "") {
      rol = tipoActuacion.valor;
    }
    const json = JSON.stringify(coordenadasFirma);
    const data = `{\n    "documentos_id": "${idDocumento}",\n    "name": "${datos.nombre}",\n    "last_name": "${datos.apellidos}",\n    "email": "${datos.correo}",\n    "phone_country_code": "${datos.codigo}",\n    "phone": "${datos.celular}",\n    "role": "${rol}",\n    "firmante_involvements_id": "${tipoActuacion.codigo}",\n    "company": "${datos.Compañia}",\n    "notification_types_id":${value},\n    "id_required":${idRequired},\n    "signature_coords": ${json}\n}`;

    //    console.log("coordenadas y agregar otro firmante");
    //    console.log(coordenadasFirma);
    //console.log("DATAbodyfirmante", data);
    let urlFirmante = `${process.env.REACT_APP_URL}api/firmantes`;

    axios
      .post(urlFirmante, data, config)
      .then((response) => {
        setNumFirmante(numFirmante + 1);
        // Respuesta del servidor
        let data = response.data[0];
        let dataID = data.id;

        setDatos({
          ...datos,
          nombre: "",
          apellidos: "",
          correo: "",
          codigo: "52",
          celular: "",
          rol: "",
          Compañia: "",
        });
        setCoordenadasFirma([{}]);
      })
      .catch((error) => {
        // Error 😨
        if (error.response.data.errors.email) {
          showNotificacion(error.response.data.errors.email);
        } else if (error.response.data.errors.phone) {
          showNotificacion(error.response.data.errors.phone);
        } else if (error.response.data.message) {
          showNotificacion(error.response.data.message);
        }
      });

    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 8000);
    }
  };

  const showNotificacion = (message) => {
    setOpenAlert(true);
    setMessageError(message);
    setTimeout(function () {
      setOpenAlert(false);
    }, 6000);
  };

  const handleInitProcess = () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenstate}`,
        Accept: " application/json",
      },
    };

    const urlInit = `${process.env.REACT_APP_URL}api/documentos/${idDocumento}/launch`;
    axios.put(urlInit, {}, config).then((response) => {
      console.log("RES DE INIT", response);

      setTimeout(handleGoBackHome, 10500);
    });
  };

  const handleInput = (event) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
    // console.log("datos", datos);
    // console.log("radios", value);
  };

  let disableform = true;
  if (
    datos &&
    datos.nombre.length >= 3 &&
    datos.apellidos.length >= 3 &&
    datos.correo.length >= 5 &&
    //datos.celular.length >= 8 &&
    datos.Compañia.length >= 3 &&
    JSON.stringify(coordenadasFirma) !== "[{}]" &&
    loading === false
  ) {
    disableform = false;
  }
  return (
    <React.Fragment>
      <Grid container spacing={3} ref={ref}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h6" gutterBottom>
            Datos de firmante #{numFirmante}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          {firmantesPrevios ? (
            <Autocomplete
              required
              id="correo"
              name="correo"
              freeSolo
              options={datosFirmantes}
              getOptionLabel={(option) => option.email}
              onInputChange={handleInputChange}
              onChange={onChange}
              onBlur={() => {
                if (datos.correo) {
                  validarMail(datos.correo);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Correo electrónico" />
              )}
            />
          ) : (
            <React.Fragment>
              <TextField
                required
                id="correo"
                name="correo"
                label="Correo electrónico"
                value={datos.correo}
                fullWidth
                autoComplete="email"
                onChange={handleInput}
                onBlur={() => {
                  if (datos.correo) {
                    validarMail(datos.correo);
                  }
                }}
                inputProps={{ maxLength: 60 }}
                error={datos.correo.length > 2 ? false : true}
              />
            </React.Fragment>
          )}
          <font color="red">{textoValidacionMail}</font>
          {/*
          <TextField
            required
            id="correo"
            name="correo"
            label="Correo electrónico"
            value={datos.correo}
            fullWidth
            autoComplete="email"
            onChange={handleInput}
            inputProps={{ maxLength: 60 }}
            error={datos.correo.length > 2 ? false : true}
          />
*/}
          {/*
          <Autocomplete
            required
            id="correo"
            name="correo"
            freeSolo
            options={datosFirmantes}
            getOptionLabel={(option) => option.email}
            onInputChange={handleInputChange}
            onChange={onChange}
            renderInput={(params) => (
              <TextField {...params} label="Correo electrónico" />
            )}
          />
*/}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="nombre"
            name="nombre"
            label="Nombre"
            value={datos.nombre}
            fullWidth
            autoComplete="given-name"
            onChange={handleInput}
            inputProps={{ maxLength: 30 }}
            error={datos.nombre.length > 1 ? false : true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="apellidos"
            name="apellidos"
            label="Apellidos"
            value={datos.apellidos}
            fullWidth
            autoComplete="family-name"
            onChange={handleInput}
            inputProps={{ maxLength: 60 }}
            error={datos.apellidos.length > 2 ? false : true}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <font color={estiloCelular} size="2">
            Código *
          </font>
          <PhoneInput
            countryCodeEditable={false}
            inputStyle={{ width: "110%" }}
            inputExtraProps={{
              name: "codigo",
              id: "codigo",
              required: true,
              autoFocus: true,
              readonly: true,
            }}
            readonly
            id="codigo"
            name="codigo"
            label="codigo"
            onlyCountries={["us", "mx"]}
            localization={{
              "United States": "Estados Unidos",
              Mexico: "México",
            }}
            country={"mx"}
            value={datos.codigo}
            onChange={(phone) => {
              setEstiloCelular("gray");
              setDatos({
                ...datos,
                codigo: phone,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            id="celular"
            name="celular"
            label="Celular"
            value={datos.celular}
            onChange={handleInput}
            inputProps={{ maxLength: 15 }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="Compañia"
            name="Compañia"
            label="Compañía"
            value={datos.Compañia}
            fullWidth
            onChange={handleInput}
            inputProps={{ maxLength: 60 }}
            error={datos.Compañia.length > 2 ? false : true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectTipos />
        </Grid>
        <Grid item xs={12} sm={6}>
          {
            tipoActuacion.codigo === "5" ? (
              <TextField
                required
                id="rol"
                name="rol"
                label="Tipo de actuación"
                value={datos.rol}
                fullWidth
                onChange={handleInput}
                inputProps={{ maxLength: 60 }}
                error={datos.rol.length > 2 ? false : true}
              />
            ) : null
            /*
        <TextField
          required
          id="rol"
          name="rol"
          label="Tipo de actuación"
          value={datos.rol}
          fullWidth
          onChange={handleInput}
          inputProps={{ maxLength: 60 }}
          error={datos.rol.length > 2 ? false : true}
        />
        */
          }
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Envío de notificación</FormLabel>
            <RadioGroup
              aria-label="gender"
              name="radios"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel
                value="1"
                name="1"
                control={<Radio />}
                label="Correo"
              />
              <FormControlLabel
                value="2"
                name="2"
                control={<Radio />}
                label="Mensaje de texto"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {/*LEMP*/}
        <UbicarFirma
          idDocumento={idDocumento}
          nombre={datos.nombre}
          setCoordenadasFirma={setCoordenadasFirma}
          setNuevoFirmante={setNuevoFirmante}
          nuevoFirmante={nuevoFirmante}
        />
        {/*LEMP*/}
      </Grid>

      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
      >
        <Button
          variant="contained"
          color="primary"
          disabled={false}
          onClick={() => {
            console.log("clic notificados");
            setModalNotificados(true);
          }}
          className={buttonClassname}
        >
          Notificados
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={disableform}
          onClick={registerNewFirmante}
          className={buttonClassname}
        >
          Registrar otro firmante
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={disableform}
          onClick={registerFirmante}
          className={buttonClassname}
        >
          Enviar a firmar
        </Button>
      </Grid>
      <Grid>
        {loading && (
          <CircularProgress size={28} className={classes.buttonProgress} />
        )}
      </Grid>
      <Snackbar
        place="tc"
        color="danger"
        icon={ErrorIcon}
        message={messageError}
        open={openAlert}
        closeNotification={() => setOpenAlert(false)}
        close
      />
      {modalNotificados ? (
        <Notificados
          setModalNotificados={setModalNotificados}
          modalNotificados={modalNotificados}
          idDocumento={idDocumento}
        />
      ) : null}
    </React.Fragment>
  );
}
