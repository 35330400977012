import React from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";


export default function VistaPrevia(props) {

//pdf
const [base64, setbase64] = React.useState("");
const [numPages, setNumPages] = React.useState(null);
const [pageNumber, setPageNumber] = React.useState(1);
const [pdfHeigth, setpdfHeigth] = React.useState(475);
const [pdfWidth, setpdfWidth] = React.useState(550);
const [marginTop, setMarginTop] = React.useState(700);

function changePage(offset) {
  setPageNumber((prevPageNumber) => prevPageNumber + offset);
}

function previousPage() {
  changePage(-1);
}

function nextPage() {
  changePage(1);
}

function onDocumentLoadSuccess({ numPages }) {
  setNumPages(numPages);
  setPageNumber(1);
}

const isMobile = () => {
  return (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/BlackBerry/i)
  );
};
//pdf

  React.useEffect(() => {
    if (isMobile()) {
      setpdfHeigth(500);
      setpdfWidth(290);
      setMarginTop(400);
    }

    setbase64(props.pdf);

  }, [base64]);

  return (
    <React.Fragment>
    <div className="text-center">
      <p>
        Página {pageNumber || (numPages ? 1 : "--")} de {numPages || "--"}
      </p>
      <button
        className="btn btn-dark"
        type="button"
        disabled={pageNumber <= 1}
        onClick={previousPage}
      >
        Atrás
      </button>{" "}
      <button
        className="btn btn-dark"
        type="button"
        disabled={pageNumber >= numPages}
        onClick={nextPage}
      >
        Siguiente
      </button>
    </div>
    <hr />
    <div>
      <Document
        error="Error al intentar cargar el PDF"
        loading="Cargando..."
        file={base64}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page
          //size="LEGAL"
          scale={1}
          height={pdfHeigth}
          width={pdfWidth}
          renderTextLayer={false}
          pageNumber={pageNumber}
        />
      </Document>
    </div>
    </React.Fragment>
  )

}
