import React from "react";
import clsx from "clsx";
import axios from "axios";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import FolderIcon from "@material-ui/icons/Folder";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DescriptionIcon from "@material-ui/icons/Description";
import Backdrop from "@material-ui/core/Backdrop";
import { Grid } from "@material-ui/core";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import CircularProgress from "@material-ui/core/CircularProgress";
import ControlPanel from "components/controlPanel/ControlPanel";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FileCopyIcon from "@material-ui/icons/FileCopyOutlined";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DeleteIcon from "@material-ui/icons/Delete";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import ArchiveIcon from "@material-ui/icons/Archive";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HomeIcon from "@material-ui/icons/Home";
import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";

import EditIcon from "@material-ui/icons/Edit";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";

import { green } from "@material-ui/core/colors";
import { grayColor } from "assets/jss/material-dashboard-react.js";
import { DialogComp } from "components/Dialog/DialogComp";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { ClipPath } from "@react-pdf/renderer";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const drawerWidth = 200;
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStylesprogrees = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgress2: {
    color: green[500],
    alignItems: "center",
    top: "50%",
    left: "50%",
    marginTop: 12,
    marginLeft: 12,
  },

  root: {
    background:
      "  linear-gradient(to right top, #eee404, #f1e405, #f3e407, #f6e308, #f8e30a)",
    borderRadius: "3px",

    padding: "15px",
    marginTop: "-20px",
    marginRight: "15px",
    backgroundColor: grayColor[0],
    float: "left",
  },

  iconGrayColor: {
    backgroundColor: grayColor[0],
    borderRadius: "3px",

    padding: "15px",
    marginTop: "-20px",
    marginRight: "15px",
    float: "left",
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  botoninfo: {
    backgroundColor: "#00acc1",
    color: "white",
  },

  botondanger: {
    backgroundColor: "red",
    color: "white",
  },

  botongreen: {
    backgroundColor: "green",
    color: "white",
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(7),
    backgroundColor: green[500],
    right: theme.spacing(2),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",

    justifyContent: "flex-end",
    padding: theme.spacing(0, 0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    height: "400px",

    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));
const useStylestable = makeStyles(styles2);

export default function MiniDrawer(props) {
  const classes = useStyles();
  const classes2 = useStylestable();
  const { setSendFolder, idDocumento, dataCarpetas, } = props
  const classespro = useStylesprogrees();

  const [openSnack, setOpenSnack] = React.useState(false);
  const [messageError, setMessageError] = React.useState("");
  const [typeAlert, setTypeAlert] = React.useState("");
  const [idFather, setidFather] = React.useState("");
  const [idFolderActual, setIdFolderActual] = React.useState("");


  const theme = useTheme();
  const [dataFolder, setDAtaFolder] = React.useState([]);
  const [ItemDataok, setItemDataOk] = React.useState([
    <Typography paragraph>Seleccione una Carpeta</Typography>,
  ]);
  const [folder, setFolder] = React.useState("");

  const [patch, setPatch] = React.useState("");
  const [pdfBase64, setPdfBase64] = React.useState([]);
  const [openPDF, setOpenPDF] = React.useState(false);
  const [scale, setScale] = React.useState(1.3);
  const [numPages, setNumPages] = React.useState(null);

  const [pageNumber, setPageNumber] = React.useState(1);
  const [pdfHeigth, setpdfHeigth] = React.useState(575);
  const [pdfWidth, setpdfWidth] = React.useState(660);
  const [openCarpetas, setOpenCarpetas] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [openSpeed, setOpenSpeed] = React.useState(false);
  const [hidden, setHidden] = React.useState(false);
  const [arrayPatch, setArraypatch] = React.useState([]);
  const [catalogo, setcatalogo] = React.useState(false)

  React.useEffect(() => {
    setArraypatch([])
    if (dataCarpetas) {
      setDAtaFolder(dataCarpetas);

      setItemDataOk([
        <Typography paragraph>Seleccione una Carpeta</Typography>,
      ]);

      console.log("dataCarpetas", dataCarpetas)

    }
  }, [dataCarpetas]);



  const isMobile = () => {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    );
  };

  const actions = [
    {
      icon: (
        <CreateNewFolderIcon
          onClick={() => {
            setOpenCarpetas(true);
          }}
        />
      ),
      name: "Crear Carpeta",
    },
    { icon: <FileCopyIcon />, name: "Crear documento" },
  ];

  const handleArchivar = () => {
    if (patch == "") {
      openAlert("Selecciona una carpeta para archivar el documento.");
      setTypeAlert("error");
    } else {
      const URL = `${process.env.REACT_APP_URL}api/documentos/${idDocumento}?sync_sign=1`;

      let data = {
        folder: patch,
      };

      let token = sessionStorage.getItem("Token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: " application/json",
        },
      };
      axios
        .put(URL, data, config)
        .then((res) => {
          const data = res.data;

          openAlert("Se ha modificado con éxito.");
          setTypeAlert("success");
          setTimeout(function () {
            setPatch("");
            setSendFolder(false);
            if (props.idFolderActual) {
              props.handleActualizar(props.idFolderActual);
            }
          }, 1000);
        })
        .catch((error) => {
          alert("se produjo un error ");
          console.error(error);
        });
    }
  };

  const handleDeleteFolder = (infoFolder) => {
    let token = sessionStorage.getItem("Token");
    console.log("DATAFOLDER", infoFolder);
    let urlDeleteDoc = `${process.env.REACT_APP_URL}api/categorias/${infoFolder._id}`;
    console.log("URL DELETE");
    axios
      .delete(urlDeleteDoc, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
        // receive two parameter endpoint url ,form data
      })
      .then((response) => {
        // Respuesta del servidor
        handleActualizar(infoFolder);

        console.log("respuest Delete", response);
      })
      .catch((error) => {
        // Error 😨
        if (error.response) {
          console.log(error.response.data.message);
          alert(error.response.data.message);
        } else if (error.request) {
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };
  function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }
  const crearCarpeta = () => {
    const URL = `${process.env.REACT_APP_URL}api/categorias`;
    const data = new FormData();
    let rutaFolder = "";
    if (patch.length > 1) {
      rutaFolder = `${patch}/${folder}`;
    } else {
      rutaFolder = folder;
    }
    data.append("folder", rutaFolder);
    let token = sessionStorage.getItem("Token");
    console.log("RUTAFOLDER", rutaFolder);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        Accept: " application/json",
        type: "formData",
      },
    };

    axios
      .post(URL, data, config, {
        // receive two parameter endpoint url ,form data
      })
      .then((response) => {
        // Respuesta del servidor
        setOpenCarpetas(false);
        handleCatalogo();

        console.log("respADD", response.data);
        handleActualizar(response.data.categoria);
      })
      .catch((error) => {
        // Error 😨
        console.log("respERROR", error);
      });
  };

  // funcion para actualizar la data despues agregar o borrar una carpeta
  const handleActualizar = (dataPather) => {
    console.log("datapapa", dataPather);

    const idFolder = dataPather.parent_id;
    setItemDataOk([]);
    let token = sessionStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/categorias/${idFolder}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const okreturn = handleItemDocu(res.data);
        // setDAtaFolder(res.data);

        setItemDataOk(okreturn);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleClose = () => {
    setOpenSpeed(false);
  };
  const handleCatalogo = () => {
    setcatalogo(true)
    let token = sessionStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/categorias`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDAtaFolder(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //FUNCION PARA TRAER EL DOCUMENTO FIRMADO
  const handlePDFB64 = (idDoc) => {
    console.log("IDPDFD", idDoc);
    let token = sessionStorage.getItem("Token");

    axios
      .get(
        `${process.env.REACT_APP_URL}api/documentos/${idDoc}/signed/download`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setPdfBase64(res.data);
        setOpenPDF(true);
      })
      .catch((error) => {
        console.error(error);
        alert(error.data.message);
      });
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  // alert functions

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const openAlert = (message) => {
    setOpenSnack(true);
    setMessageError(message);
  };
  // FUNCION  PARA TRAER EL CONTENIDO DE UNA CARPETA
  // FUNCION  PARA TRAER EL CONTENIDO DE UNA CARPETA
  const HandleChildren = (data) => {
    console.log(data, "HandleChildren");
    // setProgrees(true);
    let idFolder;
    if (Object.keys(data).length == 8) {
      idFolder = data._id;
    } else {
      idFolder = data;
    }

    setItemDataOk([]);
    let token = sessionStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/categorias/${idFolder}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // setProgrees(false);
        console.log("CATALOGO2", res.data);
        setidFather(res.data[0].parent_id);
        setIdFolderActual(res.data[0]._id);
        const patch2 = res.data[0].path;
        const respo = patch2.replace(/\//g, ", ");
        let arr = respo.split(",");

        console.log("patchearreglos", arr);
        setArraypatch(arr);
        setPatch(res.data[0].path);

        const okreturn = handleItemDocu(res.data);

        setItemDataOk(okreturn);
      })
      .catch((error) => {
        // setProgrees(false);
        console.error(error);
      });
  }

  //funcion para iterar las carpetas y documentos dentro de una carpeta


  //funcion para iterar las carpetas y documentos dentro de una carpeta
  const handleItemDocu = (data) => {
    let collectionData = [];
    var itemdocs = null;
    var carpetas = null;
    console.log("DATAITEM", data[0].depth + 1);

    if (data[0].documentos.length == 0 && data.length == 1) {
      return [<Typography paragraph>La carpeta esta vacia</Typography>];
    }
    if (data[0].documentos) {
      itemdocs = data[0].documentos.map((itemdocu) => {
        console.log(itemdocu, "handleItemDocu");
        return (
          <div key={itemdocu.filename}>
            <ListItem button key={itemdocu.filename} style={{ padding: 0 }}>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText
                style={{ marginLeft: "-26px", color: "black" }}
                primary={itemdocu.filename}
                secondary={
                  <React.Fragment>
                    {/* <Typography
                      component="span"
                      variant="body"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      Fecha: {moment(itemdocu.created_at).format("DD/MM/YYYY ")}
                    </Typography> */}

                    <font className={classes.inline}>
                      Fecha: {moment(itemdocu.created_at).format("DD/MM/YYYY ")}
                    </font>
                    <Typography
                      component="span"
                      variant="body"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      {`— ID: ${itemdocu.id}  — Estatus: ${itemdocu.status.name}   — Tipo: ${itemdocu.type.name} `}
                      {itemdocu.user && `— Emisor: ${itemdocu.user.name} `}
                    </Typography>
                  </React.Fragment>
                }
              />

            </ListItem>
          </div>
        );
      });
    }
    collectionData.push(itemdocs);
    if (data.length > 1) {
      var dataFolder = data;
      dataFolder.splice(0, 1);

      carpetas = dataFolder.map((ListItemData, index) => {
        // console.log("dDPETTHT", depthFolder);
        if (ListItemData.depth == data[0].depth) {
          return (
            <ListItem button key={ListItemData.name} style={{ padding: 1 }}>
              <ListItemIcon onClick={() => HandleChildren(ListItemData)}>
                <FolderIcon />
              </ListItemIcon>
              <ListItemText
                style={{ marginLeft: "-26px" }}
                onClick={() => HandleChildren(ListItemData)}
                primary={ListItemData.name}
              />

            </ListItem>
          );
        }
        return null;
      });
      collectionData.push(carpetas);

    }
    return collectionData;
  };
  const handleclosepdf = () => {
    setOpenPDF(false);
  };
  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  // FUNCION PARA VOLVER A LA CARPETA ANTERIOR
  const handleVolver = () => {
    if (idFather) {
      HandleChildren(idFather);
    } else {
      console.log("NO HAY PADRE");
    }
  };
  const handleHome = () => {
    setPatch("");
    setArraypatch([])

    setItemDataOk([<Typography paragraph>Seleccione una Carpeta</Typography>]);
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
            {patch !== "" ? (
              <Tooltip
                id="tooltip-top"
                title="Atras"
                placement="bottom"
                classes={{
                  tooltip: classes2.tooltip,
                }}
              >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleVolver}
                  edge="start"
                >
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
            ) : (
              ""
            )}


            <Tooltip
              id="toolteip"
              title="Mis carpetas"
              placement="bottom"
              classes={{
                tooltip: classes2.tooltip,
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleHome}
                edge="start"
              >
                <HomeIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              id="toolteippatch"
              title={patch}
              placement="bottom"
            >
              <Breadcrumbs style={{ color: "#ffffff" }} maxItems={7} itemsAfterCollapse={2} itemsBeforeCollapse={2} aria-label="breadcrumb">
                {arrayPatch.map((value, index) => {
                  return (
                    <Link color="inherit" style={{ color: "#ffffff" }} href="#" onClick={handleClick}>
                      {value}
                    </Link>
                  )
                })}
              </Breadcrumbs>
            </Tooltip>


          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        PaperProps={{
          style: {
            position: "absolute",
          },
        }}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {dataFolder.map((ListItemData, i) => {
            if (ListItemData.depth == 0) {
              return (
                <ListItem
                  button
                  key={i}
                  onClick={() => HandleChildren(ListItemData)}
                >
                  <ListItemIcon>
                    <FolderIcon />
                  </ListItemIcon>
                  <ListItemText primary={ListItemData.name} />
                </ListItem>
              );
            } else {
              return null;
            }
          })}
        </List>
        <Divider />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {/*AQUI SE RENDERIZA EL CONTENIDO DE LAS CARPETAS */}
        <div style={{ marginTop: -50 }}>{ItemDataok}</div>
      </main>
      <Fab
        variant="extended"
        color="primary"
        aria-label="add"
        className={classes.speedDial}
        onClick={handleArchivar}
      >
        <ArchiveIcon />
        Archivar aqui
      </Fab>

      <DialogComp
        open={openPDF}
        title="Tu documento"
        maxWidth={"md"}
        content={
          pdfBase64.length === 0 ? (
            <Backdrop className={classespro.backdrop} open={open}>
              <CircularProgress
                size={44}
                className={classespro.buttonProgress}
              />
            </Backdrop>
          ) : (
            <div align="center">
              <ControlPanel
                scale={scale}
                setScale={setScale}
                numPages={numPages}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                file={`data: application / pdf; base64, ${pdfBase64} `}
              />
              <Document
                error="Error al intentar cargar el PDF"
                loading="Cargando..."
                file={`data: application / pdf; base64, ${pdfBase64} `}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page
                  scale={scale}
                  height={pdfHeigth}
                  width={pdfWidth}
                  renderTextLayer={false}
                  pageNumber={pageNumber}
                />
              </Document>

              <div className="text-center">
                <p>
                  Página {pageNumber || (numPages ? 1 : "--")} de{" "}
                  {numPages || "--"}
                </p>
                <button
                  className="btn btn-dark"
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                >
                  Atrás
                </button>{" "}
                <button
                  className="btn btn-dark"
                  type="button"
                  disabled={pageNumber >= numPages}
                  onClick={nextPage}
                >
                  Siguiente
                </button>
              </div>
            </div>
          )
        }
        buttons={[
          {
            buttonEvent: handleclosepdf,
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
        ]}
      />
      {/* DIALOGO PARA AGREGAR CARPETAS */}
      <DialogComp
        open={openCarpetas}
        title={" Ingresa la ruta y/o nombre de la carpeta:"}
        maxWidth={"md"}
        content={
          <div>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Ruta de la carpeta"
              variant="outlined"
              fullWidth
              value={folder}
              onChange={(e) => {
                setFolder(e.target.value);
              }}
            />
          </div>
        }
        buttons={[
          {
            buttonEvent: () => setOpenCarpetas(false),
            buttonName: "Cerrar",
            buttonColor: "secondary",
          },
          {
            buttonEvent: () => crearCarpeta(),
            buttonName: "Guardar",
            buttonColor: "primary",
          },
        ]}
      />
      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handleCloseSnack}
      >
        <Alert onClose={handleCloseSnack} severity={typeAlert}>
          {messageError}
        </Alert>
      </Snackbar>
    </div>
  );
}
