import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";
import clsx from "clsx";
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import CargaDocumento from "containers/FirmaAvanzada/CargaDocumento";
import VistaPrevia from "containers/FirmaAvanzada/VistaPrevia";
import PDFView from "containers/Expedientes/PDFView";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "components/Snackbar/Snackbar.js";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import AddAPhoto from "@material-ui/icons/AddAPhoto";
import Check from "@material-ui/icons/Check";
import GestureIcon from "@material-ui/icons/Gesture";
import PublishIcon from '@material-ui/icons/Publish';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GavelIcon from '@material-ui/icons/Gavel';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import routes from "routes.js";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import bgImage from "assets/img/fondoblack.jpg";
import logo from "assets/img/ConCertezaGris.png";
import axios from "axios";
import { useHistory } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

//credenciales API CDO
const username = "ce2af9cab947ed15288e058ecd5350f5";
const password = "9c9dd0db9b4a23eddf64b96e28b1214b";
const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");
//const PROXY_URL = 'https://sistemas.lemperroni.com/';
const PROXY_URL = "";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "30%",
      marginRight: "auto",
      marginTop: "13%",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  divacciones: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  input: {
    display: "none",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    float: "right",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  footer: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(1),
      padding: theme.spacing(3),
    },
  },
}));

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      " linear-gradient(79deg, rgba(76,175,80,1) 0%, rgba(102,187,106,1) 63%, rgba(92,184,96,1) 100%)",
  },
});

function getSteps() {
  return ["Carga", "Visualiza", "Genera"];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return "Cargar el Documento";
    case 1:
      return "Vista Previa";
    case 2:
      return "Generar Constancia";
    default:
      return "Unknown stepIndex";
  }
}

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;
  let icons = {};

  icons = {
    1: <PublishIcon />,
    2: <VisibilityIcon />,
    3: <GavelIcon />,
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed ? <Check /> : icons[String(props.icon)]}
    </div>
  );
}

export default function Flujo(props) {
  const ref = React.createRef();
  function goTop() {
    console.log("REF");
    ref.current.scrollIntoView();
  }
  //layout
  const history = useHistory();
  const classes = useStyles();
  const classes2 = useStyles2();
  const mainPanel = React.createRef();
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  const [paperWidth, setPaperWidth] = React.useState("80%");
  const [marginButton, setMarginButton] = React.useState("35%");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [pdf, setPDF] = React.useState("");
  const [archivo, setArchivo] = React.useState("");
  const [constancia, setConstancia] = React.useState("");
  const [mensajeError, setMensajeError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [messageError, setMessageError] = React.useState("");
  const [openAlert, setOpenAlert] = React.useState("");
  const [colorAlert, setColorAlert] = React.useState("info");

  const [openSnack, setOpenSnack] = React.useState(false);
  const [messageErrorSnack, setMessageErrorSnack] = React.useState("");
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const openAlerta = (error) => {
    setOpenSnack(true);
    setMessageErrorSnack(error);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleVolver = () => {
    let uri = localStorage.getItem("uri");
    if (props.setActiveRender) {
      props.setActiveRender(0);
    } else {
      history.push(`/${uri}/serviciosLegales`);
    }
  };

  //steps
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // initialize and destroy the PerfectScrollbar plugin

  React.useEffect(() => {
    document.body.style.overflow = "unset";
    if (isMobile) {
      setPaperWidth("100%");
      setMarginButton("5%");
    }
  }, []);



  //API SOLICITUD DE CONSTANCIA CDO (ya no se usa)
  function makeGetRequestConstancia() {
    const URL =
      "https://api.certificaciondigital.online/api/constancia/solicitud";

    const data = new FormData();
    //data.append('contrato', fs.createReadStream('/path/to/file'));
    data.append("archivo", archivo);
    data.append("descripcion", "Constancia NOM151 -" + archivo.name);
    console.log("DATAbodyfirmante", data);

    var urlDocumento = PROXY_URL + URL;
    var config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Basic " + token,
      },
    };
    return new Promise(function (resolve, reject) {
      axios.post(urlDocumento, data, config, {}).then(
        (response) => {
          var result = response.data;
          console.log("Processing Request");
          resolve(result);
        },
        (error) => {
          reject(error);
          setMensajeError(true);
        }
      );
    });
  }

  //API SOLICITUD DE CONSTANCIA CON CERTEZA
  function makeGetRequestConstanciaCC(idDocumento) {
    const urlDocumento = `${process.env.REACT_APP_URL}api/documentos/solicitarConstancia/${idDocumento}`;
    let tokenCC = sessionStorage.getItem("Token");

    /*
        const data = new FormData();
        data.append("archivo", archivo);
        data.append("descripcion", "Constancia NOM151 -" + archivo.name);
        console.log("DATAbodyfirmante", data);
    */
    //const data = [];
    //var urlDocumento = PROXY_URL + URL;
    /*
        var config = {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + tokenCC,
          },
        };
    */
    var config = {
      method: "PUT",
      url: `${process.env.REACT_APP_URL}api/documentos/solicitarConstancia/${idDocumento}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };

    return new Promise(function (resolve, reject) {
      //axios.post(urlDocumento, data, config, {}).then(
      axios(config).then(
        (response) => {
          var result = response.data;
          console.log("Processing Request");
          resolve(result);
        },
        (error) => {
          reject(error);
          setMensajeError(true);
        }
      );
    });
  }


  //API CREAR DOCUMENTO EN CON-CERTEZA
  function makeGetRequestDocumentoCC() {
    const URL = `${process.env.REACT_APP_URL}api/documentos`;
    let tokenCC = sessionStorage.getItem("Token");

    const data = new FormData();
    data.append("filename", archivo);
    data.append("descripcion", archivo.name);
    data.append("documento_types_id", "11");
    //data.append('cdo_contratos_id', contratocdo);
    //console.log("DATAbodyfirmante", data);

    var urlDocumento = URL;
    var config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };
    return new Promise(function (resolve, reject) {
      axios.post(urlDocumento, data, config, {}).then(
        (response) => {
          var result = response.data;
          console.log("Processing Request");
          resolve(result);
        },
        (error) => {
          reject(error);
          setMensajeError(true);
          //openAlerta("Imposible cargar el documento debido a que ha sido registrado con anterioridad");
          setColorAlert("danger");
          showNotificacion("Imposible cargar el documento debido a que ha sido registrado con anterioridad");

        }
      );
    });
  }

  function makeUpdateDocumentoCC(idDocumento, constanciaCDO) {
    let tokenCC = sessionStorage.getItem("Token");
    let newStatus = 5;
    let constancia = `{"id" : "${constanciaCDO.id}", "hash" : "${constanciaCDO.hash}", "datetime" : "${constanciaCDO.datetime}", "descripcion" : "${constanciaCDO.descripcion}", "originalname" : "${constanciaCDO.originalname}"}`;
    //let constancia =constanciaCDO;

    var config = {
      method: "PUT",
      url: `${process.env.REACT_APP_URL}api/documentos/${idDocumento}?documento_status_id=${newStatus}&constancia=${constancia}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenCC,
      },
    };

    axios(config)
      .then(function (response) {
        console.log("ContratoCC Actualizado:", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function registra() {
    //se crea el ducumento en con certeza
    var resultCC = await makeGetRequestDocumentoCC();
    console.log("RESULTCC:", resultCC);

    if (resultCC) {
      //se genera la constancia desde CDO
      var result = await makeGetRequestConstanciaCC(resultCC[1]._id);
      console.log("CONSTANCIA:", result);
      //setConstancia(result);
      setConstancia(result.constancia);
      console.log("Constancia CDO OK");

      //se actualiza el documento creado en CC con su constancia
      //makeUpdateDocumentoCC(resultCC[1]._id, result);
      console.log("FIN DEL PROCESO");
    }
    /*
        setContrato(result);
        console.log('Contrato OK');
        var resultCC = await makeGetRequestContratoCC(result.id);
        console.log("RESULTCC:",resultCC);
        setContratoCC(resultCC);
      */
    handleNext();
  }

  const handleRegistra = () => {
    registra();
  };

  const handleReset = () => {
    goTop();
    setActiveStep(0);
    setPDF("");
  };

  const handleDescargar = () => {
    //////////////////////////////////
    var nombre_archivo = constancia.descripcion + ".zip";
    var URL =
      "https://api.certificaciondigital.online/api/constancia/solicitud/" +
      constancia.id +
      "/descargar";

    var config = {
      method: "get",
      url: PROXY_URL + URL,
      responseType: "blob", // important
      headers: {
        Authorization: "Basic " + token,
      },
    };
    axios(config).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nombre_archivo);
      document.body.appendChild(link);
      link.click();
    });
    ////////////////////////////////////
    setActiveStep(0);
    setPDF("");
  };

  const handleGenerar = () => {
    goTop();
    showNotificacion(
      "Generando Constancia de Conservación, por favor espere un momento"
    );
    registra();
  };

  const showNotificacion = (message) => {
    setOpenAlert(true);
    setMessageError(message);
    setTimeout(function () {
      setOpenAlert(false);
    }, 6000);
  };

  return (
    <React.Fragment>
      {/*<div className={classes.root}>*/}
      {activeStep == 0 ? (
        <Button
          size="large"
          style={{
            marginTop: "-9px",
            height: "30px",
          }}
          startIcon={<ArrowBackIcon />}
          onClick={() => handleVolver()}
        >
          Regresar
        </Button>
      ) : (
        ""
      )}
      <div style={{ marginTop: "0px" }} ref={ref}>
        <Grid
          style={{
            //marginTop: "10%",
            width: paperWidth,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Paper>
            <Typography variant="h4" align="center">
              Constancia de Conservación
            </Typography>
            {/*
            <Typography align="center">
              Generar Constancia de Conservación
            </Typography>
            */}
            <Stepper
              activeStep={activeStep}
              className={classes.stepper}
              connector={<ColorlibConnector />}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : null}
            {/*
              <div
                style={{
                  display: 'flex',
                  justifyContent:'center',
                  alignItems:'center',
                  marginLeft:"25%"
                }}
              >
              */}
            {activeStep === 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: marginButton,
                  width: "95%"
                }}
              >
                <CargaDocumento
                  handleNext={handleNext}
                  setPDF={setPDF}
                  setArchivo={setArchivo}
                />
              </div>
            ) : activeStep === 1 ? (
              <>
                <Grid container>
                  {/*
              <Grid item sm="12">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleGenerar}
                    style={{
                      float: "right",
                      marginBottom: "20px",
                      marginRight:"10px"
                    }}
                  >
                      Generar constancia
                  </Button>
                  <Button
                    variant="contained"
                    color="danger"
                    onClick={handleReset}
                    style={{
                      float: "right",
                      marginBottom: "20px",
                      marginRight:"5px",
                      backgroundColor: "red", color: "white"
                    }}
                  >
                      Cancelar
                  </Button>
              </Grid>
              */}
                  <Grid item sm="12">
                    {pdf ? <PDFView base64={pdf} /> : ("NO hay PDF")}
                  </Grid>
                  <Grid item sm="12">
                    <hr />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleGenerar}
                      style={{
                        float: "right",
                        marginBottom: "20px",
                        marginRight: "10px"
                      }}
                    >
                      Generar constancia
                    </Button>
                    <Button
                      variant="contained"
                      color="danger"
                      onClick={handleReset}
                      style={{
                        float: "right",
                        marginBottom: "20px",
                        //marginRight:"5px",
                        backgroundColor: "red", color: "white"
                      }}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : (
              <div>
                <Typography variant="h6" align="center">
                  Su constancia ha sido generada
                </Typography>
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDescargar}
                  style={{
                    marginLeft: "40%",
                  }}
                >
                  <Typography style={{ textTransform: "none" }}>
                    Descargar
                  </Typography>
                </Button>
              </div>
            )}
            <br />
            <br />
            <br />
          </Paper>
        </Grid>
      </div>

      <Snackbar
        place="tc"
        color={colorAlert}
        icon={InfoIcon}
        message={messageError}
        open={openAlert}
        closeNotification={() => {
          setOpenAlert(false)
          setColorAlert("info")
        }}
        close
      />

    </React.Fragment>
  );
}
