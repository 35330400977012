import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
import ButtonC from "components/CustomButtons/Button.js";
import axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import validator from "validator";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";

import Grid from "@material-ui/core/Grid";

import TextField from "@material-ui/core/TextField";

import Backdrop from "@material-ui/core/Backdrop";
import { green } from "@material-ui/core/colors";

import styles from "assets/jss/material-kit-react/views/components.js";


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles(styles);
const useStyles2 = makeStyles((theme) => ({
    whatsapp: {
        position: "fixed",
        width: "80px",
        height: "80px",
        bottom: " 60px",
        right: " 60px",
        backgroundColor: " #25d366",
        color: "#ffff",
        borderRadius: "60px",
        textAlign: "center",
        fontSize: "50px",
    },
}));
const useStylesprogrees = makeStyles((theme) => ({
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    buttonProgress2: {
        color: green[500],
        alignItems: "center",
        top: "50%",
        left: "50%",
        marginTop: 12,
        marginLeft: 12,
    },

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));


export default function Register(props) {
    const classes = useStyles();
    const classespro = useStylesprogrees();

    const { idDocumento, handleCloseCopiaCer } = props
    const [datos, setDatos] = useState({
        contacto: "",
        telefono: "",
        correo: "",
        comentarios: "",
    });
    const [progress, setprogress] = useState(false);

    const [textoValidacionMail, setTextoValidacionMail] = useState("");
    const [openSnack, setOpenSnack] = useState(false);
    const [disabledContact, setDisabledContact] = useState(true)

    useEffect(() => {

        let esCorreo = validator.isEmail(datos.correo);
        if (esCorreo && datos.comentarios.length >= 3 && datos.contacto.length >= 3 && datos.telefono.length == 10) {
            setDisabledContact(false)
        }

    }, [datos]);



    const handleCloseGuardado = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnack(false);
    };

    const removerCaracteres = (event) => {
        let phone = event.target.value;
        if (phone) {
            let wordArray = phone.split("");
            let result = "";
            if (wordArray) {
                wordArray.map((item, i) => {
                    if (esNumero(wordArray[i])) {
                        result += wordArray[i];
                    }
                });
                return result;
            } else {
                return false;
            }
        }
        return false;
    };
    function esNumero(text) {
        if (text) {
            var reg = new RegExp("[0-9]+$");
            if (reg.test(text)) {
                return reg.test(text);
            } else {
                return false;
            }
        }
        return false;
    }

    const handleinput = (event) => {

        if (event.target.name == "telefono") {
            let resultado = removerCaracteres(event);
            setDatos({
                ...datos,
                [event.target.name]: !resultado ? "" : resultado,
            });
            return false;
        }
        setDatos({
            ...datos,
            [event.target.name]: event.target.value,
        });
    }
    function validarMail(email) {
        let tokenSG =
            "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";
        var config = {
            async: true,
            crossDomain: true,
            method: "post",
            url: `https://api.sendgrid.com/v3/validations/email`,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + tokenSG,
            },
            processData: false,
            data: `{\"email\":\"${email}\",\"source\":\"signup\"}`,
        };
        axios(config)
            .then(function (response) {
                console.log("Email Validation:", response);
                if (response.data.result.verdict === "Invalid") {
                    setTextoValidacionMail("Correo no válido");
                } else {
                    setTextoValidacionMail("");
                }
                //verdict: "Invalid"
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const sendCopiaCer = () => {
        let token = sessionStorage.getItem("Token");
        setprogress(true)

        let url = `${process.env.REACT_APP_URL}api/documentos/solicitar/copia/${idDocumento}`;
        const data = new FormData();
        data.append("contacto", datos.contacto);
        data.append("correo", datos.correo)
        data.append("telefono", datos.telefono)
        data.append("comentarios", datos.comentarios)

        axios
            .post(url, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                withCredentials: true,
                crossDomain: true,
            })
            .then((response) => {
                console.log("copiacer", response);
                setOpenSnack(true);
                setTimeout(function () {
                    setprogress(false)
                    handleCloseCopiaCer()

                }, 2000);

                //Guardar log Registró Grafo firma
                //buscar datos firmante

            })
            .catch((error) => {
                console.log(error)
                alert("Error al solicitar Copia certificada")
                setprogress(false)

            });
    }
    return (
        <div>
            <Grid container spacing={1}>


                <Grid item xs={3} sm={6}>
                    <TextField
                        required
                        id="correo"
                        name="correo"
                        variant="outlined"
                        label="Correo"
                        value={datos.correo}
                        fullWidth
                        autoComplete="correo"
                        onChange={handleinput}
                        inputProps={{ maxLength: 80 }}
                        error={datos.correo.length > 5 ? false : true}
                        onBlur={() => {
                            if (datos.correo) {
                                validarMail(datos.correo);
                            }
                        }}
                    />
                    <font color="red">{textoValidacionMail}</font>
                </Grid>
                <Grid item xs={3} sm={6}>
                    <TextField
                        required
                        id="contacto"
                        name="contacto"
                        variant="outlined"
                        label="Nombre de contacto"
                        value={datos.contacto}
                        fullWidth
                        autoComplete="given-name"
                        onChange={handleinput}
                        inputProps={{ maxLength: 80 }}
                        error={datos.contacto.length > 1 ? false : true}
                    />
                </Grid>
                <Grid item xs={3} sm={6}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="telefono"
                        label="Celular"
                        onChange={handleinput}
                        value={datos.telefono}
                        inputProps={{ maxLength: 10 }}
                        name="telefono"
                        error={datos.telefono.length < 10 ? true : false}
                    />
                </Grid>
                <Grid item xs={3} sm={6}>
                    <TextField
                        fullWidth
                        id="comentarios"
                        name="comentarios"
                        label="Comentarios"
                        multiline
                        rows={3}
                        value={datos.comentarios}
                        onChange={handleinput}
                        defaultValue="Comentarios"
                        variant="outlined"
                        error={datos.comentarios.length < 3 ? true : false}

                    />
                </Grid>
                <Grid item xs={6} sm={12} style={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}
                >
                    <ButtonC

                        color="success"
                        onClick={sendCopiaCer}
                        disabled={disabledContact}
                    >
                        Solicitar Copia
                    </ButtonC>
                </Grid>

            </Grid>
            <Snackbar
                open={openSnack}
                autoHideDuration={4000}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                onClose={handleCloseGuardado}
            >
                <Alert onClose={handleCloseGuardado} severity="success">
                    La solicitud ha sido recibida correctamente por nuestro sistema, te notificaremos el avance del proceso.
                </Alert>
            </Snackbar>
            <Backdrop className={classespro.backdrop} open={progress}>
                <CircularProgress size={44} className={classespro.buttonProgress} />
            </Backdrop>
        </div>
    );
}
