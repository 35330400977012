/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
//imput
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import axios from "axios";
import clsx from "clsx";
import Input from "@material-ui/core/Input";
import Chip from "@material-ui/core/Chip";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import CardHeader from "components/Card/CardHeader";
import Backdrop from "@material-ui/core/Backdrop";
import InputLabel from "@material-ui/core/InputLabel";

import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Paper from "@material-ui/core/Paper";
// import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Snackbar from "components/Snackbar/Snackbar.js";
import ErrorIcon from "@material-ui/icons/Error";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  panelRoot: {
    width: "80%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  root: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    display: "none",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: "280px",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  textodias: {
    marginTop: theme.spacing(+2),
  },
  textodiaslabel: {
    marginTop: theme.spacing(+1),
  },
  formControlTitle: {
    textAlign: "center",
    marginTop: 10,
    width: "90%",
  },
  formControl1: {
    marginTop: 10,
    width: "80%",
  },
  formControl2: {
    width: "80%",
  },
}));

const useStylesprogrees = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgress2: {
    color: green[500],
    alignItems: "center",
    top: "50%",
    left: "50%",
    marginTop: 12,
    marginLeft: 12,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
export default function AddressFormDocument(props) {
  const {
    onChangePDF,
    progress,
    setAsistido,
    setFechaCierta,
    asistido,
    fechaCierta,
    descripcion,
    setDescripcion,
    setInicio,
    setTermino,
    setDiasAlert,
    corporaciones,
  } = props;
  const classes = useStyles();
  const theme = useTheme();

  // Metadata
  const [selectCustomMeta, setSelectCustomMeta] = useState("");
  const [customMeta, setCustomMeta] = useState([]);
  const [data, setData] = useState({});

  const [metaName, setMetaName] = useState([]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(name, metaName, theme) {
    return {
      fontWeight:
        metaName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  ////

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [user, setUser] = useState("");
  const [desde, setDesde] = useState("");
  const [hasta, setHasta] = useState("");
  const [diasAlerta, setDiasAlerta] = useState(0);
  const [alerta, setAlerta] = useState("");
  const [messageError, setMessageError] = useState("");
  const [openAlert, setOpenAlert] = useState("");
  const [checked, setChecked] = useState(false);
  const [selectRol, setSelectRol] = useState([]);
  const [idCompany, setIdCompany] = useState("");
  const [tipoRoles, setTiporoles] = useState({
    codigo: "Seleccione el nombre del remitente",
    name: "Seleccione el nombre del remitente",
  });
  const [mainGroup, setGroup] = useState(0);
  const [idClases, setIdClases] = useState("");
  const [selectClases, setSelectClases] = useState([]);

  const [tipoClases, setTipoClases] = useState({
    codigo: "Selecciona la clase de documento ",
    name: "Selecciona la clase de documento ",
  });
  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      if (diasAlerta === 0) {
        setDiasAlert(30);
        setDiasAlerta(30);
      } else {
        setDiasAlert(diasAlerta);
      }
    } else {
      setDiasAlert(0);
      setDiasAlerta(0);
    }
  };
  const timer = React.useRef();
  const classespro = useStylesprogrees();

  /// Metadata Functions

  const handleChangeMetadatos = (atributo, event) => {
    setData({
      ...data,
      [atributo]: event.target.value,
    });
    // clg
  };

  const handlechangeCustom = (event) => {
    setData({});
    const newMeta = event.target.value;
    setMetaName(newMeta);
    setCustomMeta(newMeta);
  };

  function getCustomMeta() {
    let token = sessionStorage.getItem("Token");
    //let token = `${process.env.REACT_APP_TOKEN}`;
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/custommeta`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        setSelectCustomMeta(response.data);
      })
      .catch(function (error) {
        console.log(error);
        setSelectCustomMeta("");
      });
  }

  const handlechangeClases = (event) => {
    console.log("idclases", event.target.value);
    setIdClases(event.target.value);
    let datal = {
      codigo: event.target.value,
      name: event.target.value,
    };
    setTipoClases(datal);
  };

  const Textos = () => {
    let customMeta = [];
    metaName.map((itemMeta) => {
      selectCustomMeta.map((customItem) => {
        if (itemMeta == customItem.name) {
          customMeta.push(customItem);
        }
      });
    });
    return (
      <>
        <br />
        {customMeta.map((mainItem, index) => (
          <div key={index} className={classes.panelRoot}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
              >
                <Typography className={classes.heading}>
                  {mainItem.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ display: "block" }}>
                {Object.keys(mainItem.fields).map((childItem, index2) => (
                  <TextField
                    style={{ marginTop: 5 }}
                    fullWidth
                    key={index2}
                    label={childItem}
                    helperText={"* " + mainItem.fields[childItem]}
                    onChange={(e) => {
                      handleChangeMetadatos(childItem, e);
                    }}
                    name="name"
                    id={index.toString()}
                    variant="outlined"
                  />
                ))}
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
        <br />
      </>
    );
  };

  const showNotificacion = (message) => {
    setOpenAlert(true);
    setMessageError(message);
    setTimeout(function () {
      setOpenAlert(false);
    }, 6000);
  };

  useEffect(() => {
    getCustomMeta();
    handleclases();
  }, []);

  useEffect(() => {
    if (corporaciones.length > 0) {
      itemRoles();
    }

    getUser();
    return () => {
      clearTimeout(timer.current);
    };
  }, [corporaciones]);

  function handleclases() {
    let token = sessionStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/clases`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("RESPCORPO", res.data);
        var RESPCORPO = res.data;
        var collection = [];
        RESPCORPO.map(function (data) {
          var clasesItem = {
            codigo: data._id,
            name: data.name,
          };
          collection.push(clasesItem);
        });
        console.log("COLLECTION", collection);
        setSelectClases(collection);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const itemRoles = () => {
    var collection = [];

    corporaciones.map(function (data) {
      var rolesItem = {
        codigo: data._id,
        name: data.name,
      };

      collection.push(rolesItem);
    });
    console.log("COLLECTION", collection);
    setSelectRol(collection);
  };
  const getUser = () => {
    let token = sessionStorage.getItem("Token");
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/auth/user`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios(config)
      .then(function (response) {
        //console.log("User:",response.data);
        setUser(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onChange = () => {
    onChangePDF(idCompany, data, idClases, mainGroup);

    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 4000);
    }
  };

  const handlechangeRoles = (event) => {
    console.log("eventssd", event.target.value);
    setIdCompany(event.target.value);
    let datal = {
      codigo: event.target.value,
      name: event.target.value,
    };
    setTiporoles(datal);
  };

  const handleChangeGroups = (event) => {
    const group = event.target.value;
    setGroup(group);
    // setIdCompany(event.target.value);
    // let datal = {
    //   codigo: event.target.value,
    //   name: event.target.value,
    // };
    // setTiporoles(datal);
  };

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  //const personalizar = () => setAsistido(asistido => !asistido);

  const marcaAsistido = () => {
    if (user.permissions.search("stats.cdo") >= 0) {
      return (
        <Grid item xs={12} sm={12}>
          <label htmlFor="contained-button-file">
            <input
              type="checkBox"
              checked={asistido}
              onClick={() => console.log("Clicked!")}
              onChange={() => setAsistido((asistido) => !asistido)}
            />
            Es un proceso asistido
          </label>
        </Grid>
      );
    }
  };

  const isProduction =
    process.env.REACT_APP_PRODUCTION === true ||
    process.env.REACT_APP_PRODUCTION === "true";

  const marcaFechaCierta = () => {
    return (
      <Grid
        item
        xs={12}
        sm={12}
        style={{ display: isProduction ? "none" : undefined }}
      >
        <hr />
        <label htmlFor="contained-button-fechaCierta">
          <input
            type="checkBox"
            checked={fechaCierta}
            onClick={() => console.log("Clicked!")}
            onChange={() => setFechaCierta((fechaCierta) => !fechaCierta)}
          />
          <font color="black">
            Solicitar Fecha Cierta al culminar el proceso
          </font>
        </label>
        <hr />
      </Grid>
    );
  };

  return (
    <React.Fragment>
      {/* <Grid
        container
        spacing={3}
        direction="column"
        justify="center"
        alignItems="center"
      ></Grid> */}
      <Grid
        container
        spacing={1}
        direction="column"
        justify="center"
        alignItems="center"
      >
        {/*METADATA*/}
        <center>
          {corporaciones.length > 0 && (
            <>
              <FormControl
                className={classes.formControl}
                style={{ marginTop: -30 }}
              >
                <InputLabel id="remitente">Compañía solicitante</InputLabel>

                <Select
                  labelId="remitente"
                  id="remitenteid"
                  value={tipoRoles.codigo}
                  onChange={handlechangeRoles}
                >
                  <MenuItem value="Seleccione el nombre del remitente" disabled>
                    Selecciona la compañía solicitante
                  </MenuItem>
                  {selectRol.map(({ codigo, name }, index) => (
                    <MenuItem key={index} value={codigo}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <br />
              <br />
              <div style={{ display: isProduction ? 'none' : 'block' }}>
                <Typography>Compartir con grupo de trabajo:</Typography>
                <br />
                <br />
                <FormControl className={classes.formControl}>
                  <InputLabel id="grupo">Grupo de trabajo</InputLabel>

                  <Select
                    labelId="grupo"
                    id="grupoId"
                    value={mainGroup}
                    onChange={handleChangeGroups}
                  >
                    <MenuItem value="Seleccione el grupo de trabajo " disabled>
                      Selecciona el grupo de trabajo
                    </MenuItem>
                    {corporaciones.map(({ roles_id, name }, index) => (
                      <MenuItem key={index} value={roles_id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </>
          )}
          <br />
          <br />

          {selectClases.length > 1 ? (
            <FormControl className={classes.formControl}>
              <InputLabel id="clase">Clases de documento</InputLabel>

              <Select
                labelId="clase"
                id="clase"
                value={tipoClases.codigo}
                onChange={handlechangeClases}
              >
                <MenuItem value="Selecciona la clase de documento " disabled>
                  Selecciona la clase de documento
                </MenuItem>
                {selectClases.map(({ codigo, name }, index) => (
                  <MenuItem key={index} value={codigo}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            ""
          )}
          <br />
          <br />
        </center>
        <Container maxWidth="xs">
          <Box width={1} bgcolor="grey.300" p={1} my={0.5}>
            <Paper elevation={3} style={{ padding: 20 }}>
              <Grid container>
                <Grid item xs={6} sm={12}>
                  <p align="center">
                    <b>Información general del documento</b>
                  </p>
                  <hr />
                </Grid>

                <Grid item xs={12} sm={6}>
                  Descripción:
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={2}
                    maxRows={4}
                    inputProps={{
                      maxlength: 360,
                    }}
                    aria-label="minimum height"
                    rowsMin={2}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={`${descripcion.length}/360`}
                    placeholder=""
                    //style={{ width: "100%" }}
                    onChange={(e) => {
                      console.log("descripcion", e.target.value); // Works
                      setDescripcion(e.target.value);
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  Inicio vigencia:
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="dateDesde"
                    type="date"
                    onChange={(e) => {
                      console.log("inicio", e.target.value); // Works
                      setInicio(e.target.value);
                      setDesde(e.target.value);

                      if (e.target.value > hasta) {
                        setAlerta(
                          "La fecha de término debe ser superior a la fecha de inicio"
                        );
                      } else {
                        setAlerta("");
                      }
                      var date = new Date(e.target.value);
                      var f = new Date();

                      var hoy = new Date("Y-m-d");
                      if (date.getFullYear() > 2300) {
                        showNotificacion("La fecha de inicio es inválida");
                      }
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  Término vigencia:
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="dateDesde"
                    type="date"
                    onChange={(e) => {
                      console.log("termino", e.target.value); // Works
                      setTermino(e.target.value);
                      setHasta(e.target.value);

                      if (e.target.value < desde) {
                        //alert("La fecha de término debe ser superior a la fecha de inicio")
                        setAlerta(
                          "La fecha de término debe ser superior a la fecha de inicio"
                        );
                      } else {
                        setAlerta("");
                      }
                      var date = new Date(e.target.value);
                      if (date.getFullYear() > 2300) {
                        showNotificacion("La fecha de término es inválida");
                      }
                    }}
                    //value={desde}
                    //className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                {alerta ? (
                  <Grid item xs={12} sm={12}>
                    <font size="2" color="red">
                      <b>{alerta}</b>
                    </font>
                  </Grid>
                ) : null}
                <Grid item xs={12} sm={12}>
                  <font size="2" color="darkblue">
                    <b>Estos datos son opcionales</b>
                  </font>
                </Grid>

                {hasta ? (
                  <React.Fragment>
                    <Grid item xs={12} sm={2}></Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      className={classes.textodiaslabel}
                    >
                      <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        color="default"
                      //inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                      <font size="2" color="green">
                        Alerta de vencimiento:
                      </font>
                    </Grid>
                    <Grid item xs={12} sm={2} className={classes.textodias}>
                      <TextField
                        //variant="outlined"
                        id="standard-number"
                        type="number"
                        disabled={!checked}
                        value={diasAlerta}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: 2,
                          max: 90,
                          style: { fontSize: 15, color: "green" },
                        }}
                        style={{ width: "80%" }}
                        onChange={(e) => {
                          console.log("días", e.target.value); // Works
                          setDiasAlerta(e.target.value);
                          setDiasAlert(e.target.value);
                          if (e.target.value < 2 || e.target.value > 90) {
                            showNotificacion(
                              "La alerta de vencimiento debe comprender de mínimo 2 a máximo 90 días."
                            );
                            setDiasAlerta(30);
                            setDiasAlert(30);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={1} className={classes.textodias}>
                      <font size="2" color="green">
                        días
                      </font>
                    </Grid>
                  </React.Fragment>
                ) : null}
              </Grid>
            </Paper>
          </Box>
        </Container>
        {/*FIN METADATA*/}

        {/*FECHA CIERTA*/}
        <Grid item xs={12} sm={12}>
          <p align="center">{marcaFechaCierta()}</p>
        </Grid>

        {/*METADATOS CUSTOM*/}
        <br />
        {selectCustomMeta ? (
          <>
            <CardHeader className={classes.formControlTitle} color="info">
              DATOS ADICIONALES
            </CardHeader>

            {/* <FormControl className={classes.formControl}>
              <InputLabel id="demo-mutiple-checkbox-label">Tag</InputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={personName}
                onChange={handleChange}
                input={<Input />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {names.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={personName.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}

            <FormControl className={classes.formControl1}>
              <InputLabel id="remitente">
                Seleccione el catálogo de datos
              </InputLabel>
              <Select
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                multiple
                value={metaName}
                onChange={handlechangeCustom}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {selectCustomMeta.map(({ _id, name }, index) => (
                  <MenuItem
                    key={index}
                    value={name}
                    style={getStyles(name, metaName, theme)}
                  >
                    <Checkbox checked={metaName.indexOf(name) > -1} />
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {customMeta.length > 0 ? Textos() : null}
            <br />
          </>
        ) : null}

        {/*FIN METADATOS CUSTOM*/}

        <Grid item xs={12} sm={12}>
          <input
            accept="pdf/*"
            className={classes.input}
            id="contained-button-file"
            multiple
            type="file"
            name="PDF"
            onChange={onChange}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="contained"
              color="primary"
              component="span"
              disabled={loading}
              className={buttonClassname}
            >
              <Typography variant="h6" style={{ textTransform: "none" }}>
                CARGA DOCUMENTO PDF
              </Typography>
            </Button>
          </label>
        </Grid>
      </Grid>
      {user ? marcaAsistido() : null}
      <Backdrop className={classespro.backdrop} open={progress}>
        <CircularProgress size={44} className={classespro.buttonProgress} />
      </Backdrop>
      <Snackbar
        place="tc"
        color="danger"
        icon={ErrorIcon}
        message={messageError}
        open={openAlert}
        closeNotification={() => setOpenAlert(false)}
        close
      />
    </React.Fragment>
  );
}
