import React, { useEffect, useState } from "react";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Webcam from "react-webcam";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import rec from "assets/img/rec.gif";
import PVSpeechProcessor from "./PVSpeechProcessor";
import PVNoCameraAvailableDialog from "./PVNoCameraAvailableDialog";
import Cdo from "./../../services/Cdo";

const useStyles = makeStyles((theme) => ({
}));

export default function PVBrowserRecorder(props) {

    const { hOnError, hOnSpeech, hOnSpeechError, hOnReset, hDisabled } = props;
    const [isCameraError, setCameraError] = useState(false);
    const [isCameraAvailable, setCameraAvailable] = useState(false);
    const [isRecording, setRecording] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [renderWebcam, setRenderWebcam] = useState(true);
    const [videoBlob, setVideoBlob] = useState(null);
    const [videoUrlBlob, setVideoUrlBlob] = useState(null);
    const [didProcessRecording, setDidProcessRecording] = useState(false);
    const [sstResult, setSstResult] = useState('');
    const [isSstError, setSstError] = useState(false);
    const [wasValidated, setWasValidated] = useState(false);
    const requiresApollo = PVSpeechProcessor.isSpeechRecognitionAvailable() === false;
    const webcamRef = React.useRef(null);
    const mediaRecorderRef = React.useRef(null);
    const videoRef = React.useRef(null);

    PVSpeechProcessor.onSpeech = () => stopRecording();

    let recordingChunks = [];

    const retryCamera = () => {
        setRenderWebcam(false);
    }

    useEffect(() => {
        if (renderWebcam !== false)
            return;

        setCameraError(false);
        // Dar un tiempo para que se remueva el canvas de la webcam.
        setTimeout(() => setRenderWebcam(true), 500);
    }, [renderWebcam]);


    /**
     * Inicia la grabación de video y speech en cliente.
     */
    const startRecording = async () => {
        setCameraError(false);
        // Si pulsa "volver a intentar"... devolver al estado original.
        if (videoUrlBlob !== null) {
            setVideoBlob(null);
            setVideoUrlBlob(null);
            hOnReset();
            return;
        }
        recordingChunks = [];
        // Determinar si hay media recorder disponible. Si no, crear uno.
        try {
            mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
                mimeType: 'video/webm',
            });
        } catch (e) {
            setCameraError(true);
            return;
        }

        mediaRecorderRef.current.ondataavailable = (ev) => {
            if (ev.data.size > 0)
                recordingChunks.push(ev.data);
        }

        mediaRecorderRef.current.onstop = handleStopRecording;
        mediaRecorderRef.current.start(200);
        setRecording(true);
        setVideoBlob(null);
        setVideoUrlBlob(null);
        PVSpeechProcessor.startRecognition();
    }

    /**
     * Este metodo se invoca por el media recorder, al detener una grabacion.
     */
    const handleStopRecording = async () => {
        const blob = new Blob(recordingChunks, {
            type: 'video/webm',
        });
        setVideoBlob(blob);
        setVideoUrlBlob(URL.createObjectURL(blob));

        if (requiresApollo) {
            // Si se requiere Apollo, desactivar carga.
            setLoading(false);
        }
    }

    /**
     * Actualiza el blob de video para reproducir localmente.
     */
    useEffect(() => {
        if (videoUrlBlob === null || videoRef.current.src === videoUrlBlob)
            return;


        videoRef.current.load();
        videoRef.current.src = videoUrlBlob;
        videoRef.current.play();

    });

    useEffect(() => {
        if (isSstError || videoUrlBlob === null || requiresApollo || !sstResult || !sstResult.length && !didProcessRecording)
            return;


        // Si se esta utilzando el SST del navegador y ya paso el check, procesar
        // console.log({ requiresApollo, sstResult, isSstError, isLoading });
        setDidProcessRecording(true);
        processRecording();
    })

    /**
     * Detiene la grabación.
     */
    const stopRecording = async () => {
        if (didProcessRecording)
            return;

        setDidProcessRecording(true);
        try {
            mediaRecorderRef.current.stop();
        } catch (e) { }

        setRecording(false);
        setLoading(true);
        setWasValidated(false);

        const sst_result = await PVSpeechProcessor.stopRecognition();
        setSstResult(sst_result);
        console.log({ sst_result });

        /**
         * Si el Speech To Text ha fallado localmente y no se necesita del backend 
         * (es decir, se puede reconocer en el mismo navegador del cliente), 
         * entonces mostrar un error.
         */
        if (!requiresApollo) {
            setSstError(true);
            if (sst_result.length <= 0) {
                hOnSpeechError(sst_result);
            }

            // Validar en microservicio si el speech es valido.
            try {
                const resp = await Cdo.CdoFirmantes.isValidPhrase(sst_result);
                if (!resp || resp.ok !== true)
                    throw "Invalid valid phrase response.";


                setSstError(false);
            } catch (e) {
                setSstError(true);
                hOnSpeechError(sst_result);
            } finally {
            }
        }

        setLoading(false);
        setDidProcessRecording(false);

    }

    const processRecording = () => {
        if (isLoading || didProcessRecording) {
            return;
        }


        if (videoBlob === null) {
            hOnError('Debe grabar una prueba de vida para continuar.');
            setLoading(false);
            setRecording(false);
            return;
        }

        console.log({ didProcessRecording });
        hOnSpeech({
            videoBlobUrl: URL.createObjectURL(videoBlob),
            sstResult,
            requiresApollo,
        })
        setWasValidated(true);
    }

    const sendBtnDisabled = wasValidated || hDisabled || isLoading || sstResult.length <= 0 && !requiresApollo;
    return (
        <React.Fragment>
            <PVNoCameraAvailableDialog show={isCameraError} onReturn={props.hOnReturn} onRetry={retryCamera} />
            <center>
                <img
                    src={rec}
                    style={{ display: isRecording ? 'block' : 'none' }}
                    alt="logo"
                    width="80"
                    height="30"
                />
                <div style={{ display: 'block', width: '100%', height: '1px', marginTop: '2rem' }}></div>
                {!isRecording ?
                    <Button
                        variant="contained"
                        color={videoUrlBlob === null ? 'primary' : 'secondary'}
                        component="span"
                        onClick={startRecording}
                        disabled={isLoading || hDisabled || isCameraError || !renderWebcam || !isCameraAvailable}
                    >{videoUrlBlob === null ? 'INICIAR GRABACIÓN' : 'VOLVER A INTENTAR'}</Button>
                    :
                    <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        onClick={stopRecording}
                        disabled={isLoading}
                    >DETENER GRABACIÓN</Button>
                }
                {videoUrlBlob !== null && requiresApollo ?
                    <Button
                        style={{
                            marginLeft: '2rem',
                            backgroundColor: !sendBtnDisabled ? green[500] : undefined,
                            color: !sendBtnDisabled ? 'white' : undefined,
                        }}
                        variant="contained"
                        color="success"
                        component="span"
                        disabled={sendBtnDisabled}
                        onClick={processRecording}>VALIDAR</Button>
                    :
                    null
                }
                <div style={{ display: 'block', width: '100%', height: '1px', marginTop: '2rem' }}></div>
                {renderWebcam ? <Webcam
                    audio={true}
                    muted="muted"
                    ref={webcamRef}
                    onUserMedia={() => { setCameraAvailable(true) }}
                    onUserMediaError={() => { setCameraError(true) }}
                    videoConstraints={{
                        facingMode: 'user'
                    }}
                    style={{
                        display: videoUrlBlob === null ? "block" : "none",
                        marginLeft: "auto",
                        marginRight: "auto",
                        left: 0,
                        right: 0,
                        textAlign: "center",
                        zindex: 9,
                        width: "90%",
                        height: 400,
                        backgroundColor: !isCameraAvailable ? 'rgba(0,0,0,0.2)' : null,
                    }}
                /> : null}
                <video
                    ref={videoRef}
                    style={{
                        display: videoUrlBlob !== null ? "block" : "none",
                        marginLeft: "auto",
                        marginRight: "auto",
                        left: 0,
                        right: 0,
                        textAlign: "center",
                        zindex: 9,
                        width: "90%",
                        height: 400,
                    }}
                    controls
                ></video>
            </center>
        </React.Fragment >
    );
}