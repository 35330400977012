/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import MaterialTable from "material-table";
import PauseIcon from "@material-ui/icons/Pause";
import InfoIcon from "@material-ui/icons/Info";
import EditIcon from "@material-ui/icons/Edit";
import GetAppIcon from "@material-ui/icons/GetApp";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import SettingsIcon from "@material-ui/icons/Settings";
import LibraryAddCheckIcon from "@material-ui/icons/LibraryAddCheck";
import DomainIcon from "@material-ui/icons/Domain";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import { Grid } from "@material-ui/core";
import axios from "axios";
import localization from "components/localization/localization";
import Dialog from "@material-ui/core/Dialog";
import { DialogComp } from "components/Dialog/DialogComp";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  iconSize: {
    // fontSize: "15px",
    color: "#9c27b0",
    // padding: "12px"2
  },
  rootGrid: {
    flexGrow: 1,
  },
  paperGrid: {
    padding: theme.spacing(0.5),
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export default function FechaCierta() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [docuDataLoading, setDocuDataLoading] = useState(false);
  const [valueState, setValueState] = React.useState(1);
  const [headerColor, setHeaderColor] = React.useState("warning");
  const [dataActasPendientes, setdataActasPendientes] = useState([]);
  const [dataActasProcess, setdataActasProcess] = useState([]);
  const [newData, setnewData] = useState({
    actaId: "",
    actaConfirm: "",
    actaName: "",
    actaDescripcion: "",
  });
  const [openZip, setOpenZip] = useState(false);
  const [editData, setEditData] = useState({
    openEditForm: false,
    dataEditInfo: [],
  });
  const [rowInfo, setRowInfo] = useState({
    openDialogInfo: false,
    dataRow: [],
  });

  const { openDialogInfo, dataRow } = rowInfo;
  const { openEditForm, dataEditInfo } = editData;
  const { actaId, actaConfirm, actaName, actaDescripcion } = newData;

  useEffect(() => {
    getActas("pending");
  }, []);

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

  const handleOpenInfo = (rowData) => {
    setRowInfo({
      openDialogInfo: true,
      dataRow: rowData,
    });
  };
  const handleCloseInfo = () => {
    setRowInfo({
      openDialogInfo: false,
      dataRow: [],
    });
  };

  const handleCloseEditInfo = () => {
    setEditData({
      openEditForm: false,
      dataEditInfo: [],
    });
  };

  const handleInput = (event) => {
    setnewData({
      ...newData,
      [event.target.name]: event.target.value,
    });
    // console.log("datos", datos);
    // console.log("radios", value);
  };

  const handleChangeData = () => {
    setDocuDataLoading(true);
    let token = sessionStorage.getItem("Token");
    let endpoint = `${process.env.REACT_APP_URL}api/actas?id=${actaId}?correduria_id=${actaId}?correduria_id_confirmation=${actaConfirm}?name=${actaName}?description=${actaDescripcion}`;

    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
      })
      .then(async (resp) => {
        // setShowData(resp.data);
        getActas("pending");
        console.log(resp.data, "Mostrar acta api info");
        setDocuDataLoading(false);
      })
      .catch((err) => {
        console.log("Error Axios", err);
        setDocuDataLoading(false);
      });
  };

  const getActas = (filter) => {
    setDocuDataLoading(true);
    let token = sessionStorage.getItem("Token");
    let endpoint = `${process.env.REACT_APP_URL}api/actas`;
    if (filter) {
      endpoint += `?status=${filter}`;
    }
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
      })
      .then(async (resp) => {
        if (filter == "pending") {
          setdataActasPendientes(resp.data);
        } else {
          setdataActasProcess(resp.data);
        }
        setDocuDataLoading(false);
      })
      .catch((err) => {
        console.log("Error Axios", err);
        setDocuDataLoading(false);
      });
  };

  const handleGetData = (rowData) => {
    // const actaId = rowData.id;
    console.log(rowData, "ROW DATA ");
    setEditData({
      openEditForm: true,
      dataEditInfo: rowData,
    });

    // console.log(rowData, "Curent Data");
    // setDocuDataLoading(true);
    // let token = sessionStorage.getItem("Token");
    // let endpoint = `${process.env.REACT_APP_URL}api/actas?id=${actaId}`;
    // axios
    //   .get(endpoint, {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //     data: {},
    //   })
    //   .then(async (resp) => {
    //     setnewData({
    //       actaId: "",
    //       actaConfirm: "",
    //       actaName: "",
    //       actaDescripcion: "",
    //     });
    //     console.log(resp.data, "Mostrar acta api info");
    //     setDocuDataLoading(false);
    //   })
    //   .catch((err) => {
    //     console.log("Error Axios", err);
    //     setDocuDataLoading(false);
    //   });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeTabs = (event, value) => {
    setValueState(value);
    if (value == 0) {
      getActas("pending");
      setHeaderColor("warning");
    } else if (value == 1) {
      getActas("processed");
      setHeaderColor("success");
    }
  };

  //funcion para descargar adjuntos
  const getZipAnexos = (rowData) => {
    const fileName = rowData.id;
    const nameFormat = fileName.slice(0, -4);
    setOpenZip(true);
    console.log(nameFormat, "nameFormat");
    let token = sessionStorage.getItem("Token");
    axios({
      url: `${process.env.REACT_APP_URL}api/actas/${rowData.id}/download-zip`,
      method: "GET",
      responseType: "blob", // important
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${nameFormat}-download.zip`);
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
          setOpenZip(false);
        }, 5000);
      })
      .catch((error) => {
        console.error(error);
        setTimeout(() => {
          setOpenZip(false);
        }, 5000);
      });
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          // variant="scrollable"
          variant="fullWidth"
          scrollButtons="off"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <Tab
            wrapped
            label="Documentos en proceso"
            icon={<SettingsIcon />}
            {...a11yProps(0)}
          />
          <Tab
            wrapped
            label="Documentos Certificados"
            icon={<LibraryAddCheckIcon />}
            {...a11yProps(1)}
          />
          <Tab
            wrapped
            label="Razones Sociales"
            icon={<DomainIcon />}
            {...a11yProps(2)}
          />
          <Tab
            wrapped
            label="Actas"
            icon={<CollectionsBookmarkIcon />}
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        Documentos en proceso
      </TabPanel>
      <TabPanel value={value} index={1}>
        Documentos Certificados
      </TabPanel>
      <TabPanel value={value} index={2}>
        Razones Sociales
      </TabPanel>
      <TabPanel value={value} index={3}>
        <CustomTabs
          handleChange={handleChangeTabs}
          valueState={valueState}
          headerColor={headerColor}
          title=""
          tabs={[
            {
              tabName: `Pendientes ${dataActasPendientes.length}`,
              tabIcon: PauseIcon,
              tabContent: (
                <React.Fragment>
                  <Grid
                    container
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <MaterialTable
                        localization={localization}
                        options={{
                          exportButton: {
                            csv: true,
                            pdf: false,
                          },
                          exportAllData: true,
                          draggable: false,
                          pageSize: 20, // make initial page size
                          pageSizeOptions: [20, 50, 100, 500, 1000],
                          actionsColumnIndex: -1,
                        }}
                        isLoading={docuDataLoading}
                        columns={[
                          {
                            title: "Acta Correduria",
                            // field: "id",
                          },
                          {
                            title: "Registros",
                            field: "register_count",
                          },
                          {
                            title: "Creado",
                            field: "created_at",
                            type: "date",
                          },
                          {
                            title: "Procesado",
                            field: "processed",
                          },
                          {
                            title: "Registro Federal",
                            field: "corporacion.rfc",
                          },
                          {
                            title: "Compañia",
                            field: "corporacion.name",
                          },
                        ]}
                        // isLoading={docuDataLoading}
                        data={dataActasPendientes}
                        title={
                          "Actas Pendientes (" +
                          dataActasPendientes.length +
                          ")"
                        }
                        actions={[
                          () => ({
                            icon: () => (
                              <InfoIcon className={classes.iconSize} />
                            ),
                            tooltip: "Detalles",
                            onClick: (event, rowData) => {
                              handleOpenInfo(rowData);
                            },
                          }),
                          () => ({
                            icon: () => (
                              <EditIcon className={classes.iconSize} />
                            ),
                            tooltip: "Modificar Acta",
                            onClick: (event, rowData) => {
                              handleGetData(rowData);
                            },
                          }),
                          {
                            icon: () => (
                              <GetAppIcon className={classes.iconSize} />
                            ),
                            tooltip: "Descargar Acta",
                            onClick: (event, rowData) => {
                              getZipAnexos(rowData);
                            },
                          },
                        ]}
                      />
                    </div>
                  </Grid>
                </React.Fragment>
              ),
            },
            {
              tabName: "Procesadas (" + dataActasProcess.length + ")",
              tabIcon: LibraryAddCheckIcon,
              tabContent: (
                <React.Fragment>
                  <Grid
                    container
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <MaterialTable
                        localization={localization}
                        options={{
                          exportButton: {
                            csv: true,
                            pdf: false,
                          },
                          exportAllData: true,
                          draggable: false,
                          pageSize: 20, // make initial page size
                          pageSizeOptions: [20, 50, 100, 500, 1000],
                          actionsColumnIndex: -1,
                        }}
                        columns={[
                          {
                            title: "Acta Correduria",
                            // field: "id",
                          },
                          {
                            title: "Registros",
                            field: "register_count",
                          },
                          {
                            title: "Creado",
                            field: "created_at",
                            type: "date",
                          },
                          {
                            title: "Procesado",
                            field: "processed",
                          },
                          {
                            title: "Registro Federal",
                            field: "corporacion.rfc",
                          },
                          {
                            title: "Compañia",
                            field: "corporacion.name",
                          },
                        ]}
                        isLoading={docuDataLoading}
                        data={dataActasProcess}
                        title={
                          "Actas Procesadas (" + dataActasProcess.length + ")"
                        }
                      // actions={[
                      //   (rowData) => ({
                      //     icon: () => (
                      //       <GestureIcon
                      //         className={
                      //           classes2.tableActionButtonIcon +
                      //           " " +
                      //           classes2.edit
                      //         }
                      //       />
                      //     ),
                      //     tooltip: "Firmar",
                      //     onClick: (event, rowData) => {
                      //       if (rowData.date_signed) {
                      //         alert(
                      //           "Ud. ya firmó este documento, esta pendiente alguno de los otros firmantes"
                      //         );
                      //       } else {
                      //         const isLogged = true;
                      //         switch (rowData.documentos.type.id) {
                      //           case 1:
                      //             console.log("SIMPLE");
                      //             // window.open(
                      //             //   `https://con-certeza.mx/sign/${rowData.documentos._id}/${rowData._id}`
                      //             // );

                      //             // /sign/miFirma/:idDoc/:idfirmante/:isLogged

                      //             // window.location.href = `/sign/miFirma/${rowData.documentos._id}/${rowData._id}/${isLogged}`;

                      //             history.push(
                      //               `/sign/miFirma/${rowData.documentos._id}/${rowData._id}/${isLogged}`
                      //             );
                      //             break;
                      //           case 2:
                      //             console.log("SIMPLE+ID");
                      //             // window.open(
                      //             //   `https://con-certeza.mx/signid/${rowData.documentos._id}/${rowData._id}`
                      //             // );
                      //             history.push(
                      //               `/signid/miFirma/${rowData.documentos._id}/${rowData._id}/${isLogged}`
                      //             );
                      //             break;
                      //           case 3:
                      //             console.log("FIEL");
                      //             //window.open(`https://firma.certificaciondigital.online`);
                      //             //setModalCDO(true);
                      //             handleFirmaCDO(
                      //               rowData.documentos.cdo_contratos_id
                      //             );
                      //             break;
                      //           case 8:
                      //             console.log("SIMPLE SIN SMS");
                      //             // window.open(
                      //             //   `https://con-certeza.mx/sign/${rowData.documentos._id}/${rowData._id}`
                      //             // );

                      //             history.push(
                      //               `/sign/miFirma/${rowData.documentos._id}/${rowData._id}/${isLogged}`
                      //             );
                      //             break;
                      //           case 12:
                      //             console.log("FIEL");
                      //             history.push(
                      //               `/sign/miFirma/${rowData.documentos._id}/${rowData._id}/${isLogged}`
                      //             );
                      //             break;
                      //           case 13:
                      //             if (rowData.id_required) {
                      //               history.push(
                      //                 `/signid/miFirma/${rowData.documentos._id}/${rowData._id}/${isLogged}`
                      //               );
                      //             } else {
                      //               history.push(
                      //                 `/sign/miFirma/${rowData.documentos._id}/${rowData._id}/${isLogged}`
                      //               );
                      //             }
                      //             break;
                      //           default:
                      //             console.log("NO SOPORTADO");
                      //             history.push(
                      //               `/sign/miFirma/${rowData.documentos._id}/${rowData._id}/${isLogged}`
                      //             );
                      //         }
                      //       }
                      //     },
                      //     disabled: rowData.documentos.type.id === 4,
                      //   }),

                      //   {
                      //     icon: () => (
                      //       <VisibilityIcon
                      //         className={
                      //           classes2.tableActionButtonIcon +
                      //           " " +
                      //           classes2.edit
                      //         }
                      //       />
                      //     ),
                      //     tooltip: "Visualizar PDF",
                      //     onClick: (event, rowData) => {
                      //       handleOpenPDF(rowData.documentos._id);
                      //     },
                      //   },
                      //   {
                      //     icon: () => (
                      //       <PeopleIcon
                      //         className={
                      //           classes2.tableActionButtonIcon +
                      //           " " +
                      //           classes2.edit
                      //         }
                      //       />
                      //     ),
                      //     tooltip: "Participantes",
                      //     onClick: (event, rowData) => {
                      //       //console.log(rowData)
                      //       // switch (rowData.documentos.type.id) {
                      //       //   case 1:
                      //       //     setListaFirmantes(
                      //       //       rowData.documentos.firmantes
                      //       //     );
                      //       //     setModalFirmantesCC(true);
                      //       //     break;
                      //       //   case 2:
                      //       //     setListaFirmantes(
                      //       //       rowData.documentos.firmantes
                      //       //     );
                      //       //     setModalFirmantesCC(true);
                      //       //     break;
                      //       //   case 3:
                      //       //     setContratosID({
                      //       //       cc: rowData.documentos._id,
                      //       //       cdo: rowData.documentos.cdo_contratos_id,
                      //       //     });
                      //       //     bucarFirmantesCDO(
                      //       //       rowData.documentos.cdo_contratos_id
                      //       //     );
                      //       //     setModalFirmantes(true);
                      //       //     break;
                      //       //   case 8:
                      //       //     setListaFirmantes(
                      //       //       rowData.documentos.firmantes
                      //       //     );
                      //       //     setModalFirmantesCC(true);
                      //       //     break;
                      //       //   case 12:
                      //       //     setListaFirmantes(
                      //       //       rowData.documentos.firmantes
                      //       //     );
                      //       //     setModalFirmantesCC(true);
                      //       //     break;
                      //       //   case 13:
                      //       //     setListaFirmantes(
                      //       //       rowData.documentos.firmantes
                      //       //     );
                      //       //     setModalFirmantesCC(true);
                      //       //     break;
                      //       //   default:
                      //       //     console.log("NO SOPORTADO");
                      //       // }
                      //     },
                      //   },
                      // ]}
                      />
                    </div>
                  </Grid>
                </React.Fragment>
              ),
            },
          ]}
        />
      </TabPanel>
      <>
        <Dialog fullWidth open={openEditForm}>
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleCloseEditInfo}
          >
            Modificar Acta
          </DialogTitle>
          <DialogContent dividers>
            <div className={classes.rootGrid} style={{ overflowX: "hidden" }}>
              <Grid container spacing={5}>
                <Grid item style={{ padding: "12px 20px" }} xs={12}>
                  <div className={classes.paperGrid}>
                    <Typography gutterBottom>
                      <b>ID Reporte :</b>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      label="Reporte"
                      id="outlined-size-small"
                      defaultValue={dataEditInfo.id}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                </Grid>
                <Grid item style={{ padding: "8px 20px" }} xs={12}>
                  <div className={classes.paperGrid}>
                    <Typography gutterBottom>
                      <b>Expediente :</b>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      label="Expediente"
                      id="outlined-size-small"
                      defaultValue={dataEditInfo.folder}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                </Grid>
                <Grid item style={{ padding: "8px 20px" }} xs={6}>
                  <div className={classes.paperGrid}>
                    <Typography gutterBottom>
                      <b>Acta Correduría :</b>{" "}
                    </Typography>
                    <TextField
                      label="Acta *"
                      id="outlined-size-small"
                      variant="outlined"
                      size="small"
                      name="actaId"
                      onChange={handleInput}
                      value={actaId}
                    />
                  </div>
                </Grid>
                <Grid item style={{ padding: "8px 20px" }} xs={6}>
                  <div className={classes.paperGrid}>
                    <Typography gutterBottom>
                      <b>Confirmar Acta Correduría :</b>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      label="Confirmar Acta *"
                      id="outlined-size-small"
                      variant="outlined"
                      size="small"
                      name="actaConfirm"
                      onChange={handleInput}
                      value={actaConfirm}
                    />
                  </div>
                </Grid>
                <Grid item style={{ padding: "8px 20px" }} xs={12}>
                  <div className={classes.paperGrid}>
                    <Typography gutterBottom>
                      <b>Nombre :</b>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      label="Nombre"
                      id="outlined-size-small"
                      variant="outlined"
                      size="small"
                      name="actaName"
                      onChange={handleInput}
                      value={actaName}
                    />
                  </div>
                </Grid>
                <Grid item style={{ padding: "8px 20px" }} xs={12}>
                  <div className={classes.paperGrid}>
                    <Typography gutterBottom>
                      <b>Descripción :</b>{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      label="Descripción"
                      id="outlined-size-small"
                      variant="outlined"
                      size="small"
                      name="actaDescripcion"
                      onChange={handleInput}
                      value={actaDescripcion}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCloseEditInfo} color="secondary">
              Cancelar
            </Button>
            <Button
              autoFocus
              disabled
              onClick={handleChangeData}
              color="primary"
            >
              Guardar Cambios
            </Button>
          </DialogActions>
        </Dialog>

        <DialogComp
          open={openZip}
          title="Descarga entregables finales"
          content=" La descarga se realizará en el lugar habitual de descargas. Es posible que esta acción tarde unos segundos."
          grid={true}
        />
        <DialogComp
          open={openDialogInfo}
          title=" Detalle Acta"
          content={
            <div>
              <b>ID Reporte :</b> {dataRow.id}
              <br></br>
              <hr></hr>
              <b>Expediente:</b> {dataRow.id}
              <br></br>
              <hr></hr>
              <b>Acta Correduria:</b> {dataRow.id}
              <br></br>
              <hr></hr>
              <b>Nombre:</b> {dataRow.id}
              <br></br>
              <hr></hr>
              <b>Descripción :</b> {dataRow.id}
              <br></br>
              <hr></hr>
              <b>Confirmar Acta Correduría:</b> {dataRow.id}
              <br></br>
              <hr></hr>
            </div>
          }
          buttons={[
            {
              buttonEvent: handleCloseInfo,
              buttonName: "Cerrar",
              buttonColor: "secondary",
            },
          ]}
        />
      </>
    </div>
  );
}
