import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
// import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import ChangeDocument from "./carga";
import Autofirma from "./autofirma";
// import FormConfirm from "./confirm";
import Review from "./Review";

import firmasimple from "assets/img/firmaSimple2.png";
import firmacerti from "assets/img/NEWFIRMACONIDE.png";
import firmaAvanzada from "assets/img/firmaFiel.png";
import AUTOFIRMA from "assets/img/logoAutoFirma.png";
import FIRMARAPIDA from "assets/img/firmasincodigo.png";
import Grid from "@material-ui/core/Grid";

import {
  Avatar,
  FormControl,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Radio,
  RadioGroup,
  Tooltip,
} from "@material-ui/core";
import Components from "containers/CheckoutNew";

// import Previewpdf from "./Preview";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
// import { isMappedTypeNode } from "typescript";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import { green } from "@material-ui/core/colors";
import GestureIcon from "@material-ui/icons/Gesture";
import clsx from "clsx";
import Check from "@material-ui/icons/Check";
import FlipToFrontIcon from "@material-ui/icons/FlipToFront";
import PublishIcon from "@material-ui/icons/Publish";

import PropTypes from "prop-types";
import StepConnector from "@material-ui/core/StepConnector";
// import DialpadIcon from "@material-ui/icons/Dialpad";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";

import Preview from "./Preview";

import { useSelector } from "react-redux";
import { DialogComp } from "components/Dialog/DialogComp";
import Firma from "containers/Plantillas/Firma";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      " linear-gradient(79deg, rgba(76,175,80,1) 0%, rgba(102,187,106,1) 63%, rgba(92,184,96,1) 100%)",
  },
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 700,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "relative",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgress2: {
    color: green[500],
    alignItems: "center",
    top: "50%",
    left: "50%",
    marginTop: 12,
    marginLeft: 12,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const steps = ["Carga", "Coloca ", "Firma documento", , "Firmado"];

export default function ContainerAutoSign(props) {
  const history = useHistory();
  const classes = useStyles();
  const { user } = useSelector((state) => state.auth);
  console.log("DATOSUSERS ", user);
  const [activeStep, setActiveStep] = useState(0);
  const [progress, setprogress] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [idDocumento, setidDocumento] = useState("");
  const [progrees, setProgrees] = useState(false);
  const [idfirma, setidFirma] = useState("");
  const [success, setSuccess] = useState(false);
  const [idFirmante, setIdFirmante] = useState("");
  const [idDocumentoFirmado, setidDocumentoFirmado] = useState("");
  /*arreglo para almacenar el objeto coordenadas*/
  const [coordenadasFirma, setCoordenadasFirma] = useState([{}]);
  /*indicador para saber si estoy cargando un nuevo firmante*/
  const [nuevoFirmante, setNuevoFirmante] = useState(true);
  const [successRegis, setSuccesRegis] = useState(false);
  const [tokenstate, setTokenstate] = useState("");
  const [openDelete, setopenDelete] = useState(false);
  const [displaySign, setDisplaySign] = useState(true);
  //referencia para saber si viene de registrado

  const [firma, setfirma] = useState(true);
  const [registrado, setRegistrado] = useState(false);

  const {
    tabs,
    idDocumentotable,
    idprocces,
    idDocumentoDetenido,
    setOpenForm,
    setActiveRender,
    currentStep,
    idStatus,
  } = props;

  const [datos, setDatos] = useState({
    nombre: "",
    apellidos: "",
    correo: "",
    codigo: "52",
    celular: "",
    rol: "",
    Compañia: "",
    notificacion: "",
  });

  // Asignar nuevo tipo de proceso de firma

  const [isAnotherType, setisAnotherType] = useState(false);
  const [isOpenEdit, setisOpenEdit] = useState(true);

  const [newAssignProcess, setNewAssign] = useState({
    openNewAssignProcess: false,
    currentIdProcess: Number(idprocces),
    valueAssignNew: 0,
    disabledAssign: true,
  });

  const {
    openNewAssignProcess,
    currentIdProcess,
    disabledAssign,
    valueAssignNew,
  } = newAssignProcess;

  const setFirmado = () => {
    handleNext();
    setSuccess(false);
  };

  const handleDialog = () => {
    setActiveStep(1);
  };

  var arrayfirmante = [];
  React.useEffect(() => {
    console.log(currentStep, "Current Step Olv");

    if (currentStep && currentStep != "") {
      setidDocumento(idDocumentotable);
      setActiveStep(currentStep);
    }
    let token = sessionStorage.getItem("Token");
    setTokenstate(token);
    setDatos({
      ...datos,
      nombre: user.name,
      apellidos: user.name,
      correo: user.email,
      codigo: "52",
      celular: user.phone,
      rol: "Cliente",
      Compañia: user.company,
      notificacion: "1",
    });
    arrayfirmante.push(datos);
    if (tabs == "1") {
      handleDialog();
      setidDocumento(idDocumentotable);
    }
  }, []);

  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
      1: <PublishIcon />,
      2: <FlipToFrontIcon />,
      3: <GestureIcon />,
      4: <AssignmentTurnedInIcon />,
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {completed ? <Check /> : icons[String(props.icon)]}
      </div>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };
  const topmt = 430;
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <ChangeDocument onChangePDF={onChangePDF} progress={progress} />;
      case 1:
        return (
          <Preview
            idDocumento={idDocumento}
            nombre={datos.nombre}
            setCoordenadasFirma={setCoordenadasFirma}
            setNuevoFirmante={setNuevoFirmante}
            nuevoFirmante={nuevoFirmante}
            displaySign={displaySign}
            topmt={topmt}
            user={user}
            idFirmante={idFirmante}
            setIdFirmante={setIdFirmante}
            tabs={tabs}
            setRegistrado={setRegistrado}
            setfirma={setfirma}
            setopenDelete={setopenDelete}
            handleInitProcess={handleInitProcess}
          // arrayFirmantes={arrayfirmante}
          // clickFijar={clickFijar}
          />
        );
      case 2:
        return <Autofirma imageB64={imageB64} progrees={progrees} />;
      case 3:
        return (
          <Review
            idDocumentoFirmado={idDocumentoFirmado}
            setActiveRender={setActiveRender}
            registrado={registrado}
          />
        );

      default:
        throw new Error("Unknown step");
    }
  };

  const handleInitProcess = () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenstate}`,
        Accept: " application/json",
      },
    };

    const urlInit = `${process.env.REACT_APP_URL}api/documentos/${idDocumento}/launch`;
    axios.put(urlInit, {}, config).then((response) => {
      setFirmado();
      setSuccesRegis(false);
      setisOpenEdit(false);
      console.log("RES DE INIT", response);
    });
  };

  const handleDelete = () => {
    let urlDeleteDoc = `${process.env.REACT_APP_URL}api/documentos/${idDocumento}`;

    axios
      .delete(urlDeleteDoc, {
        headers: {
          Authorization: `Bearer ${tokenstate}`,
        },
        data: {},
        // receive two parameter endpoint url ,form data
      })
      .then((response) => {
        // Respuesta del servidor
        console.log("respuest a data", response);
        setActiveStep(0);
        setopenDelete(false);
      })
      .catch((error) => {
        // Error 😨
        if (error.response) {
          console.log(error.response.data.message);
          openAlert(error.response.data.message);
        } else if (error.request) {
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  // alert functions
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const openAlert = (error) => {
    setOpenSnack(true);
    setMessageError(error);
  };

  const sendDocument = () => {
    setTimeout(handleNext, 500);
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenstate}`,
      Accept: " application/json",
    },
  };

  function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  const imageB64 = async (firma) => {
    const url = `${process.env.REACT_APP_URL}api/firmas`;
    const token = sessionStorage.getItem("Token");
    let base64 = dataURLtoBlob(firma);
    console.log("objectidFirm", idFirmante);

    const data = new FormData();
    data.append("graphic_signature", base64);
    data.append("firmantes_id", idFirmante);

    // if (base64.length >= 2001) {
    setProgrees(true);
    await axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("successds", response.data);

        let datares = response.data[1];
        console.log("success", datares._id);

        setidFirma(datares._id);
        let pin = datares.pin;
        let firma = datares._id;

        setTimeout(confirmarSinCodigo(firma, pin), 1000);
      })
      .catch((err) => {
        setProgrees(false);
        console.log("Error servidor", err.response.data.message);
        alert(err.response.data.message);
      });
    // }
  };

  function confirmarSinCodigo(firma, pin) {
    console.log("IDFIRMA", firma, pin);
    setSuccess(true);
    const token = sessionStorage.getItem("Token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: " application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const urlInit = `${process.env.REACT_APP_URL}api/firmas/${firma}/confirm?pin=${pin}`;
    console.log("ULRINIT CON ID", urlInit);
    axios
      .put(urlInit, {}, config)
      .then(async (response) => {
        //Guardar log confirmar
        //buscar datos firmante

        //fin log
        console.log("RES DE CONFIRM", response);
        let data = response.data[0];
        console.log("RES DATA", data);
        console.log("dataID documento", data.documentos_id);
        const idDocumentofirmado = data.documentos_id;
        console.log("iddocumetovar", idDocumentofirmado);
        await setidDocumentoFirmado(idDocumentofirmado);
        setTimeout(handleNext, 2000);
        setTimeout(setProgrees(false), 2000);
      })
      .catch((error) => {
        // Error 😨
        setSuccess(false);

        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data.message);
          alert(error.response.data.message);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  }

  const onChangePDF = async (idClases) => {
    const data = new FormData();
    setprogress(true);
    var file = document.querySelector('input[type="file"]').files[0];
    data.append("filename", file);
    data.append("documento_types_id", 7);
    console.log("fiLE", data);
    let url = `${process.env.REACT_APP_URL}api/documentos`;
    if (idClases) {
      data.append("fc_clase_id", idClases)
    }
    axios
      .post(url, data, config, {
        // receive two parameter endpoint url ,form data
      })
      .then((response) => {
        // Respuesta del servidor
        let data = response.data[0];
        setidDocumento(data.id);
        sendDocument();
        setprogress(false);
      })
      .catch((error) => {
        // Error 😨
        if (error.response.data.errors) {
          openAlert("El archivo debe ser de tipo PDF sin ninguna encriptacion");
        } else if (error.response.data.message) {
          openAlert(error.response.data.message);
        } else {
          openAlert("Su documento tiene algun problema");
        }

        setprogress(false);
      });
  };

  // const handleBack = () => {
  //   setActiveStep(activeStep - 1);
  // };
  const handleVolver = () => {
    let uri = localStorage.getItem("uri");
    if (setActiveRender) {
      setActiveRender(0);
    } else {
      history.push(`/${uri}/nuevoProceso`);
    }
  };

  const handleCloseAssign = () => {
    setNewAssign({
      ...newAssignProcess,
      openNewAssignProcess: false,
      valueAssignNew: 0,
      disabledAssign: true,
    });
    // setCurrentStep("");
  };

  const handleOpenTypes = () => {
    setNewAssign({
      ...newAssignProcess,
      openNewAssignProcess: true,
    });
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    setNewAssign({
      ...newAssignProcess,
      valueAssignNew: Number(value),
      disabledAssign: false,
    });
  };

  const handleChangeProcess = () => {
    props.setProgrees(true);
    setNewAssign({
      ...newAssignProcess,
      openNewAssignProcess: false,
      disabledAssign: true,
    });
    let newValueAsign = valueAssignNew;

    let token = sessionStorage.getItem("Token");
    let endpoint = `${process.env.REACT_APP_URL}api/documentos/${idDocumentotable}?documento_types_id=${newValueAsign}`;
    var config = {
      method: "PUT",
      url: endpoint,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then(function (response) {
        props.setProgrees(false);
        setNewAssign({
          currentIdProcess: Number(newValueAsign),
          valueAssignNew: 0,
          disabledAssign: true,
          openNewAssignProcess: false,
        });
        setisAnotherType(true);
        // refreshTables();
        // receive two parameter endpoint url ,form data
      })
      .catch((error) => {
        // Error 😨
        props.setProgrees(false);
        if (error.response) {
          console.log(error.response, "Error =>");
          //openAlert("El archivo debe ser de tipo PDF sin ninguna encriptacion");
        } else if (error.response.data.message) {
          openAlert(error.response.data.message);
        } else {
          openAlert("Su documento tiene algun problema");
        }
      });
    //  handleOpenForm(rowDataAssign, valueAssignNew);
  };

  return (
    <React.Fragment>
      {console.log("objectidFirmstate", idFirmante)}
      {activeStep == 0 ? (
        <Button
          size="large"
          startIcon={<ArrowBackIcon />}
          onClick={() => handleVolver()}
        >
          Regresar
        </Button>
      ) : (
        ""
      )}

      {isAnotherType ? (
        <>
          <Components
            setProgrees={props.setProgrees}
            tabs={tabs}
            idDocumentotable={idDocumentotable}
            setOpenForm={setOpenForm}
            idprocces={currentIdProcess}
            idDocumentoDetenido={idDocumentoDetenido}
            idStatus={idStatus}
          />
        </>
      ) : (
        <>
          <main className={classes.layout} style={{ width: 550 }}>
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={10}>
                  <Typography component="h1" variant="h4" align="center">
                    AUTOFIRMA
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <div align="center">
                    {isOpenEdit &&
                      (idStatus == 1 ? (
                        <Tooltip title="Cambiar tipo de proceso de firma">
                          <IconButton
                            onClick={handleOpenTypes}
                            aria-label="change"
                          >
                            <EditIcon fontSize="large" />
                          </IconButton>
                        </Tooltip>
                      ) : null)}
                  </div>
                </Grid>
              </Grid>

              <DialogComp
                open={openNewAssignProcess}
                title={"Seleccione el proceso de firma "}
                content={
                  <>
                    <List className={classes.root}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="quiz"
                          name="quiz"
                          value={valueAssignNew}
                          onChange={handleRadioChange}
                        >
                          <ListItem>
                            <FormControlLabel value={1} control={<Radio />} />
                            <ListItemAvatar>
                              <Avatar>
                                <img
                                  src={firmasimple}
                                  alt="Con-Certeza"
                                  width="65"
                                  height="65"
                                />
                              </Avatar>
                            </ListItemAvatar>
                            <Tooltip
                              placement="top-start"
                              title="Solicita que el firmante confirme el proceso con un código de validación."
                            >
                              <ListItemText primary="FIRMA SIMPLE CON CONFIRMACIÓN POR CÓDIGO" />
                            </Tooltip>
                          </ListItem>
                          <ListItem>
                            <FormControlLabel value={2} control={<Radio />} />
                            <ListItemAvatar>
                              <Avatar>
                                <img
                                  src={firmacerti}
                                  alt="Con-Certeza"
                                  width="65"
                                  height="63"
                                />
                              </Avatar>
                            </ListItemAvatar>
                            <Tooltip
                              placement="top-start"
                              title="Solicita que el firmante adjunte su identificación oficial durante el proceso de firma."
                            >
                              <ListItemText primary="FIRMA SIMPLE CON IDENTIFICACIÓN" />
                            </Tooltip>
                          </ListItem>
                          <ListItem>
                            <FormControlLabel value={8} control={<Radio />} />
                            <ListItemAvatar>
                              <Avatar>
                                <img
                                  src={FIRMARAPIDA}
                                  alt="Con-Certeza"
                                  width="67"
                                  height="65"
                                />
                              </Avatar>
                            </ListItemAvatar>
                            <Tooltip
                              placement="top-start"
                              title="Solicita que el firmante confirme el proceso completando una casilla de verificación."
                            >
                              <ListItemText primary="FIRMA SIMPLE CONFIRMACIÓN CON CASILLA DE VERIFICACIÓN" />
                            </Tooltip>
                          </ListItem>
                        </RadioGroup>
                      </FormControl>
                    </List>
                  </>
                }
                maxWidth={"sm"}
                buttons={[
                  {
                    buttonEvent: handleCloseAssign,
                    buttonName: "Cancelar",
                    buttonColor: "secondary",
                  },
                  {
                    buttonDisabled: disabledAssign,
                    buttonEvent: handleChangeProcess,
                    buttonName: "Confirmar",
                    buttonColor: "primary",
                  },
                ]}
              />
              <Stepper
                activeStep={activeStep}
                className={classes.stepper}
                connector={<ColorlibConnector />}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              <React.Fragment>
                {activeStep === steps.length ? (
                  <React.Fragment>
                    <Typography variant="h5" gutterBottom>
                      Muchas gracias por firmar con nuestra plataforma
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      style={{
                        textTransform: "none",
                      }}
                    >
                      Su documento ha sido firmado lo podra consultar en la
                      opcion de mis firmas. tambien se enviara una notificacion
                      a su correo donde podra consultar su archivo y
                      descargarlo.
                    </Typography>
                  </React.Fragment>
                ) : (
                  <React.Fragment>{getStepContent(activeStep)}</React.Fragment>
                )}
              </React.Fragment>
            </Paper>
          </main>
          <div className={classes.root}>
            <Snackbar
              open={openSnack}
              autoHideDuration={9000}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              onClose={handleCloseSnack}
            >
              <Alert onClose={handleCloseSnack} severity="error">
                {messageError}
              </Alert>
            </Snackbar>
          </div>
          <Backdrop className={classes.backdrop} open={successRegis}>
            <Grid>
              <CircularProgress size={44} className={classes.buttonProgress} />
              <br></br>
              <Typography variant="body2" color="textSecondary" align="center">
                Espera un poco
              </Typography>
            </Grid>
          </Backdrop>
          {/* //!Dialogo estas seguro de eliminar documento */}
          <DialogComp
            open={openDelete}
            title="Eliminar Documento"
            content="¿Está seguro de eliminar este documento?"
            buttons={[
              {
                buttonEvent: () => setopenDelete(false),
                buttonName: "Cerrar",
                buttonColor: "secondary",
              },
              {
                buttonEvent: handleDelete,
                buttonName: "Eliminar",
                buttonColor: "primary",
              },
            ]}
          />
        </>
      )}

      {/* <Dialog
        // fullWidth={true}
        // maxWidth="md"

        open={openDelete}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Eliminar documento"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography variant="subtitle1" style={{ textTransform: "none" }}>
              ¿Está seguro de eliminar este documento?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setopenDelete(false)}>Cerrar</Button>
          <Button onClick={handleDelete} color="secondary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog> */}
    </React.Fragment>
  );
}
