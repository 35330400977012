import React, { useLayoutEffect, useState } from "react";
import { render } from "react-dom";
import { isMobile } from 'react-device-detect';
import axios from "axios";
import './style.css'; // css styling from the react-vis node module
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  makeVisFlexible,
  XYPlot,
  XAxis,
  YAxis,
  ChartLabel,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  VerticalBarSeriesCanvas,
  LabelSeries,
  RadialChart,
  Borders,
  Hint,
  LineSeries,
  Crosshair
} from 'react-vis';

var width = 600;
var height = 400;

if (isMobile) {
  var width = 350;
  var height = 300;
}

export default function GraficaPrincipal(props) {

  const [loading, setLoading] = React.useState(true);
  //const [status1, setStatus1] = React.useState([{x:0,y:0}]);
  const [status1, setStatus1] = React.useState([]);
  const [status2, setStatus2] = React.useState([]);
  const [status3, setStatus4] = React.useState([]);
  const [status4, setStatus3] = React.useState([]);
  const [dataLinea, setDataLinea] = React.useState([]);
  const [cuadro, setCuadro] = React.useState([]);
  /*
  const [label1, setLabel1] = React.useState("");
  const [totalMes1, setTotalMes1] = React.useState(0);
  const [label2, setLabel2] = React.useState("");
  const [totalMes2, setTotalMes2] = React.useState(0);
  const [label3, setLabel3] = React.useState("");
  const [totalMes3, setTotalMes3] = React.useState(0);
  const [label4, setLabel4] = React.useState("");
  const [totalMes4, setTotalMes4] = React.useState(0);
  const [label5, setLabel5] = React.useState("");
  const [totalMes5, setTotalMes5] = React.useState(0);
  const [label6, setLabel6] = React.useState("");
  const [totalMes6, setTotalMes6] = React.useState(0);
*/

  function getData(estatus, color) {
    let token = sessionStorage.getItem("Token");

    //DOCUMENTOS
    var endpoint = `${process.env.REACT_APP_URL}api/documentos/status/${estatus}/stats`;
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/documentos/status/${estatus}/stats`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };

    return new Promise(function (resolve, reject) {
      axios(config).then(
        (response) => {
          let arr = response.data;
          console.log('Processing Request');
          var meses = [];
          var totalmeses = 0;
          for (var i = 5; i >= 0; i--) {
            totalmeses += arr.monthly.series[i];
            meses = [
              ...meses,
              { x: arr.monthly.labels[i].substring(0, 3), y: arr.monthly.series[i], color: color }
              //{x: arr.monthly.labels[i], y: arr.monthly.series[i], color:color}
            ];
          }
          console.log(meses);
          resolve(meses);
        },
        (error) => {
          //reject(error);
          var meses = [];
          resolve(meses);
        }
      );
    });

    //FIN DOCUMENTOS

  }


  const leyenda = [
    { color: "#66bb6a", name: "Firmados" },
    { color: "#ffa726", name: "Proceso" },
    { color: "#ef5350", name: "Rechazados" },
    { color: "#ab47bc", name: "Registrados" }
  ]


  async function getTotales() {
    const uno = await getData(1, "#ab47bc");
    setStatus1(uno);
    const dos = await getData(2, "#ffa726");
    setStatus2(dos);
    const tres = await getData(3, "#66bb6a");
    setStatus3(tres);
    const cuatro = await getData(4, "#ef5350");
    setStatus4(cuatro);

    console.log("uno", uno);
    console.log("dos", dos);
    console.log("tres", tres);
    console.log("cuatro", cuatro);

    if (uno.length && dos.length && tres.length && cuatro.length) {
      setDataLinea([
        { x: uno[0].x, y: uno[0].y + dos[0].y + tres[0].y + cuatro[0].y },
        { x: uno[1].x, y: uno[1].y + dos[1].y + tres[1].y + cuatro[1].y },
        { x: uno[2].x, y: uno[2].y + dos[2].y + tres[2].y + cuatro[2].y },
        { x: uno[3].x, y: uno[3].y + dos[3].y + tres[3].y + cuatro[3].y },
        { x: uno[4].x, y: uno[4].y + dos[4].y + tres[4].y + cuatro[4].y },
        { x: uno[5].x, y: uno[5].y + dos[5].y + tres[5].y + cuatro[5].y }
      ]);
    }

  }

  React.useEffect(() => {
    getTotales();
    setLoading(false);
  }, []);

  const _onMouseLeave = () => {
    //console.log("salio")
    setCuadro([]);
  };

  const _onNearestX = (value, { index }) => {
    //console.log("value",value)
    setCuadro([value])
  };

  const FlexibleXYPlot = makeVisFlexible(XYPlot);
  const useCanvas = true;
  const content = useCanvas ? 'TOGGLE TO SVG' : 'TOGGLE TO CANVAS';
  const BarSeries = useCanvas ? VerticalBarSeriesCanvas : VerticalBarSeries;

  return (
    <React.Fragment>
      {
        loading ? (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <CircularProgress />
          </div>
        ) : null
      }
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography color="dark" gutterBottom>
            <font size="5">
              <b>DOCUMENTOS POR ESTATUS</b>
            </font>
          </Typography>
        </Box>
      </Grid>
      <hr />
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" alignItems="center">
          {
            status1 ? (
              <XYPlot
                onMouseLeave={_onMouseLeave}
                xType="ordinal"
                width={width}
                height={height}
                xDistance={100}
              >
                {/*
              <VerticalGridLines />
              <HorizontalGridLines />
              */}
                <VerticalGridLines />
                <HorizontalGridLines />
                <Borders style={{
                  all: { fill: '#fff' },
                  bottom: { fill: '#fff' }
                }} />
                {/*
              <XAxis />
              <YAxis />
              */}
                <YAxis />
                <XAxis />
                <BarSeries
                  data={status1}
                  colorType="literal"
                />

                <BarSeries
                  data={status2}
                  colorType="literal"
                />

                <BarSeries
                  data={status3}
                  colorType="literal"
                />

                <BarSeries
                  data={status4}
                  colorType="literal"
                />

                <LineSeries
                  onNearestX={_onNearestX}
                  className="third-series"
                  curve={'curveMonotoneX'}
                  data={dataLinea}
                />

                <Crosshair
                  values={cuadro}>
                </Crosshair>


              </XYPlot>
            ) : null
          }
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          {(leyenda) ? (
            leyenda.map((item) => (
              <React.Fragment key={item.name}>
                <span>&nbsp;<lable Style={"background-color:" + item.color + "; color:white"}><font size="1">{item.name}</font></lable></span>
              </React.Fragment>
            ))
          ) : null}
        </Box>
      </Grid>
    </React.Fragment>
  )

}
