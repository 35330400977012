import React from "react";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import { useHistory } from "react-router-dom";
import CONSTANCIA from "assets/img/CONSTANCIA_LOGO.png";
import NOTIFICACION from "assets/img/NOTIFICACION_LOGO.png";
import Typography from "@material-ui/core/Typography";
import Checkout from "containers/Checkout.js";
import Tooltip from "@material-ui/core/Tooltip";
import Card from "components/Card/Card.js";

import Components from "containers/Nom151/Flujo.js";
import NotificacionCertificada from "containers/NotificacionCertificada/Flujo.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(styles);

const useStylespro = makeStyles((theme) => ({
  Textfound: {
    fontFamily: "Helvetica",
    color: "#000000",
  },
  Imagen: {
    margin: "10px 2px !important",
  },
}));

export default function ServiciosContainer() {
  const classes = useStyles();
  const classestext = useStylespro();
  const history = useHistory();
  const [idflujo, setIdFlujo] = React.useState(0);
  const [activeRender, setActiveRender] = React.useState(0);

  const handleServiciosLegales = () => {
    setActiveRender(1);
  };

  const handleNotificaciones = () => {
    setActiveRender(2);
  };

  return (
    <div>
      {activeRender === 0 ? (
        <GridContainer>
          <GridItem xs={12} sm={6} md={12}>
            <Typography
              variant="h6"
              gutterBottom
              style={{ textTransform: "none", marginLeft: 15 }}
            >
              Selecciona un tipo de servicio
              <br />
            </Typography>
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <Card style={{ cursor: "pointer" }}>
              <CardHeader stats icon onClick={() => handleServiciosLegales()}>
                <CardIcon color="info">
                  <Tooltip title="Crear constancias de conservacion de documentos.">
                    <img
                      src={CONSTANCIA}
                      alt="Con-Certeza"
                      width="65"
                      height="65"
                    />
                  </Tooltip>
                </CardIcon>
                <p
                  style={{ textAlign: "center", fontSize: "14px" }}
                  className={classestext.Textfound}
                >
                  CONSTANCIA DE CONSERVACIÓN <br />
                </p>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card style={{ cursor: "pointer" }}>
              <CardHeader stats icon onClick={() => handleNotificaciones()}>
                <CardIcon color="success">
                  <Tooltip title="Notificaciones Certificadas">
                    <img
                      src={NOTIFICACION}
                      alt="Con-Certeza"
                      width="65"
                      height="65"
                    />
                  </Tooltip>
                </CardIcon>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    marginTop: "10px",
                  }}
                  className={classestext.Textfound}
                >
                  NOTIFICACIÓN CERTIFICADA
                </p>
                <br />
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
      ) : activeRender === 1 ? (
        <Components setActiveRender={setActiveRender} />
      ) : (
        <NotificacionCertificada setActiveRender={setActiveRender} />
      )}
    </div>
  );
}
