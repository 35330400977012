import React, { useEffect, useRef, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";

const styles = {
  imageContainer: {
    display: "block",
    width: "100%",
    height: "100px",
    border: "2px dashed rgba(0,0,0,0.3)",
    padding: "1rem",
    marginBottom: "0.5rem",
    borderRadius: "0.5rem",
    cursor: "pointer",
  },
  imageDisplay: {
    display: "block",
    width: "100%",
    height: "100%",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
};

export default function ImagePicker(props) {
  const filePicker = React.useRef();
  const dropRef = React.useRef();

  let lastFile = { name: "", size: 0 };
  const processFile = (file) => {
    if (file.name === lastFile.name && file.size === lastFile.size) return;

    const imageBlob = URL.createObjectURL(file);
    lastFile.name = file.name;
    lastFile.size = file.size;

    if (typeof props.onPick === "function")
      props.onPick(imageBlob);
  };

  const onSelectImage = (ev) => {
    const files = filePicker.current.files;
    if (!files[0]) {
      if (props.image !== null) {
        URL.revokeObjectURL(props.image);
      }
      if (typeof props.onPick === "function")
        props.onPick(null);
      return;
    }

    processFile(files[0]);
  };

  const stopPropagation = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDropFiles = (e) => {
    stopPropagation(e);
    let files = e.dataTransfer.files;
    if (!files) return;
    processFile(files[0]);
  };

  useEffect(() => {
    if (!filePicker || !filePicker.current) return;

    filePicker.current.addEventListener("change", onSelectImage);
    const div = dropRef.current;
    div.addEventListener("dragenter", stopPropagation);
    div.addEventListener("dragleave", stopPropagation);
    div.addEventListener("dragover", stopPropagation);
    div.addEventListener("drop", handleDropFiles);
  });

  const backgroundImage =
    props.image === null ? `url(${props.defaultImage})` : `url(${props.image})`;

  return (
    <div
      style={props.style}
      onClick={() => filePicker.current.click()}
      ref={dropRef}
    >
      <p>{props.title}</p>
      <div style={{ ...styles.imageContainer }} key={props.image}>
        <div style={{ ...styles.imageDisplay, backgroundImage }}></div>
      </div>
      <label>{props.placeholder}</label>
      <input
        type="file"
        style={{ display: "none" }}
        ref={filePicker}
        accept="image/*"
      />
    </div>
  );
}
