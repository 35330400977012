/**
 * Store principal de la aplicacion.
 */

const initialState = {
    dashboard: {
        total: 0,
        registrado: 0,
        en_proceso: 0,
        firmados: 0,
        rechazados: 0,
        synced: false,
    },
    $loading: false,
    sessionExpired: false,
    avisoPrivacidadAceptado: false,
    pruebaVidaAceptado: false,
    pruebaVidaErrorModalOculto: false,
};

export const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_PV_ERROR_MODAL_OCULTO": {
            return { ...state, pruebaVidaErrorModalOculto: action.payload === true };
        }
        case "SET_PRUEBA_VIDA_ACEPTADO": {
            return { ...state, pruebaVidaAceptado: action.payload === true };
        }
        case "SET_AVISO_PRIVACIDAD_ACEPTADO": {
            return { ...state, avisoPrivacidadAceptado: action.payload === true };
        }
        case "SET_DASHBOARD": {
            return { ...state, dashboard: { ...action.payload, synced: true } };
        }
        case "SET_LOADING": {
            return { ...state, $loading: action.payload === true }
        }
        case "SET_SESSION_EXPIRED": {
            return { ...state, sessionExpired: action.payload === true }
        }
        default: {
            return state;
        }
    }
};