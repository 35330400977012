import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonC from "components/CustomButtons/Button.js";
import PhotoSizeSelectSmallIcon from "@material-ui/icons/PhotoSizeSelectSmall";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import axios from "axios";
//import IconButton from "@material-ui/core/IconButton";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { green, deepOrange } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { useSelector } from "react-redux";
import ErrorIcon from "@material-ui/icons/Error";
import Snackbar from "components/Snackbar/Snackbar.js";
import DeleteIcon from "@material-ui/icons/Delete";

//LEMP
import Autocomplete from "@material-ui/lab/Autocomplete";
import UbicarFirma from "containers/Firmante/newUbicarFirma";
import Notificados from "containers/Firmante/Notificados";
//LEMP
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Paper from "@material-ui/core/Paper";

//listItem
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: 5,
  },
  button: {
    marginTop: theme.spacing(10),
    marginLeft: theme.spacing(0),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  codigo: {
    marginTop: theme.spacing(-1),
    marginRight: "-5px",
  },
  formControl: {
    margin: theme.spacing(-1),
    padding: 0,
    marginRight: "-15px",
  },
  container: {
    padding: theme.spacing(2),
  },

  buttonProgress: {
    color: green[700],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));
const useStylesprogrees = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgress2: {
    color: green[500],
    alignItems: "center",
    top: "50%",
    left: "50%",
    marginTop: 12,
    marginLeft: 12,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const useStyles2 = makeStyles((theme) => ({
  green: {
    color: "#fff",
    backgroundColor: green["A700"],
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
}));
const useStylestable = makeStyles(styles2);

export default function AddressForm(props) {
  const classespro = useStylesprogrees();

  const ref = React.createRef();
  function goTop() {
    console.log("REF");
    ref.current.scrollIntoView();
  }
  const history = useHistory();
  const [tokenstate, setTokenstate] = React.useState("");
  //data firmantes
  const [datosFirmantes, setDatosFirmantes] = React.useState([{}]);
  const [firmantesPrevios, setFirmantesPrevios] = React.useState(false);
  const [tiposActuacion, setTiposActuacion] = React.useState(
    "Por su Propio Derecho"
  );
  const [tipoActuacion, setTipoActuacion] = React.useState({
    codigo: 1,
    valor: "Por su Propio Derecho",
  });
  const [estiloCelular, setEstiloCelular] = React.useState("gray");
  const [modalNotificados, setModalNotificados] = React.useState(false);
  const [textoValidacionMail, setTextoValidacionMail] = React.useState("");
  const [checked, setChecked] = React.useState([1]);
  /*LEMP*/
  /*arreglo para almacenar el objeto coordenadas*/
  const [coordenadasFirma, setCoordenadasFirma] = React.useState([{}]);
  /*indicador para saber si estoy cargando un nuevo firmante*/
  const [nuevoFirmante, setNuevoFirmante] = React.useState(true);
  /*LEMP*/

  const classes2 = useStylestable();
  const classesicon = useStyles2();

  const classes = useStyles();
  const { idDocumento, senFirmante, handleDelete, idRequired } = props;

  const [value, setValue] = useState("1");
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [messageError, setMessageError] = React.useState("");
  const [openAlert, setOpenAlert] = React.useState("");
  const [nameFirmante, setNameFirmante] = React.useState("");
  const [numFirmante, setNumFirmante] = React.useState(1);
  const [idFirmante, setIdFirmante] = React.useState("");
  const timer = React.useRef();
  const [displaySign, setDisplaySign] = React.useState(false);
  const [progress, setprogress] = React.useState(false);
  const [arrayIDFirmante, setArrayIDFirmante] = React.useState([]);
  const [modalEditarFirmante, setModalEditarFirmante] = React.useState(false);
  const [valueModificar, setValueModificar] = React.useState("1");

  const [datosModificar, setDatosModificar] = React.useState({
    nombre: "",
    apellidos: "",
    correo: "",
    codigo: "52",
    celular: "",
    rol: "Por su Propio Derecho",
    Compañia: "",
  });
  const [datos, setDatos] = useState({
    nombre: "",
    apellidos: "",
    correo: "",
    codigo: "52",
    celular: "",
    rol: "",
    Compañia: "",
    idFirmante: "",
  });
  //almacena todas las firmas configuradas en el documento
  const [ubicacionFirmaHistoria, setUbicacionFirmaHistoria] = React.useState(
    []
  );
  //almacena la coordenadas de la última posisición de la firma
  const [coordenadas, setCoordenadas] = useState({
    page: 0,
    x: 0,
    y: 0,
    screenX: 0,
    screenY: 0,
    w: 0,
    h: 0,
    top: 0,
    left: 0,
    nombre: "",
    idFirmante: "",
    height: 0,
    width: 0,
  });

  const [arrayFirmantes, setArrayFirmantes] = React.useState([]);
  const myRef = React.createRef();

  React.useEffect(() => {
    goTop();
    getTiposActuacion();
    let token = sessionStorage.getItem("Token");
    setTokenstate(token);

    datafirmantes();
  }, []);

  const datafirmantes = () => {
    let token = sessionStorage.getItem("Token");

    const endpoint = `${process.env.REACT_APP_URL}api/documentos/${idDocumento}`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {},
        // receive two parameter endpoint url ,form data
      })
      .then(async (resp) => {
        // const ok = itemsFirmantes(resp.data.firmantes);
        // setFirmantesdata(ok);
        // setArrayFirmantes(resp.data.firmantes);

        itemFirmantesAPI(resp.data.firmantes, arrayFirmantes);
      })
      .catch((err) => {
        console.log("Error Axios", err);
      });
  };

  const itemFirmantesAPI = (DataAPi, arrayFirmantes1) => {
    var collection = [];
    console.log("DATAAPI", DataAPi);
    console.log("collection", collection);
    DataAPi.map(function (data) {
      var firmante = {
        nombre: data.name,
        apellidos: data.last_name,
        correo: data.email,
        codigo: data.phone_country_code,
        celular: data.phone,
        rol: data.role,
        Compañia: data.company,
        idFirmante: data._id,
        date_signed: data.date_signed,
      };
      collection.push(firmante);
    });
    setArrayFirmantes(collection);
  };
  //FUNCIONES PARA REGISTRAR LA POSICION DE LA FIRMA

  const clickFijar = () => {
    setDisplaySign(false);

    // setUbicacionFirma([...ubicacionFirma, coordenadas]);

    ubicacionFirmaHistoria.push(coordenadas);
    console.log("historiaFirma", ubicacionFirmaHistoria);

    //buscar cordenadas por id de firmante que pertenezcan a al firmante que vamos actualizar
    const firmantesFiltrados = ubicacionFirmaHistoria.filter(
      (x) => x.idFirmante == idFirmante
    );

    console.log("FILTRO", firmantesFiltrados);
    console.log("CORDENADAS", coordenadas);

    const dataFull = eliminarIdFimante(firmantesFiltrados);
    console.log("itemIDqwe", dataFull);

    console.log("borraridfiirmante", firmantesFiltrados);
    let token = sessionStorage.getItem("Token");

    var datajs = JSON.stringify({
      signature_coords: dataFull,
    });
    const config = {
      method: "put",
      url: `${process.env.REACT_APP_URL}api/firmantes/${idFirmante}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: datajs,
    };

    axios(config)
      .then((res) => {
        const data = res.data;

        console.log("modificacion de Gryop.", data);
        // alert("se registro con exito ");
      })
      .catch((error) => {
        alert("El campo signature_coords debe ser un array");
        console.error(error);
      });
  };

  const eliminarIdFimante = (data) => {
    let values = [];
    data.forEach((item) => {
      let object = {};

      object = {
        h: item.h,
        height: item.height,
        left: item.left,
        nombre: item.nombre,
        page: item.page,
        screenX: item.screenX,
        screenY: item.screenY,
        top: item.top,
        w: item.w,
        width: item.width,
        x: item.x,
        y: item.y,
      };
      values.push(object);
    });
    return values;
  };

  function validarMail(email) {
    let tokenSG =
      "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";
    var config = {
      async: true,
      crossDomain: true,
      method: "post",
      url: `https://api.sendgrid.com/v3/validations/email`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + tokenSG,
      },
      processData: false,
      data: `{\"email\":\"${email}\",\"source\":\"signup\"}`,
    };
    axios(config)
      .then(function (response) {
        console.log("Email Validation:", response);
        if (response.data.result.verdict === "Invalid") {
          setTextoValidacionMail("Correo no valido");
        } else {
          setTextoValidacionMail("");
        }
        //verdict: "Invalid"
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //LEMP TIPOS DE ACTUACIÓN
  function getTiposActuacion() {
    let token = sessionStorage.getItem("Token");
    axios
      .get(`${process.env.REACT_APP_URL}api/firmantes/involvement/types`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("Tipos:", response.data);
        setTiposActuacion(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleChangeTipoActuacion = (event) => {
    var t = [];
    Object.keys(tiposActuacion).forEach(function (n, i) {
      t[i] = {
        codigo: n,
        valor: tiposActuacion[n],
      };
    });
    const tipo = t.find((ta) => ta.codigo === event.target.value);
    setTipoActuacion(tipo);

    console.log("TIPOS DE ACTUACION", tipo);
  };

  function SelectTipos() {
    var t = [];
    Object.keys(tiposActuacion).forEach(function (n, i) {
      t[i] = {
        codigo: n,
        valor: tiposActuacion[n],
      };
    });
    console.log("Tipos", t);
    return (
      <React.Fragment>
        <InputLabel id="demo-simple-select-label">Tipo de Actuación</InputLabel>
        <Select
          labelId="tipoactuacion"
          id="tipoactuacion"
          value={tipoActuacion.codigo}
          onChange={handleChangeTipoActuacion}
        >
          {t.map(({ codigo, valor }, index) => (
            <MenuItem key={index} value={codigo}>
              {valor}
            </MenuItem>
          ))}
        </Select>
      </React.Fragment>
    );
  }

  function handleInputChange(event, value) {
    //console.log(value);
    setDatos({
      ...datos,
      correo: value,
    });
  }

  function b(email) {
    for (var i = datosFirmantes.length; i--;) {
      if (datosFirmantes[i].email === email) {
        return datosFirmantes[i];
      }
    }
    return null;
  }

  const onChange = (event, value) => {
    //      console.log("change");
    //      console.log(value.email);
    if (value) {
      var existente = b(value.email);
      console.log("existente", existente);

      if (existente) {
        //validación temporal teléfono mientras se envía código en campo separado
        var codigo;
        var tlf;
        if (existente.phone) {
          if (existente.phone === "") {
            codigo = "52";
          } else {
            if (existente.phone.substring(0, 1) === "1") {
              codigo = "1";
              tlf = existente.phone.slice(1);
            } else {
              codigo = "52";
              if (existente.phone.substring(0, 2) === "52") {
                tlf = existente.phone.slice(2);
              } else {
                tlf = existente.phone;
              }
            }
          }
          if (existente.phone_country_code !== "") {
            codigo = existente.phone_country_code;
          }
          console.log("cel:", existente.phone.substring(0, 1));
          console.log("codigo:", codigo);
          console.log("Tlf:", tlf);
        } else {
          codigo = "52";
        }

        //fin validar teléfono
        setDatos({
          ...datos,
          nombre: existente.name,
          apellidos: existente.last_name,
          correo: existente.email,
          codigo: codigo,
          celular: tlf,
          rol: existente.role,
          Compañia: existente.company,
        });

        setTipoActuacion({
          codigo: existente.firmante_involvements_id,
          valor: existente.role,
        });
        setValue(existente.notification_types_id.toString());
      } else {
        setDatos({
          ...datos,
          nombre: "",
          apellidos: "",
          correo: "",
          codigo: "52",
          celular: "",
          rol: "",
          Compañia: "",
        });
      }
    } else {
      setDatos({
        ...datos,
        nombre: "",
        apellidos: "",
        correo: "",
        codigo: "52",
        celular: "",
        rol: "",
        Compañia: "",
      });
    }
  };

  const handleChange = async (event) => {
    await setValue(event.target.value);
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenstate}`,
      Accept: " application/json",
    },
  };

  var areYouReallySure = false;
  function areYouSure() {
    if (allowPrompt) {
      if (!areYouReallySure && true) {
        areYouReallySure = true;
        var confMessage = "Por favor termine de registrar el firmante ";

        return confMessage;
      }
    } else {
      allowPrompt = true;
    }
  }

  var allowPrompt = true;

  window.onbeforeunload = areYouSure;

  const handleGoBackHome = () => {
    let uri = localStorage.getItem("uri");
    history.push(`/${uri}/nuevoProceso`);
  };
  const editFirmante = async () => {
    var rol = datosModificar.rol;
    if (tipoActuacion.codigo !== 5) {
      rol = tipoActuacion.valor;
    }

    var url = `${process.env.REACT_APP_URL}api/firmantes/${idFirmante}?name=${datosModificar.nombre}&last_name=${datosModificar.apellidos}&email=${datosModificar.correo}&phone_country_code=${datosModificar.codigo}&phone=${datosModificar.celular}&role=${rol}&firmante_involvements_id=${tipoActuacion.codigo}&company=${datosModificar.Compañia}&notification_types_id=${valueModificar}`;
    /*
  var url="";
  if(valueModificar===2) {
    url = `${process.env.REACT_APP_URL}api/firmantesfrecuentes/${firmante._id}?name=${datosModificar.nombre}&last_name=${datosModificar.apellidos}&email=${datosModificar.correo}&phone_country_code=${datosModificar.codigo}&phone=${datosModificar.celular}&role=${rol}&firmante_involvements_id=${tipoActuacion.codigo}&company=${datosModificar.Compañia}&notification_types_id=${valueModificar}&group=${datosModificar.grupo.toUpperCase()}`
  }
  else{
    url = `${process.env.REACT_APP_URL}api/firmantesfrecuentes/${firmante._id}?name=${datosModificar.nombre}&last_name=${datosModificar.apellidos}&email=${datosModificar.correo}&role=${rol}&firmante_involvements_id=${tipoActuacion.codigo}&company=${datosModificar.Compañia}&notification_types_id=${valueModificar}&group=${datosModificar.grupo.toUpperCase()}`
  }
*/

    let token = sessionStorage.getItem("Token");
    const config = {
      method: "put",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        // Respuesta del servidor
        let data = response.data;
        setModalEditarFirmante(false);
        setDatos({
          nombre: "",
          apellidos: "",
          correo: "",
          codigo: "52",
          celular: "",
          rol: "Por su Propio Derecho",
          Compañia: "",
        });

        datafirmantes();
        setArrayFirmantes([]);
        setDatosModificar({
          nombre: "",
          apellidos: "",
          correo: "",
          codigo: "52",
          celular: "",
          rol: "Por su Propio Derecho",
          Compañia: "",
        });
      })
      .catch((error) => {
        // Error 😨

        if (error.response.data.length > 1) {
          showNotificacion(error.response.data[0].message);
        } else {
          if (error.response.data.errors.email) {
            showNotificacion(error.response.data.errors.email);
          } else if (error.response.data.errors.phone) {
            showNotificacion(error.response.data.errors.phone);
          } else if (error.response.data.message) {
            showNotificacion(error.response.data.message);
          }
        }
      });
  };

  const registerNewFirmante = async () => {
    if (firmantesPrevios) {
      myRef.current
        .getElementsByClassName("MuiAutocomplete-clearIndicator")[0]
        .click();
    }

    const actuacionDefault = {
      codigo: 1,
      valor: "Por su Propio Derecho",
    };
    var requieredNum = 0;
    if (idRequired == true) {
      requieredNum = 1;
    }
    //nuevo LEMP usando JSON
    //AGREGAR OTRO FIRMANTE

    setNuevoFirmante(true);

    var rol = datos.rol;

    console.log("valorROL", rol);
    if (isObjEmpty(rol) === true) {
      rol = actuacionDefault.valor;
    }

    function isObjEmpty(obj) {
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) return false;
      }

      return true;
    }

    const json = JSON.stringify(coordenadasFirma);
    // const data = `{\n    "documentos_id": "${idDocumento}",\n    "name": "${datos.nombre}",\n    "last_name": "${datos.apellidos}",\n    "email": "${datos.correo}",\n    "phone_country_code": "${datos.codigo}",\n    "phone": "${datos.celular}",\n    "role": "${rol}",\n    "firmante_involvements_id": "${tipoActuacion.codigo}",\n    "company": "${datos.Compañia}",\n    "notification_types_id":${value},\n    "id_required":${idRequired},\n  `;

    const data = new FormData();
    data.append("documentos_id", idDocumento);
    data.append("name", datos.nombre);
    data.append("last_name", datos.apellidos);
    data.append("email", datos.correo);
    data.append("phone_country_code", datos.codigo);
    data.append("phone", datos.celular);
    data.append("rol", rol);
    data.append("firmante_involvements_id", tipoActuacion.codigo);
    data.append("company", datos.Compañia);
    data.append("notification_types_id", value);
    data.append("id_required", JSON.stringify(requieredNum));

    var datajs = JSON.stringify({
      documentos_id: idDocumento,
      name: datos.nombre,
      last_name: datos.apellidos,
      email: datos.correo,
      phone_country_code: datos.codigo,
      phone: datos.celular,
      role: rol,
      firmante_involvements_id: tipoActuacion.codigo,
      company: datos.Compañia,
      notification_types_id: value,
      id_required: idRequired,
    });

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_URL}api/firmantes`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${tokenstate}`,
      },
      data: datajs,
    };

    //    console.log("coordenadas y agregar otro firmante");
    //    console.log(coordenadasFirma);
    console.log("DATAbodyfirmante", data);

    axios(config)
      .then((response) => {
        setNumFirmante(numFirmante + 1);
        // Respuesta del servidor
        let data = response.data[1];
        setDisplaySign(false);
        //agregar id al registro de firmante
        saveArrayFirmantes(data._id, rol);
        setDatos({
          ...datos,
          nombre: "",
          apellidos: "",
          correo: "",
          codigo: "52",
          celular: "",
          rol: "",
          Compañia: "",
        });
        setCoordenadasFirma([{}]);
      })
      .catch((error) => {
        // Error 😨
        if (error.response.data.message) {
          showNotificacion(error.response.data.message);
        } else if (error.response.data.errors.email) {
          showNotificacion(error.response.data.errors.email);
        } else if (error.response.data.errors.phone) {
          showNotificacion(error.response.data.errors.phone);
        } else if (error.response.data.message) {
          showNotificacion(error.response.data.message);
        }
      });
  };

  const saveArrayFirmantes = (id, rolprop) => {
    const collection = [...arrayFirmantes];

    console.log("DATASave", rolprop);
    var firmante = {
      nombre: datos.nombre,
      apellidos: datos.apellidos,
      correo: datos.correo,
      codigo: datos.codigo,
      celular: datos.celular,
      rol: datos.rol,
      Compañia: datos.Compañia,
      idFirmante: id,
    };

    collection.push(firmante);
    setArrayFirmantes(collection);
  };

  const handlePlaceSign = (value) => {
    console.log("VALUEPOSITION", value.idFirmante);
    setIdFirmante(value.idFirmante);
    setNameFirmante(value.nombre);
    setDisplaySign(true);
  };

  const handleOpenEditar = (value) => {
    console.log("valueeditar", value);
    setIdFirmante(value.idFirmante);
    setModalEditarFirmante(true);
    var codigo;
    if (value.codigo) {
      codigo = value.codigo;
    } else {
      codigo = "52";
    }
    setDatosModificar({
      nombre: value.nombre,
      apellidos: value.apellidos,
      correo: value.correo,
      codigo: codigo,
      celular: value.celular,
      rol: value.rol,
      Compañia: value.Compañia,
    });
    setTipoActuacion({
      codigo: 1,
      valor: "Por su Propio Derecho",
    });

    //setModalEliminarFirmante(true);
  };

  const deleteFirmantes = (value) => {
    console.log("DELETEVALUE", value.idFirmante);
    const UrlDeleteFirmante = `${process.env.REACT_APP_URL}api/firmantes/${value.idFirmante}`;
    axios
      .delete(UrlDeleteFirmante, {
        headers: {
          Authorization: `Bearer ${tokenstate}`,
        },
        data: {},
        // receive two parameter endpoint url ,form data
      })
      .then((response) => {
        // Respuesta del servidor
        console.log("DELETEVALUE", value.idFirmante);
        console.log("HISTORIASFIRMAS", ubicacionFirmaHistoria);

        const firmantesFiltradosDelete = ubicacionFirmaHistoria.filter(
          (x) => x.idFirmante == value.idFirmante
        );
        console.log("FIRMASBORRADAS", firmantesFiltradosDelete);

        var data3 = [];

        ubicacionFirmaHistoria.forEach((item) => {
          var elemento_en_data2 = firmantesFiltradosDelete.find(
            (element) => element == item
          );
          if (elemento_en_data2 === undefined) {
            data3.push(item);
          }
        });

        setUbicacionFirmaHistoria(data3);

        console.log("respuest a data", response);
        const collection = [...arrayFirmantes];

        const currentIndex = collection.indexOf(value);
        console.log("valorfirmante", currentIndex);
        collection.splice(currentIndex, 1);
        setArrayFirmantes(collection);
      })
      .catch((error) => {
        // Error 😨
        if (error.response) {
          // console.log(error.response.data.message);
          //  openAlert(error.response.data.message);
        } else if (error.request) {
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  const showNotificacion = (message) => {
    setOpenAlert(true);
    setMessageError(message);
    setTimeout(function () {
      setOpenAlert(false);
    }, 6000);
  };

  const handleInitProcess = () => {
    setprogress(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenstate}`,
        Accept: " application/json",
      },
    };

    const urlInit = `${process.env.REACT_APP_URL}api/documentos/${idDocumento}/launch`;
    axios.put(urlInit, {}, config).then((response) => {
      console.log("RES DE INIT", response);
      setprogress(false);

      handleGoBackHome();
    });
  };

  const handleInput = (event) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
    // console.log("datos", datos);
    // console.log("radios", value);
  };

  const topmt = 155;

  let disabledFirmante = true;

  if (value == 2) {
    if (
      datos.correo.length >= 5 &&
      datos.nombre.length >= 5 &&
      datos.apellidos.length >= 2 &&
      datos.Compañia.length >= 2 &&
      datos.celular.length == 10
    ) {
      disabledFirmante = false;
    }
  } else {
    if (
      datos.correo.length >= 3 &&
      datos.nombre.length >= 3 &&
      datos.apellidos.length >= 3 &&
      datos.Compañia.length >= 2
    ) {
      disabledFirmante = false;
    }
  }

  let disabledModificar = true;
  if (valueModificar == 2) {
    if (
      datosModificar &&
      datosModificar.correo.length >= 3 &&
      datosModificar.nombre.length >= 3 &&
      datosModificar.rol.length >= 2 &&
      datosModificar.apellidos.length >= 2 &&
      datosModificar.Compañia.length >= 2 &&
      datosModificar.celular.length >= 9
    ) {
      disabledModificar = false;
    }
  } else {
    if (
      datosModificar.correo.length >= 3 &&
      datosModificar.nombre.length >= 3 &&
      datosModificar.rol.length >= 2 &&
      datosModificar.apellidos.length >= 3 &&
      datosModificar.Compañia.length >= 2 &&
      datosModificar.celular &&
      datosModificar.celular.length >= 9
    ) {
      disabledModificar = false;
    }
  }

  Array.prototype.unicos = function () {
    return this.filter((valor, indice) => {
      return this.indexOf(valor) === indice;
    });
  };
  const idUnicos = arrayIDFirmante.unicos();
  console.log("numerounicos", ubicacionFirmaHistoria);

  let valuesresultado = [];
  idUnicos.forEach((item) => {
    const resultado = ubicacionFirmaHistoria.find(
      (element) => element.idFirmante === item
    );

    console.log("RESULTADO", resultado);

    if (resultado !== undefined) {
      valuesresultado.push(resultado);
    }
  });
  console.log("RESULTADOvaluess", valuesresultado.length);

  let disableform = true;

  console.log("ARRAYFIRMANTES", arrayFirmantes);

  let firmantesSinFirmar = [];

  arrayFirmantes.forEach((item) => {
    if (item.date_signed) {
      console.log("ya firmo", item);
    } else {
      firmantesSinFirmar.push(item);
    }
  });

  console.log("firmantesSinFirmar", firmantesSinFirmar);
  if (
    valuesresultado.length === firmantesSinFirmar.length &&
    arrayFirmantes.length > 0
  ) {
    disableform = false;
  }
  // idUnicos.forEach((item) => {
  //   var elemento_en_data2 = ubicacionFirmaHistoria.find(
  //     (element) => element == item
  //   );
  //   console.log("elemento_en_data2", elemento_en_data2);
  // });

  const handleInputModificar = (event) => {
    setDatosModificar({
      ...datosModificar,
      [event.target.name]: event.target.value,
    });
    console.log("datos modificar", datosModificar);
  };

  return (
    <React.Fragment>
      {console.log("DATOSIDF", datos)}
      {console.log("DATOSARRAYFIRM", arrayFirmantes)}
      <Grid
        container
        style={{ width: 1000, marginLeft: "auto", marginRight: "auto" }}
        justifyContent="center"
      >
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          component={Paper}
          elevation={6}
          square
          className={classes.container}
        >
          <UbicarFirma
            idDocumento={idDocumento}
            nombre={nameFirmante}
            setCoordenadasFirma={setCoordenadasFirma}
            setNuevoFirmante={setNuevoFirmante}
            nuevoFirmante={nuevoFirmante}
            topmt={topmt}
            displaySign={displaySign}
            setDisplaySign={setDisplaySign}
            idFirmante={idFirmante}
            arrayFirmantes={arrayFirmantes}
            clickFijar={clickFijar}
            setUbicacionFirmaHistoria={setUbicacionFirmaHistoria}
            ubicacionFirmaHistoria={ubicacionFirmaHistoria}
            setCoordenadas={setCoordenadas}
          />

          {/* <Button
            variant="contained"
            color="primary"
            disabled={false}
            onClick={() => {
              console.log("clic notificados");
              setModalNotificados(true);
            }}
            className={buttonClassname}
          >
            Notificados
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={disableform}
            onClick={registerNewFirmante}
            className={buttonClassname}
          >
            Registrar otro firmante
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={disableform}
            onClick={registerFirmante}
            className={buttonClassname}
          >
            mandar a firmar
          </Button> */}
        </Grid>

        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          className={classes.container}
        >
          <Grid>
            <Typography variant="h6" gutterBottom>
              Datos de firmante #{arrayFirmantes.length + 1}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            {firmantesPrevios ? (
              <Autocomplete
                ref={myRef}
                required
                id="correo"
                name="correo"
                freeSolo
                options={datosFirmantes}
                getOptionLabel={(option) => option.email}
                onInputChange={handleInputChange}
                onChange={onChange}
                onBlur={() => {
                  if (datos.correo) {
                    validarMail(datos.correo);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Correo electrónico" />
                )}
              />
            ) : (
              <React.Fragment>
                <TextField
                  required
                  id="correo"
                  name="correo"
                  label="Correo electrónico"
                  value={datos.correo}
                  fullWidth
                  autoComplete="email"
                  onChange={handleInput}
                  onBlur={() => {
                    if (datos.correo) {
                      validarMail(datos.correo);
                    }
                  }}
                  inputProps={{ maxLength: 60 }}
                  error={datos.correo.length > 2 ? false : true}
                />
              </React.Fragment>
            )}
            <font color="red">{textoValidacionMail}</font>
            {/*
          <TextField
            required
            id="correo"
            name="correo"
            label="Correo electrónico"
            value={datos.correo}
            fullWidth
            autoComplete="email"
            onChange={handleInput}
            inputProps={{ maxLength: 60 }}
            error={datos.correo.length > 2 ? false : true}
          />
*/}
            {/*
          <Autocomplete
            required
            id="correo"
            name="correo"
            freeSolo
            options={datosFirmantes}
            getOptionLabel={(option) => option.email}
            onInputChange={handleInputChange}
            onChange={onChange}
            renderInput={(params) => (
              <TextField {...params} label="Correo electrónico" />
            )}
          />
*/}
          </Grid>
          <Grid container spacing={2} ref={ref}>
            <Grid item xs={3} sm={6}>
              <TextField
                required
                id="nombre"
                name="nombre"
                label="Nombre"
                value={datos.nombre}
                fullWidth
                autoComplete="given-name"
                onChange={handleInput}
                inputProps={{ maxLength: 30 }}
                error={datos.nombre.length > 1 ? false : true}
              />
            </Grid>
            <Grid item xs={3} sm={6}>
              <TextField
                required
                id="apellidos"
                name="apellidos"
                label="Apellidos"
                value={datos.apellidos}
                fullWidth
                autoComplete="family-name"
                onChange={handleInput}
                inputProps={{ maxLength: 60 }}
                error={datos.apellidos.length > 2 ? false : true}
              />
            </Grid>

            <Grid item xs={12} sm={3} className={classes.codigo}>
              <font color={estiloCelular} size="2">
                Código
              </font>
              <PhoneInput
                countryCodeEditable={false}
                inputStyle={{ width: "110%" }}
                inputExtraProps={{
                  name: "codigo",
                  id: "codigo",
                  required: true,
                  autoFocus: true,
                  readonly: true,
                }}
                readonly
                id="codigo"
                name="codigo"
                label="codigo"
                onlyCountries={["us", "mx"]}
                localization={{
                  "United States": "Estados Unidos",
                  Mexico: "México",
                }}
                country={datos.codigo === "1" ? "us" : "mx"}
                //country={"mx"}
                //value={datos.codigo}
                onChange={(phone) => {
                  setEstiloCelular("gray");
                  setDatos({
                    ...datos,
                    codigo: phone,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                id="celular"
                name="celular"
                label="Celular"
                value={datos.celular}
                onChange={handleInput}
                inputProps={{ maxLength: 15 }}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                required
                id="Compañia"
                name="Compañia"
                label="Compañía"
                value={datos.Compañia}
                fullWidth
                onChange={handleInput}
                inputProps={{ maxLength: 60 }}
                error={datos.Compañia.length > 2 ? false : true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectTipos />
            </Grid>
            <Grid item xs={4} sm={5}>
              {
                tipoActuacion.codigo === "5" ? (
                  <TextField
                    required
                    id="rol"
                    name="rol"
                    label="Tipo de actuación"
                    value={datos.rol}
                    fullWidth
                    onChange={handleInput}
                    inputProps={{ maxLength: 60 }}
                    error={datos.rol.length > 2 ? false : true}
                  />
                ) : null
                /*
        <TextField
          required
          id="rol"
          name="rol"
          label="Tipo de actuación"
          value={datos.rol}
          fullWidth
          onChange={handleInput}
          inputProps={{ maxLength: 60 }}
          error={datos.rol.length > 2 ? false : true}
        />
        */
              }
            </Grid>
            <Grid item xs={3} sm={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Envío de notificación</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="radios"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="1"
                    name="1"
                    control={<Radio />}
                    label="Correo electronico"
                  />
                  <FormControlLabel
                    value="2"
                    name="2"
                    control={<Radio />}
                    label="Mensaje de texto"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={3} sm={6}>
              <ButtonC
                color="success"
                size="md"
                type="button"
                onClick={registerNewFirmante}
                className={classes.button}
                disabled={disabledFirmante}
              >
                agregar firmante
              </ButtonC>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={12}>
            <hr />
            <List dense className={classes.root}>
              {arrayFirmantes.map((value) => {
                const labelId = `checkbox-list-secondary-label-${value}`;
                arrayIDFirmante.push(value.idFirmante);
                console.log("DATAFIRMANTES", value);
                return (
                  <ListItem key={value.name} button>
                    <ListItemAvatar>
                      <Avatar
                        className={
                          value.date_signed
                            ? classesicon.orange
                            : classesicon.green
                        }
                      >
                        {value.nombre
                          .split(" ")
                          .map((n) => n[0])
                          .join(".")}
                        {value.apellidos
                          .split(" .")
                          .map((n) => n[0])
                          .join(".")}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      id={labelId}
                      primary={` ${value.nombre} ${value.apellidos}`}
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            {value.date_signed
                              ? "Ya ha firmado no se puede editar"
                              : "No ha firmado, puede editar"}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                    {value.date_signed ? (
                      ""
                    ) : (
                      <ListItemSecondaryAction>
                        <Grid>
                          <Tooltip
                            id="tooltip-top"
                            title="Editar firmante"
                            placement="top"
                            classes={{
                              tooltip: classes2.tooltip,
                            }}
                          >
                            <IconButton
                              aria-label="Edit"
                              className={classes2.tableActionButton}
                            >
                              <EditIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                                onClick={() => {
                                  handleOpenEditar(value);
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            id="tooltip-top"
                            title="Ubicar firma"
                            placement="top"
                            classes={{
                              tooltip: classes2.tooltip,
                            }}
                          >
                            <IconButton
                              aria-label="ubicar"
                              className={classes2.tableActionButton}
                            >
                              <PhotoSizeSelectSmallIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                                onClick={() => {
                                  handlePlaceSign(value);
                                }}
                              />
                            </IconButton>
                          </Tooltip>

                          <Tooltip
                            id="tooltip-top"
                            title="Eliminar firmante"
                            placement="top"
                            classes={{
                              tooltip: classes2.tooltip,
                            }}
                          >
                            <IconButton
                              aria-label="Edit"
                              className={classes2.tableActionButton}
                            >
                              <DeleteIcon
                                className={
                                  classes2.tableActionButtonIcon +
                                  " " +
                                  classes2.edit
                                }
                                onClick={() => {
                                  deleteFirmantes(value);
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                );
              })}
            </List>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={6}
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={false}
                onClick={() => {
                  console.log("clic notificados");
                  setModalNotificados(true);
                }}
              >
                Notificados
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={disableform}
                onClick={handleInitProcess}
              >
                Enviar a firmar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Backdrop className={classespro.backdrop} open={progress}>
        <CircularProgress size={44} className={classespro.buttonProgress} />
      </Backdrop>

      {/*dialogo modificar FIRMANTES*/}
      <Dialog
        open={modalEditarFirmante}
        maxWidth={"md"}
        fullWidth={true}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          MODIFICAR FIRMANTE
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Grid container spacing={2}>
              <Grid item xs={6} sm={12}>
                <TextField
                  required
                  id="correo"
                  name="correo"
                  label="Correo electrónico"
                  value={datosModificar.correo}
                  fullWidth
                  autoComplete="email"
                  onChange={handleInputModificar}
                  onBlur={() => {
                    if (datosModificar.correo) {
                      validarMail(datosModificar.correo);
                    }
                  }}
                  inputProps={{ maxLength: 60 }}
                  error={datosModificar.correo.length > 2 ? false : true}
                />
                <font color="red">{textoValidacionMail}</font>
              </Grid>
              <Grid item xs={3} sm={6}>
                <TextField
                  required
                  id="nombre"
                  name="nombre"
                  label="Nombre(s)"
                  value={datosModificar.nombre}
                  fullWidth
                  autoComplete="given-name"
                  onChange={handleInputModificar}
                  inputProps={{ maxLength: 30 }}
                  error={datosModificar.nombre.length > 1 ? false : true}
                />
              </Grid>
              <Grid item xs={3} sm={6}>
                <TextField
                  required
                  id="apellidos"
                  name="apellidos"
                  label="Apellido(s)"
                  value={datosModificar.apellidos}
                  fullWidth
                  autoComplete="family-name"
                  onChange={handleInputModificar}
                  inputProps={{ maxLength: 60 }}
                  error={datosModificar.apellidos.length > 2 ? false : true}
                />
              </Grid>

              <Grid item xs={12} sm={2} className={classes.codigo}>
                <font color={"gray"} size="2">
                  Código
                </font>
                <PhoneInput
                  countryCodeEditable={false}
                  inputStyle={{ width: "80%" }}
                  inputExtraProps={{
                    name: "codigo",
                    id: "codigo",
                    required: true,
                    autoFocus: true,
                    readonly: true,
                  }}
                  readonly
                  id="codigo"
                  name="codigo"
                  label="codigo"
                  onlyCountries={["us", "mx"]}
                  localization={{
                    "United States": "Estados Unidos",
                    Mexico: "México",
                  }}
                  country={datosModificar.codigo === "1" ? "us" : "mx"}
                  //value={datosModificar.codigo}
                  onChange={(phone) => {
                    //setEstiloCelular("gray");
                    setDatosModificar({
                      ...datosModificar,
                      codigo: phone,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  style={{ width: "100%" }}
                  id="celular"
                  name="celular"
                  label="Celular"
                  value={datosModificar.celular}
                  onChange={handleInputModificar}
                  inputProps={{ maxLength: 15 }}
                  error={
                    datosModificar.celular && datosModificar.celular.length > 9
                      ? false
                      : true
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="Compañia"
                  name="Compañia"
                  label="Compañía"
                  value={datosModificar.Compañia}
                  fullWidth
                  onChange={handleInputModificar}
                  inputProps={{ maxLength: 60 }}
                  error={datosModificar.Compañia.length > 2 ? false : true}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Tipo de notificación</FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    name="radios"
                    value={valueModificar}
                    //onChange={handleChange}
                    onChange={(e) => {
                      setValueModificar(e.target.value);
                      console.log(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="1"
                      name="1"
                      control={<Radio />}
                      label="Correo electrónico"
                    />
                    <FormControlLabel
                      value="2"
                      name="2"
                      control={<Radio />}
                      label="Mensaje de texto"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <SelectTipos />
              </Grid>
              <Grid item xs={12} sm={4}>
                {tipoActuacion.codigo === "5" ? (
                  <TextField
                    required
                    id="rol"
                    name="rol"
                    label="Tipo de actuación"
                    value={datosModificar.rol}
                    fullWidth
                    onChange={handleInputModificar}
                    inputProps={{ maxLength: 60 }}
                    error={datosModificar.rol.length > 2 ? false : true}
                  />
                ) : null}
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonC
            color="success"
            onClick={editFirmante}
            disabled={disabledModificar}
          >
            Modificar firmante
          </ButtonC>

          <ButtonC
            onClick={() => {
              setModalEditarFirmante(false);
              setDatosModificar({
                nombre: "",
                apellidos: "",
                correo: "",
                codigo: "52",
                celular: "",
                rol: "Por su Propio Derecho",
                Compañia: "",
                grupo: "DEFAULT",
              });
            }}
            color="danger"
          >
            Cancelar
          </ButtonC>
        </DialogActions>
      </Dialog>
      {/*fin dialogo MODIFICAR*/}

      <Snackbar
        place="tc"
        color="danger"
        icon={ErrorIcon}
        message={messageError}
        open={openAlert}
        closeNotification={() => setOpenAlert(false)}
        close
      />
      {modalNotificados ? (
        <Notificados
          setModalNotificados={setModalNotificados}
          modalNotificados={modalNotificados}
          idDocumento={idDocumento}
        />
      ) : null}
    </React.Fragment>
  );
}
