import React from "react";
import PDFPrinter from "components/PDFprinter/PDFPrinter";

import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CardHeader from "components/Card/CardHeader";
import IconButton from "@material-ui/core/IconButton";

import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import FastForwardIcon from "@material-ui/icons/FastForward";
import FastRewindIcon from "@material-ui/icons/FastRewind";
import { Grid } from "@material-ui/core";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

const useStylestable = makeStyles(styles2);

const ControlPanel = (props) => {
  const classes2 = useStylestable();
  const { file, pageNumber, numPages, setPageNumber, scale, setScale } = props;

  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber === numPages;

  const firstPageClass = isFirstPage ? "disabled" : "clickable";
  const lastPageClass = isLastPage ? "disabled" : "clickable";

  const goToFirstPage = () => {
    if (!isFirstPage) setPageNumber(1);
  };
  const goToPreviousPage = () => {
    if (!isFirstPage) setPageNumber(pageNumber - 1);
  };
  const goToNextPage = () => {
    if (!isLastPage) setPageNumber(pageNumber + 1);
  };
  const goToLastPage = () => {
    if (!isLastPage) setPageNumber(numPages);
  };

  const onPageChange = (e) => {
    const { value } = e.target;
    setPageNumber(Number(value));
  };

  const isMinZoom = scale < 0.6;
  const isMaxZoom = scale >= 2.0;

  const zoomOutClass = isMinZoom ? "disabled" : "clickable";
  const zoomInClass = isMaxZoom ? "disabled" : "clickable";

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1);
  };

  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1);
  };

  return (
    <React.Fragment>
      <CardHeader color="info">
        <Grid container justify="center">
          <SkipPreviousIcon
            onClick={goToFirstPage}
            style={{ cursor: "pointer" }}
          />
          <FastRewindIcon
            onClick={goToPreviousPage}
            style={{ cursor: "pointer" }}
          />
          <span>
            &nbsp; Página{" "}
            <input
              name="pageNumber"
              type="number"
              min={1}
              max={numPages || 1}
              value={pageNumber}
              onChange={onPageChange}
            />
            de {numPages}
            &nbsp; &nbsp;
          </span>
          <FastForwardIcon
            onClick={goToNextPage}
            style={{ cursor: "pointer" }}
          />
          <SkipNextIcon onClick={goToLastPage} style={{ cursor: "pointer" }} />
          {/* <RemoveCircleIcon onClick={zoomOut} style={{ cursor: "pointer" }} />
          <span> {(scale * 100).toFixed()}%</span>
          <AddCircleIcon onClick={zoomIn} style={{ cursor: "pointer" }} /> */}
          {/* <PDFPrinter file={file} /> */}
        </Grid>
      </CardHeader>
    </React.Fragment>
  );
};

export default ControlPanel;
