import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import ControlPanel from "components/controlPanel/ControlPanel";
import Button from "components/CustomButtons/Button.js";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import Observadores from "containers/Firmante/Notificados";
import Firmantes from "containers/Firmante/Firmantes";
import { isMobile } from "react-device-detect";

export default function Notificados(props) {
  const [mostrarDocumento, setMostrarDocumento] = React.useState(false);
  const [scale, setScale] = React.useState(0.5);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pdfHeigth, setpdfHeigth] = React.useState(575);
  const [pdfWidth, setpdfWidth] = React.useState(660);
  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }


  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const [modalNotificados, setModalNotificados] = React.useState(false);
  const [modalFirmantes, setModalFirmantes] = React.useState(false);
  const [datos, setDatos] = React.useState({
    nombre: "",
    apellidos: "",
    correo: "",
    codigo: "52",
    celular: "",
    rol: "",
    Compañia: "",
  });

  function handleInputChange(event, value) {
    setDatos({
      ...datos,
      correo: value,
    });
  }

  React.useEffect(() => {
    if (isMobile) {
      setpdfHeigth(500);
      setpdfWidth(290);
    }
      console.log(props.documentoPrincipal);
    }, []);

  return(
    <React.Fragment>
    <Grid container spacing={1}>
    <Grid item xs={12}>
      <div align="center">
          <Button
            variant="contained"
            disabled={false}
            style={{
              backgroundColor: "#3f51b5",
              color: "white"
            }}
            onClick={() => {
              setMostrarDocumento(!mostrarDocumento);
            }}
          >
            Documento
          </Button>
          <Button
            variant="contained"
            disabled={false}
            style={{
              backgroundColor: "#3f51b5",
              color: "white"
            }}
            onClick={() => {
              console.log("clic firmantes");
              setModalFirmantes(true);
            }}
          >
            Destinatarios
          </Button>
          <Button
            variant="contained"
            disabled={false}
            style={{
              backgroundColor: "#3f51b5",
              color: "white"
            }}
            onClick={() => {
              console.log("clic notificados");
              setModalNotificados(true);
            }}
          >
            Observadores
          </Button>
      </div>
    </Grid>

    {
      mostrarDocumento ? (
        <React.Fragment>
        {/* PDF */}
        <Grid item xs={12}>
        <div align="center">
          <ControlPanel
            scale={scale}
            setScale={setScale}
            numPages={numPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            file={props.base64Principal}
          />
          <Document
            error="Error al intentar cargar el PDF"
            loading="Cargando..."
            file={props.base64Principal}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              scale={scale}
              height={pdfHeigth}
              width={pdfWidth}
              renderTextLayer={false}
              pageNumber={pageNumber}
            />
          </Document>

          <div className="text-center">
            <p>
              Página {pageNumber || (numPages ? 1 : "--")} de{" "}
              {numPages || "--"}
            </p>
            <button
              className="btn btn-dark"
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
            >
              Atrás
            </button>{" "}
            <button
              className="btn btn-dark"
              type="button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
            >
              Siguiente
            </button>
          </div>
        </div>
        </Grid>
        {/* FIN PDF*/}
        </React.Fragment>
      ):null
    }

    </Grid>


    {modalNotificados ? (
      <Observadores
        setModalNotificados={setModalNotificados}
        modalNotificados={modalNotificados}
        idDocumento={props.documentoPrincipal._id}
      />
    ) : null}

    {modalFirmantes ? (
      <Firmantes
        setModalFirmantes={setModalFirmantes}
        modalFirmantes={modalFirmantes}
        idDocumento={props.documentoPrincipal._id}
        setFirmantes={props.setFirmantes}
      />
    ) : null}
    </React.Fragment>
  );
}
