import React from "react";
import ReactDOM from "react-dom";
import Draggable from "react-draggable";
import firma from "./rectangle-firma1.png";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";

class CuadroFirma extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.refField = React.createRef();
    this.state = {
      pagina: props.pagina,
      defx: props.defx,
      defy: props.defy,
      left: props.left,
      nombre: props.nombre,
      alerta: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pagina !== this.props.pagina) {
      this.setState({ pagina: this.props.pagina });
    }

    /*
    if (this.props.defaultpos){
      this.setState({ defx: this.props.defx })
      this.setState({ defy: -this.props.defy })
    }
    this.props.setDefaultpos(false);
    console.log("default",this.props.defaultpos);
    */
  }

  handleClose = () => {
    this.setState({ alerta: false });
  };

  handleStop = (e: MouseEvent, data: Object) => {
    this.props.setDisableFijar(false);

    var element = document.getElementsByClassName("react-pdf__Page__canvas");
    //console.log(element[0].getBoundingClientRect())
    var clientRect = element[0].getBoundingClientRect();
    var clientX = clientRect.left;
    var bottom = clientRect.bottom;
    var width = clientRect.width;
    // console.log("PDF:")
    // console.log("botton:"+clientRect.bottom)
    console.log("PDF_page:");
    console.log(clientRect);

    //var clientRect = element.getBoundingClientRect();
    //var clientX = clientRect.left;

    console.log("Event: ", e);
    console.log("Data: ", data);

    this.setState({ defx: data.lastX });
    this.setState({ defy: -data.lastY });

    //this.setState({ defaultpos: { x: data.lastX, y: -data.lastY } });
    //points = pixels * 96/72

    /*
      letter paper size =  563 pixels x 750 pixels
      legal paper size = 563 pixels x 975 pixels
    */

    var screenX = Math.round(data.x);
    var screenY = Math.round(data.y);
    //var x=data.x;
    //var y=Math.round(data.y);

    // SE AJUSTA X Y Y DEPENDIENDO DEL SCREEN SIZE
    //var x = Math.round( (data.x*563)/clientRect.width );
    //var y = Math.round( (data.y*975)/clientRect.height );
    var x = screenX;
    var y = screenY;

    //firma fuera del documento
    if (x < 0 || x > clientRect.width - 100) {
      //alert("La Firma se encuentra fuera del documento");
      this.setState({ alerta: true });
    } else {
      if (-1 * y > clientRect.height) {
        //alert("La Firma se encuentra fuera del documento");
        this.setState({ alerta: true });
      } else {
        //firma correcta
      }
    }

    // SE JUSTA LA FIRMA POSICIÓN DE LA FIRMA TOMANDO EN CUENTA LOS MÁRGENES
    if (x <= 0) {
      x = 1;
    }

    if (y > -64) {
      y = -64;
    }

    //const y=e.clientY;
    if (this.state.pagina) {
      this.props.setCoordenadas({
        page: this.state.pagina,
        x: x,
        y: y + 25,
        screenX: screenX,
        screenY: screenY,
        w: 100,
        h: 64,
        top: bottom,
        left: clientX,
        nombre: this.props.nombre,
        height: clientRect.height,
        width: clientRect.width,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <Dialog
          open={this.state.alerta}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"La firma está fuera del documento"}
            <ErrorOutlineOutlinedIcon />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Por favor ubique la posición de la firma dentro del documento.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
        <Draggable
          ref={this.refField}
          axis="both"
          handle=".handle"
          defaultPosition={{ x: this.state.defx, y: -this.state.defy }}
          //position={{ x: this.state.defx, y: -this.state.defy }}
          position={null}
          //grid={[25, 25]}
          scale={1}
          onStart={this.handleStart}
          onDrag={this.handleDrag}
          onStop={this.handleStop}
        >
          <div>
            <div className="handle">
              <font color="red">
                <b style={{ fontSize: "7px" }}>
                  Clic para arrastar y clic para soltar
                </b>
              </font>
              <br />
              <img
                src={firma}
                width="100"
                height="64"
                style={{ opacity: "50%" }}
              />
            </div>
          </div>
        </Draggable>
      </React.Fragment>
    );
  }
}

export default CuadroFirma;
