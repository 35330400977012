import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
}));

export default function InfoDocu(props) {
  const { docuDataPerson } = props;
  const classes = useStyles();

  function ShowMeta(props) {
    let mainData;
    if (typeof props.json === "string") {
      mainData = JSON.parse(props.json);
    } else {
      mainData = props.json;
    }
    return (
      <>
        {Object.keys(mainData).map((key, i) => (
          <p key={i}>
            <b>{key}:</b> {mainData[key]}
          </p>
        ))}
      </>
    );
  }
  return (
    <div>
      <b>Nombre del documento:</b> {docuDataPerson.filename}
      <br></br>
      <hr></hr>
      <b>Compañia:</b>{" "}
      {docuDataPerson.corporacion != null
        ? docuDataPerson.corporacion.name
        : ""}
      <br></br>
      <hr></hr>
      <b>Emisor:</b>
      <br />
      {docuDataPerson.users != null ? (
        <>
          <b>Nombre:</b> {docuDataPerson.users.name} <br />
          <b>Correo:</b> {docuDataPerson.users.email}
        </>
      ) : (
        ""
      )}
      <br></br>
      <hr></hr>
      {docuDataPerson.description ? (
        <React.Fragment>
          <b>Descripción:</b> {docuDataPerson.description}
          <br></br>
          <hr></hr>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <b>Descripción:</b> {""}
          <br></br>
          <hr></hr>
        </React.Fragment>
      )}
      {docuDataPerson.reason ? (
        <React.Fragment>
          <b>Fecha de detención:</b>{" "}
          {moment(docuDataPerson.stopped_at).format("DD/MM/YYYY HH:mm:ss")}
          <br></br>
          <b>Motivo:</b> {docuDataPerson.reason}
          <br></br>
          <hr></hr>
        </React.Fragment>
      ) : null}
      <b>Hash:</b> {docuDataPerson.hash}
      <br></br>
      <hr></hr>
      <b>ID el documento:</b> {docuDataPerson._id}
      <br></br>
      <hr></hr>
      <b>Carpeta:</b> {docuDataPerson.folder}
      <br></br>
      <hr></hr>
      <b>Tamaño de documento:</b> {docuDataPerson.size}
      <br></br>
      <hr></hr>
      {docuDataPerson.meta && docuDataPerson.meta.vigencia_start ? (
        <React.Fragment>
          <b>Inicio de Vigencia:</b>{" "}
          {moment(docuDataPerson.meta.vigencia_start).format(
            "DD/MM/YYYY HH:mm:ss"
          )}
          <br></br>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <b>Inicio de Vigencia:</b> <br></br>
        </React.Fragment>
      )}
      {docuDataPerson.meta && docuDataPerson.meta.vigencia_end ? (
        <React.Fragment>
          <b>Término de Vigencia:</b>{" "}
          {moment(docuDataPerson.meta.vigencia_end).format(
            "DD/MM/YYYY HH:mm:ss"
          )}
          <br></br>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <b>Término de Vigencia:</b> <br></br>
        </React.Fragment>
      )}
      {docuDataPerson.meta && docuDataPerson.meta.dias_alerta ? (
        <React.Fragment>
          <b>Alerta de Vencimiento:</b> {docuDataPerson.meta.dias_alerta} días
          <br></br>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <b>Alerta de Vencimiento:</b> <br></br>
        </React.Fragment>
      )}
      {docuDataPerson.meta && docuDataPerson.meta.info ? (
        <React.Fragment>
          <hr />
          <font color="black">
            <b>Campos adicionales:</b>
          </font>
          <hr />
          <ShowMeta json={docuDataPerson.meta.info} />
        </React.Fragment>
      ) : null}
    </div>
  );
}
