import React from "react";
import ReactDOM from "react-dom";
import Draggable from "react-draggable";
import firma from "./rectangle-firma1.png";
import Button from "@material-ui/core/Button";
import ButtonCom from "components/CustomButtons/Button.js";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LibraryAddCheckIcon from "@material-ui/icons/LibraryAddCheck";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import IconButton from "@material-ui/core/IconButton";

import Tooltip from "@material-ui/core/Tooltip";
import { green } from "@material-ui/core/colors";

import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";

import { PanToolOutlined } from "@material-ui/icons";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

class CuadroFirma extends React.Component {
  constructor(props) {
    super(props);
    console.log("PRPSO", props);

    this.refField = React.createRef();
    this.state = {
      pagina: props.pagina,
      defx: props.defx,
      defy: props.defy,
      left: props.left,
      nombre: props.nombre,
      alerta: false,
      texto: props.texto,
      boundsTop: -605,
      isDragging: false,
      isDirty: false,
      isHover: false,
    };
    console.log("ESTADO DE CUADRO", this.state);
    console.log("PROPS DE CUADRO", props);
  }


  handleStart = () => {
    this.setState({ isDragging: true });
  }

  componentDidUpdate(prevProps) {
    console.log("propsCuradro", this.props);
    if (prevProps.pagina !== this.props.pagina) {
      this.setState({ pagina: this.props.pagina });
    }
  }

  componentDidMount() {
    if (this.props.autoFirma == true) {
      this.setState({ boundsTop: -580 });

      console.log("entro!! ");
    }
  }

  handleClose = () => {
    this.setState({ alerta: false });
    // const { setDisplaySign } = this.props;
    // setDisplaySign(false);
  };

  handleStop = (e: MouseEvent, data: Object) => {
    this.setState({ isDragging: false, isDirty: true });
    this.props.setDisableFijar(false);
    var element = document.getElementsByClassName("react-pdf__Page__canvas");
    //console.log(element[0].getBoundingClientRect())
    var clientRect = element[0].getBoundingClientRect();
    var clientX = clientRect.left;
    var bottom = clientRect.bottom;
    var width = clientRect.width;
    // console.log("PDF:")
    // console.log("botton:"+clientRect.bottom)
    console.log("PDF_page:");
    console.log(clientRect);

    //var clientRect = element.getBoundingClientRect();
    //var clientX = clientRect.left;

    console.log("Event: ", e);
    console.log("Data: ", data);
    //points = pixels * 96/72

    /*
      letter paper size =  563 pixels x 750 pixels
      legal paper size = 563 pixels x 975 pixels
    */

    var screenX = Math.round(data.x);
    var screenY = Math.round(data.y);
    //var x=data.x;
    //var y=Math.round(data.y);

    // SE AJUSTA X Y Y DEPENDIENDO DEL SCREEN SIZE
    //var x = Math.round( (data.x*563)/clientRect.width );
    //var y = Math.round( (data.y*975)/clientRect.height );
    var x = screenX;
    var y = screenY;

    //firma fuera del documento
    if (x < 0 || x > clientRect.width - 80) {
      //alert("La Firma se encuentra fuera del documento");
      this.setState({ alerta: true });
    } else {
      if (-1 * y > clientRect.height) {
        //alert("La Firma se encuentra fuera del documento");
        this.setState({ alerta: true });
      } else {
        //firma correcta
      }
    }

    // SE JUSTA LA FIRMA POSICIÓN DE LA FIRMA TOMANDO EN CUENTA LOS MÁRGENES
    if (x <= 0) {
      x = 1;
    }

    if (y > -64) {
      y = -64;
    }

    //const y=e.clientY;
    if (this.state.pagina) {
      this.props.setCoordenadas({
        page: this.state.pagina,
        x: data.x,
        y: data.y,
        screenX: data.x,
        screenY: data.y,
        w: 90,
        h: 64,
        top: clientRect.top,
        left: clientRect.left,
        nombre: this.props.nombre,
        idFirmante: this.props.idFirmante,
        height: clientRect.height,
        width: clientRect.width,
      });
    }
  };


  render() {
    const nombre_firmante = `${this.props.nombre}`.toUpperCase();
    const $nombre_firmante = nombre_firmante.length > 10 ? nombre_firmante.substr(0, 10) + '...' : nombre_firmante;

    return (
      <React.Fragment>
        <Dialog
          open={this.state.alerta}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"La firma está fuera del documento"}
            <ErrorOutlineOutlinedIcon />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Por favor ubique la posición de la firma dentro del documento.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>

        <Draggable
          ref={this.refField}
          bounds={{
            top: this.state.boundsTop,
            left: 1,
            right: 379,
            bottom: -100,
          }}
          axis="both"
          handle=".handle"
          defaultPosition={{ x: this.state.defx, y: -this.state.defy }}
          position={null}
          scale={1}
          onStart={this.handleStart}
          onDrag={this.handleDrag}
          onStop={this.handleStop}
        >
          <div style={{ position: "absolute" }}>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <b>
                    {
                      "Clic para arrastrar y mover el recuadro a la posición deseada, luego clic para soltar. Selecciona  "
                    }
                  </b>{" "}
                  <CheckCircleOutlineIcon
                    style={{ color: green[700], width: "15px", height: "15px" }}
                  />
                  <b>{"para fijar."}</b>
                </React.Fragment>
              }
            >
              <div className="handle ifb-drag">
                <div class="i-firma-box" onMouseEnter={() => this.setState({ isHover: true })} onMouseLeave={() => this.setState({ isHover: false })}>
                  {this.state.isDirty & this.state.isHover ?
                    <Tooltip id="tooltip-top" title="Fijar firma" placement="top">
                      <IconButton aria-label="Edit" className="i-firma-btn">
                        <CheckCircleOutlineIcon
                          style={{ color: green[700] }}
                          onClick={() => this.props.clickFijar()}
                        />
                      </IconButton>
                    </Tooltip> :
                    <PanToolOutlined style={{ color: "#076DED" }} />
                  }
                </div>
                <Tooltip
                  title={nombre_firmante}
                  placement="top"
                >
                  <div
                    className="i-firma-txt">
                    <p
                    >
                      {$nombre_firmante}
                    </p>
                  </div>
                </Tooltip>
              </div>
            </HtmlTooltip>
          </div>
        </Draggable>
      </React.Fragment>
    );
  }

}

export default CuadroFirma;
